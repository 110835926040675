import { ButtonVariant } from "./Button.model";

import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

type TVariantKeys = ButtonVariant;

const variants: {
  /* eslint @typescript-eslint/no-explicit-any: "off" */
  [key in TVariantKeys]: any;
} = {
  primary: {
    backgroundColor: "primary.0",
    color: "neutralTones.0",
    padding: "14px 32px",

    "&:hover": {
      backgroundColor: "primary.1",
    },
    "&:disabled": {
      opacity: 0.5,
    },
    "&:disabled:hover": {
      backgroundColor: "primary.0",
      opacity: 0.5,
    },
  },
  secondary: {
    backgroundColor: "secondary.0",
    color: "neutralTones.0",
    padding: "14px 32px",

    "&:hover": {
      backgroundColor: "secondary.1",
    },
    "&:disabled": {
      opacity: 0.5,
    },
    "&:disabled:hover": {
      backgroundColor: "secondary.0",
      opacity: 0.5,
    },
  },
  tertiary: {
    backgroundColor: "neutralTones.0",
    color: "secondary.0",
    border: "1px solid",
    borderColor: "secondary.0",
    padding: "13px 32px",
    "&:hover": {
      backgroundColor: "secondary.1",
      color: "neutralTones.0",
      borderColor: "secondary.1",
    },
    "&:disabled": {
      borderColor: "secondary.0",
      opacity: 0.5,
    },
    "&:disabled:hover": {
      backgroundColor: "transparent",
      color: "secondary.0",
      borderColor: "secondary.0",
      opacity: 0.5,
    },
  },
  text: {
    backgroundColor: "transparent",
    color: "secondary.0",
    padding: 0,

    "&:hover": {
      color: "secondary.1",
    },
    "&:disabled": {
      opacity: 0.5,
    },
    "&:disabled:hover": {
      color: "secondary.0",
      opacity: 0.5,
    },
  },
  outlined: {
    backgroundColor: "transparent",
    color: "secondary.0",
    border: "1px solid",
    borderColor: "secondary.0",
    padding: "13px 32px",

    "&:hover": {
      backgroundColor: "secondary.1",
      color: "neutralTones.0",
      borderColor: "secondary.1",
    },
    "&:disabled": {
      borderColor: "secondary.0",
      opacity: 0.5,
    },
    "&:disabled:hover": {
      backgroundColor: "transparent",
      color: "secondary.0",
      borderColor: "secondary.0",
      opacity: 0.5,
    },
  },
  selectPrimary: {
    backgroundColor: "transparent",
    color: "neutralTones.5",
    border: "1px solid",
    borderColor: "neutralTones.4",
    borderRadius: "0",
    height: 48,
    paddingX: "xxl-2",
    paddingY: "m",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  selectSecondary: {
    backgroundColor: getConfig<string>(config.colors, "tertiary", "tertiary"),
    color: "neutralTones.0",
    border: "1px solid",
    borderColor: "neutralTones.5",
    height: 48,
    padding: "m",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  selectTertiary: {
    backgroundColor: "neutralTones.0",
    color: "neutralTones.7",
    border: "1px solid",
    borderColor: "neutralTones.3",
    height: 40,
    padding: "m",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  icon: {
    backgroundColor: "neutralTones.0",
    color: "secondary.0",
    border: "1px solid",
    borderColor: "secondary.0",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "secondary.1",
      borderColor: "secondary.1",
      color: "neutralTones.0",
    },
    "&:disabled": {
      opacity: 0.5,
    },
    "&:disabled:hover": {
      color: "secondary.0",
      borderColor: "neutralTones.3",
      opacity: 0.5,
    },
  },
};

export default variants;
