import fetchData from "../Api";
import redirectUrls from "../../config/redirectUrls";
import { IAuthContext } from "../../context/Authentication/AuthenticationContext.model";
import {
  GetOrderByIdDTO,
  PlaceOrderData,
  PlaceOrderDTO,
  PlaceOrderPayload,
} from "./Exchange.model";

const { REACT_APP_ATLAS_URL } = process.env;

const placeOrder = async (
  orderData: PlaceOrderData,
  pairId: string,
  partnerId: string,
  expireUserSession: IAuthContext["expireUserSession"]
): Promise<PlaceOrderDTO> => {
  const payload: PlaceOrderPayload = {
    ...orderData,
    mode: "manual",
    operation: "transfer",
    market: pairId,
  };

  const { data, error } = await fetchData<PlaceOrderDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/exchange/order`,
    options: {
      method: "POST",
      credentials: "include",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    },
    expireUserSession,
    params: {
      successRedirectUrl: redirectUrls.placeOrder.successRedirectUrl(partnerId),
      failureRedirectUrl: redirectUrls.placeOrder.failureRedirectUrl(partnerId),
    },
  });

  return { data, error };
};

const getOrderById = async (
  transactionId: string,
  expireUserSession: IAuthContext["expireUserSession"],
  mode?: string
): Promise<GetOrderByIdDTO> => {
  const params = mode ? { mode: mode } : undefined;

  const { data, error } = await fetchData<GetOrderByIdDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/exchange/orders/${transactionId}`,
    options: { credentials: "include" },
    expireUserSession,
    params,
  });

  return { data, error };
};

export { placeOrder, getOrderById };
