import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { space, SpaceProps } from "styled-system";

import Box from "../Box";
import Button from "../Button";
import Typography from "../Typography";
import Tooltip from "../Tooltip";
import useWindowSize from "../../hooks/useWindowSize";
import { HeroSectionProps } from "./HeroSection.model";

const HeroImage = styled.img`
  object-fit: cover;
`;

const Logo = styled.img<SpaceProps>`
  flex-grow: 1;
  max-height: 80px;
  ${space}
  max-width: 210px;
`;

const HeroSection: React.FC<HeroSectionProps> = ({
  heroImageUrl,
  heroImageAlt,
  logoUrl,
  logoAlt,
  brandName,
  text,
  loginButtonText = undefined,
  linkingButtonText = undefined,
  useLoginLayout = false,
  buttonAction = undefined,
  displayButton = false,
  disableButton = false,
  disabledButtonTooltip = undefined,
  isLoading = false,
  ...props
}) => {
  const { width: windowWidth } = useWindowSize();
  const themeContext = useContext(ThemeContext);
  const { breakpoints } = themeContext;
  const tabletStartBreakpoint = parseInt(breakpoints[0].split("px")[0], 10);

  return (
    <Box
      display="flex"
      flexDirection={{ _: "column", desktop: "row-reverse" }}
      backgroundColor="neutralTones.0"
      justifyContent="flex-end"
      {...props}
    >
      {windowWidth >= tabletStartBreakpoint && (
        <Box
          flex="auto"
          height={{ _: 274, desktop: "unset" }}
          data-cy="brand-banner"
        >
          <HeroImage
            src={heroImageUrl}
            alt={heroImageAlt}
            width="100%"
            height="100%"
          />
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width={{ _: "100%", desktop: 338 }}
        p="xxl-2"
        gridGap={"xxl-2"}
      >
        <Box
          flex={1}
          display="flex"
          flexDirection={{ _: "column", tablet: "row", desktop: "column" }}
          alignItems="center"
          justifyContent={{ _: "center", desktop: "center" }}
          gridGap={"xxl-2"}
          data-cy="brand-logo"
        >
          <Logo src={logoUrl} alt={logoAlt} />
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            alignItems="center"
          >
            <Typography
              color="neutralTones.5"
              variant="medium"
              textAlign="center"
            >
              {brandName}
            </Typography>
            <Typography
              variant="title"
              color="text"
              lineHeight="l"
              width={"100%"}
              textAlign="center"
            >
              {text}
            </Typography>
          </Box>
        </Box>
        {displayButton && (
          <Tooltip infoMessage={disabledButtonTooltip}>
            <Button
              id="hero section button"
              variant={useLoginLayout ? "primary" : "secondary"}
              onClick={buttonAction}
              width={"100%"}
              disabled={disableButton}
              loading={isLoading}
            >
              {useLoginLayout ? loginButtonText : linkingButtonText}
            </Button>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default HeroSection;
