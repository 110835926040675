import { CookiesPageDTO, ParsedCookiesPage } from "./cookiesPageParser.model";

import richTextToComponents from "../../../../utils/richTextToComponents";

const cookiesPageParser = (
  dto: CookiesPageDTO["data"]
): ParsedCookiesPage["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const { title, cookiesDescription } = dto.items[0].fields;

  return {
    title,
    cookiesDescription: richTextToComponents(cookiesDescription),
  };
};

export default cookiesPageParser;
