import React from "react";
import styled from "styled-components";
import css from "@styled-system/css";

import Box from "../Box";
import { space, SpaceProps } from "styled-system";
import { TextareaProps } from "./Textarea.model";

const StyledTextarea = styled.textarea<SpaceProps>`
  border: 1px solid;
  ${css({
    borderColor: "neutralTones.4",
    backgroundColor: "transparent",
    fontSize: "s",
  })}
  ${space}
`;
const StyledLabel = styled.label<SpaceProps>`
  ${css({
    fontSize: "s",
    color: "text",
  })}
  ${space}
`;

const Textarea: React.FC<TextareaProps> = ({
  id,
  value,
  onChange = () => {},
  onBlur = () => {},
  label = undefined,
  disabled = false,
  placeholder = "",
  numberOfLines = 3,
  required = false,
  ...props
}) => {
  return (
    <Box display="flex" flexDirection="column" {...props}>
      {label && (
        <StyledLabel htmlFor={id} mx={0} mb="xs">
          {`${label}${required ? " *" : ""}`}
        </StyledLabel>
      )}
      <StyledTextarea
        id={id}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={placeholder}
        rows={numberOfLines}
        required={required}
        p="l"
      />
    </Box>
  );
};

export default Textarea;
