const { REACT_APP_BASE_URL } = process.env;
import { getPartnerFromUrl } from "../utils/routing";

export default {
  login: {
    successRedirectUrl: (partnerId: string): string =>
      `${REACT_APP_BASE_URL}/partner/${partnerId.toLowerCase()}/exchange`,
    failureRedirectUrl: (partnerId: string): string =>
      `${REACT_APP_BASE_URL}/partner/${partnerId.toLowerCase()}`,
  },
  logout: {
    successRedirectUrl: (partnerId: string): string =>
      `${REACT_APP_BASE_URL}/logout?partnerId=${partnerId.toLowerCase()}`,
    failureRedirectUrl: (partnerId: string): string =>
      `${REACT_APP_BASE_URL}/logout?partnerId=${partnerId.toLowerCase()}`,
  },
  link: {
    successRedirectUrl: (partnerId: string): string =>
      `${REACT_APP_BASE_URL}/partner/${partnerId.toLowerCase()}/exchange`,
    failureRedirectUrl: (partnerId: string): string =>
      `${REACT_APP_BASE_URL}/partner/${partnerId.toLowerCase()}/exchange`,
  },
  placeOrder: {
    successRedirectUrl: (partnerId: string): string =>
      `${REACT_APP_BASE_URL}/partner/${partnerId.toLowerCase()}/exchange`,
    failureRedirectUrl: (partnerId: string): string =>
      `${REACT_APP_BASE_URL}/partner/${partnerId.toLowerCase()}/exchange`,
  },
  enableAoiSubscription: {
    successRedirectUrl: (partnerId: string): string =>
      `${REACT_APP_BASE_URL}/partner/${partnerId.toLowerCase()}/exchange`,
    failureRedirectUrl: (partnerId: string): string =>
      `${REACT_APP_BASE_URL}/partner/${partnerId.toLowerCase()}/exchange`,
  },
};

/**
 * This function is used to override the partner url in case of
 * the need to redirect to a different base url.
 *
 * It was used in the past to redirect to the bpme url instead of bp.
 *
 * @param url - the url to override
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overridePartnerRedirect(url: string) {
  const partner = getPartnerFromUrl();

  const mappedPartnerUrl: Record<string, string> = {
    bp: "bp",
  };

  const overriddenUrl = mappedPartnerUrl[partner]
    ? url.replace(partner, mappedPartnerUrl[partner])
    : url;

  return overriddenUrl;
}
