const getPartnerFromUrl = (): string => {
  const baseUrl = process.env.REACT_APP_BASE_URL as string;

  let path = location.href.split(baseUrl)[1] || "";
  // the partner is the last part of the resource in the URL, but we need to ignore
  // all query string and fragments to be able to get the partner correctly

  const indexOfQueryStringOperator = path.indexOf("?");
  if (indexOfQueryStringOperator > 0)
    path = path.substring(0, indexOfQueryStringOperator);

  const indexOfFragmentOperator = path.indexOf("#");
  if (indexOfFragmentOperator > 0) {
    path = path.substring(0, indexOfFragmentOperator);
  }
  const partner = path.split("/")[2];

  return partner;
};

export default getPartnerFromUrl;
