import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import app from "../../../../package.json";
import LoadingPage from "../../../components/LoadingPage";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import NotificationCenter from "../../../components/NotificationsCenter";
import { RoutesEnum } from "../../../config/routes.enum";
import {
  getFooterContent,
  getHeaderContent,
} from "../../../services/Contentful";
import { ParsedHeader } from "../../../services/Contentful/parsers/header/headerParser.model";
import { ParsedFooter } from "../../../services/Contentful/parsers/footer/footerParser.model";
import { NoPartnerProps } from "./NoPartner.model";
import { useAuthentication } from "../../../context/Authentication/AuthenticationContext";
import {
  Header as ExternalHeader,
  Footer as ExternalFooter,
  MODE_CODES,
  PRODUCT_CODES,
  useChannelFeatureFlag,
} from "@channel/shared-components";

import config from "../../../build-config.json";
import getConfig from "../../../utils/configGetter";
import { trackers } from "../../../utils/ga";
import sharedComponentsConfig from "../../../config/sharedComponentsConfig";
import { useFeatureToggleContext } from "../../../context/FeatureToggle/FeatureToggleProvider";

type TPageContent = {
  header: ParsedHeader["data"];
  footer: ParsedFooter["data"];
};

type LinkProps = React.ComponentPropsWithoutRef<typeof Link>;

const NoPartnerLayout: React.FC<NoPartnerProps> = ({ children }) => {
  const opCoId = config.opCoId as string;
  const { locale, opco } =
    sharedComponentsConfig[opCoId as keyof typeof sharedComponentsConfig];
  const [pageContent, setPageContent] = useState<TPageContent | undefined>(
    undefined
  );
  const history = useHistory();
  const { pathname } = useLocation();
  const features = useFeatureToggleContext();

  const ForwardedLink = forwardRef<HTMLAnchorElement, LinkProps>(
    (props, ref) => {
      return (
        <a
          ref={ref}
          {...props}
          role="menuitem"
          tabIndex={0}
          onClick={(event) => {
            props?.onClick?.(event);
            trackers.trackNavigation("sitewide main navigation");
          }}
          onKeyUp={() => {
            trackers.trackNavigation("sitewide main navigation");
          }}
        >
          {props.children}
        </a>
      );
    }
  );

  const routerOptions = {
    as: ForwardedLink,
    usesTo: false,
    product: PRODUCT_CODES.EXCHANGE,
  };

  const {
    handleLogin,
    handleLogout,
    user: authenticatedUser,
  } = useAuthentication();

  const handleLoginAction = useCallback(
    (event: React.MouseEvent<Element>) => {
      event.preventDefault();
      trackers.trackAuthentication({
        location: event.currentTarget.id,
        user_action: "log in",
      });
      handleLogin();
    },
    [handleLogin]
  );

  const handleLogoutAction = useCallback(() => {
    trackers.trackAuthentication({
      location: "header button",
      user_action: "log out",
    });
    handleLogout();
  }, [handleLogout]);

  const auth = {
    loginProps: {
      onClick: handleLoginAction,
    },
    logoutProps: {
      onClick: handleLogoutAction,
    },
  };

  const { showSharedHeaderAndFooter } = features ?? {};
  const { data: isFeatureAvailable } = useChannelFeatureFlag(
    {
      featureFlagKey: "release-1",
      product: PRODUCT_CODES.EXCHANGE,
      opco: opco,
    },
    !!showSharedHeaderAndFooter,
    process.env.REACT_APP_SHARED_COMPONENTS_URL
  );

  // We introduce multiple feature flags from different sources.
  // One from the shared components team and the other from our Contentful service to ensure control of visibility on our end.
  const shouldUseSharedComponents =
    isFeatureAvailable && showSharedHeaderAndFooter;

  useEffect(() => {
    const getPageContent = async (pairId: string) => {
      const { data: headerContent, error: headerContentError } =
        await getHeaderContent(pairId);
      const { data: footerContent, error: footerContentError } =
        await getFooterContent(pairId);

      if ([headerContentError, footerContentError].some((error) => error)) {
        history.push(RoutesEnum.OOPS);
      }

      if (footerContent && headerContent) {
        setPageContent({
          header: headerContent,
          footer: footerContent,
        });
      }
    };

    !shouldUseSharedComponents &&
      getPageContent(
        getConfig<string>(
          config.content,
          "noPartnerLayoutContentKey",
          "NO_PARTNER"
        )
      );
  }, [history, shouldUseSharedComponents]);

  const layout = () => (
    <>
      <meta name="version" content={app.version} />
      <NotificationCenter isLegacyHeader={!shouldUseSharedComponents} />
      {shouldUseSharedComponents ? (
        <ExternalHeader
          auth={auth}
          locale={locale}
          currentPath={pathname}
          mode={MODE_CODES.COLLECT}
          baseUrl={process.env.REACT_APP_SHARED_COMPONENTS_URL}
          opco={opco}
          routerOptions={routerOptions}
          {...(authenticatedUser && {
            balance: authenticatedUser?.baseAccount?.balance?.amount,
            user: {
              name: authenticatedUser?.username,
            },
          })}
        />
      ) : (
        !shouldUseSharedComponents &&
        pageContent?.header && (
          <Header
            content={{
              ...pageContent.header,
            }}
          />
        )
      )}
      {children}

      {shouldUseSharedComponents ? (
        <ExternalFooter
          locale={locale}
          currentPath={pathname}
          mode={MODE_CODES.COLLECT}
          opco={opco}
          routerOptions={routerOptions}
          baseUrl={process.env.REACT_APP_SHARED_COMPONENTS_URL}
        />
      ) : (
        pageContent?.footer && <Footer {...pageContent?.footer} />
      )}
    </>
  );

  return <>{pageContent ? layout() : <LoadingPage />}</>;
};

export default NoPartnerLayout;
