import React, { useCallback, useEffect } from "react";
import css from "@styled-system/css";
import styled from "styled-components";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";
import Typography from "../Typography";
import { ModalProps } from "./Modal.model";
import { useNotifications } from "../../context/Notifications/NotificationsContext";

import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

const BoxWithTextCentred = styled(Box)`
  text-align: center;
  ${css({
    color: "text",
  })}
`;

const Modal: React.FC<ModalProps> = ({
  id,
  isFullscreen = false,
  header,
  message,
  secondaryMessage = undefined,
  confirmationMessage = "",
  ctaText = "",
  handleClickCta = undefined,
  cancelText = "",
  hasCloseButton = false,
  closeButtonAriaLabel = "",
  handleAdditionalOnClose = undefined,
  variant,
}) => {
  const { removeModal } = useNotifications();

  const handleClose = useCallback(() => {
    removeModal();
    handleAdditionalOnClose?.();
  }, [removeModal, handleAdditionalOnClose]);

  const handleClickCtaAndClose: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (e) => {
        handleClickCta?.(e);
        handleClose();
      },
      [handleClickCta, handleClose]
    );

  // prevents scrolling behind the modal when it's open
  useEffect(() => {
    const { body } = document;
    body.style.width = "100vw";
    body.style.overflow = "hidden";

    return () => {
      body.style.width = "auto";
      body.style.overflow = "unset";
    };
  }, []);

  return (
    <Box
      id={id}
      position="fixed"
      top={0}
      right={0}
      bottom={0}
      left={0}
      zIndex={999}
      backgroundColor={{
        _: "neutralTones.0",
        tablet: isFullscreen ? "neutralTones.0" : "rgba(0, 0, 0, 0.5)",
      }}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        role="alertdialog"
        aria-modal="true"
        aria-labelledby="modal-header"
        aria-describedby="modal-message"
        position="relative"
        backgroundColor="neutralTones.0"
        width={{ _: "100vw", tablet: "620px" }}
        minHeight={{ _: "100vh", tablet: "353px" }}
        display="flex"
        flexDirection="column"
        justifyContent={{ _: "space-between", tablet: "flex-start" }}
        alignItems="center"
        padding="l"
      >
        <Box display="flex" flexDirection="column" width="100%">
          {hasCloseButton && (
            <Button
              id="modal-close-button"
              alignSelf="flex-end"
              variant="text"
              height="xl"
              onClick={handleClose}
              aria-label={closeButtonAriaLabel}
              marginBottom="l"
            >
              <Icon
                type="regular"
                name="xmark"
                width="l"
                height="l"
                color={getConfig<string>(config.colors, "tertiary", "tertiary")}
              />
            </Button>
          )}
          <Typography
            id="modal-header"
            variant="h2"
            textAlign="center"
            paddingX="l"
            marginBottom="xxl"
            color="text"
          >
            {header}
          </Typography>
          <Box id="modal-content">
            <BoxWithTextCentred
              id="modal-message"
              paddingX="l"
              marginBottom="xxl"
            >
              {documentToReactComponents(message)}
            </BoxWithTextCentred>
            {secondaryMessage && (
              <BoxWithTextCentred
                id="secondary-message"
                paddingX="l"
                marginBottom="xxl"
              >
                {documentToReactComponents(secondaryMessage)}
              </BoxWithTextCentred>
            )}
            {confirmationMessage && (
              <BoxWithTextCentred
                id="confirmation-message"
                paddingX="l"
                marginBottom="xxl"
              >
                {confirmationMessage}
              </BoxWithTextCentred>
            )}
          </Box>
        </Box>
        {(ctaText || cancelText) && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems={{ _: "stretch", tablet: "center" }}
            width="100%"
            marginTop="auto"
          >
            {ctaText && (
              <Button
                id="modal-cta-button"
                variant={variant === "secondary" ? "outlined" : "secondary"}
                fontSize="s"
                marginBottom="xxl"
                minWidth="190px"
                onClick={handleClickCtaAndClose}
              >
                {ctaText}
              </Button>
            )}
            {cancelText && (
              <Button
                id="modal-cancel-button"
                variant="text"
                fontSize="s"
                marginBottom="xxl"
                textColor={
                  variant === "secondary" ? undefined : "neutralTones.7"
                }
                onClick={handleClose}
              >
                {cancelText}
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Modal;
