import React from "react";
import styled from "styled-components";
import {
  alignItems,
  border,
  display,
  justifyContent,
  width,
} from "styled-system";

import StepIcon from "../StepIcon";
import { TIconKey } from "../StepIcon/StepIcon.model";
import { IButtonProps } from "./StepHandler.model";

const Wrapper = styled.button<IButtonProps>`
  ${border}
  ${alignItems}
  ${justifyContent}
  ${width}
  ${display}
  
  svg:hover {
    cursor: pointer;
  }
`;

const StepHandler = ({
  isCompleted,
  isActive,
  ...rest
}: IButtonProps): JSX.Element => {
  let currentStep: TIconKey = "stepBasic";

  if (isCompleted) currentStep = "stepCompleted";
  if (isActive) currentStep = "stepActive";

  return (
    <Wrapper {...rest}>
      <StepIcon name={currentStep} />
    </Wrapper>
  );
};

export default StepHandler;
