import fetchData from "../Api";
import redirects from "../../config/redirectUrls";

import { LoginDTO } from "./Auth.model";
import { AtlasError } from "../../Global.model";

import config from "../../build-config.json";

const { opCoId, defaultPartner } = config;
const { REACT_APP_ATLAS_URL } = process.env;

const userLogin = async (partnerId: string): Promise<LoginDTO> => {
  const resolvedPartnerId = partnerId || defaultPartner;

  const { data, error } = await fetchData<LoginDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/auth/login`,
    params: {
      "partner-id": resolvedPartnerId,
      "x-opco-id": opCoId,
      successRedirectUrl: redirects.login.successRedirectUrl(resolvedPartnerId),
      failureRedirectUrl: redirects.login.failureRedirectUrl(resolvedPartnerId),
    },
  });

  return { data, error };
};

const userLogout = (partnerId: string): void => {
  const resolvedPartnerId = partnerId || defaultPartner;

  const successRedirectUrl =
    redirects.logout.successRedirectUrl(resolvedPartnerId);
  const failureRedirectUrl =
    redirects.logout.failureRedirectUrl(resolvedPartnerId);
  const redirectPathParams = `&successRedirectUrl=${successRedirectUrl}&failureRedirectUrl=${failureRedirectUrl}`;

  window.location.assign(
    `${REACT_APP_ATLAS_URL}/auth/logout?partner-id=${resolvedPartnerId.toLocaleLowerCase()}${redirectPathParams}`
  );
};

const consumeCode = async (
  code: string
): Promise<{ data?: Record<string, never>; error?: AtlasError }> => {
  return fetchData({
    url: `${REACT_APP_ATLAS_URL}/auth/code2`,
    options: {
      method: "POST",
      credentials: "include",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify({ code }),
    },
  });
};

export { userLogin, userLogout, consumeCode };
