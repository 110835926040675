import React from "react";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";

import Brand from "../../assets/icons/brands.svg";
import Duotone from "../../assets/icons/duotone.svg";
import Light from "../../assets/icons/light.svg";
import Regular from "../../assets/icons/regular.svg";
import Thin from "../../assets/icons/thin.svg";
import Solid from "../../assets/icons/solid.svg";
import styled from "styled-components";
import { IconProps } from "./Icon.model";
import css from "@styled-system/css";

const Wrapper = styled.span<SpaceProps & LayoutProps>`
  display: inline-block;
  line-height: 100%;
  ${space}
  ${layout}
`;

const StyledSvg = styled.svg<{ rotate: string }>`
  transform: rotate(${(props) => props.rotate});
  ${(props) =>
    css({
      fill: props.fill,
    })}
`;

const Icon: React.FC<IconProps> = ({
  type,
  name,
  color = "currentColor",
  width = "l",
  height = "l",
  rotate = "0",
  ...rest
}) => {
  const iconType: {
    [key in IconProps["type"]]: string;
  } = {
    brands: Brand,
    duotone: Duotone,
    light: Light,
    regular: Regular,
    thin: Thin,
    solid: Solid,
  };

  return (
    <Wrapper width={width} height={height} {...rest}>
      <StyledSvg
        rotate={rotate}
        fill={color}
        width="100%"
        height="100%"
        aria-label={`icon-${name}`}
        role="img"
      >
        <use xlinkHref={`${iconType[type]}#${name}`}></use>
      </StyledSvg>
    </Wrapper>
  );
};

export default Icon;
