import React, { useEffect, useState } from "react";

import Box from "../Box";
import Button from "../Button";
import modalsIds from "../../dictionaries/modalsIds";
import ConversionAuto from "../ConversionAuto";
import ConversionAutoV2 from "../ConversionAutoV2";
import ConversionStatus from "../ConversionStatus";
import Typography from "../Typography";
import { aoiStatusIds } from "../../dictionaries/aoiStatusIds";
import { ConversionAutoSectionProps } from "./ConversionAutomaticSection.model";
import { useNotifications } from "../../context/Notifications/NotificationsContext";
import { useAuthentication } from "../../context/Authentication/AuthenticationContext";
import { useAutoConversion } from "../../context/AutoConversion/AutoConversionContext";

const ConversionAutomaticSection: React.FC<ConversionAutoSectionProps> = ({
  isSwitchEnabledGlobal = false,
  exchangeContent,
  conversionStatusTemplates,
}) => {
  const { user: authenticatedUser } = useAuthentication();
  const { aoiState, handleAoiUnsubscription, isLoadingAoiInfo } =
    useAutoConversion();

  const [autoConversionIsActive, setAutoConversionIsActive] = useState(
    Boolean(aoiState?.status)
  );

  useEffect(() => {
    !isLoadingAoiInfo && setAutoConversionIsActive(Boolean(aoiState?.status));
  }, [aoiState?.status, isLoadingAoiInfo]);

  const { getModalTemplate, addModal } = useNotifications();

  const renderConversionSection = () => {
    const template = conversionStatusTemplates?.find(
      (templateModel) =>
        templateModel.id ===
        `${templateModel.type}_${aoiState.status?.toLocaleLowerCase()}`
    );

    const handleAoiUnsubscribe = () => {
      const unsubscribeModal = getModalTemplate(modalsIds.UNSUBSCRIBE_AOI);

      if (!unsubscribeModal) {
        return;
      }

      addModal({
        ...unsubscribeModal,
        handleClickCta: handleAoiUnsubscription,
        hasCloseButton: true,
      });
    };

    if (template) {
      const handleButtonAction = () => {
        if (template.status === aoiStatusIds.ACTIVE.toLocaleLowerCase()) {
          handleAoiUnsubscribe();
        }
      };

      return (
        <ConversionStatus
          type="aoi"
          status={template.status}
          content={template}
          handleClickCta={handleButtonAction}
          isLoading={isLoadingAoiInfo}
          periodicity={exchangeContent?.periodicity}
          sourcePartnerId={aoiState?.sourcePartnerId}
        />
      );
    }

    return (
      <>
        {Boolean(process.env.REACT_APP_AOI_V2) ? (
          <ConversionAutoV2
            handleAoiSetup={() => {
              setAutoConversionIsActive(false);
            }}
            exchangeContent={exchangeContent}
          />
        ) : (
          <ConversionAuto
            handleAoiSetup={() => {
              setAutoConversionIsActive(false);
            }}
            exchangeContent={exchangeContent}
            isSwitchEnabledGlobal={isSwitchEnabledGlobal}
          />
        )}
      </>
    );
  };

  return (
    <>
      {authenticatedUser?.accountIsLinked && autoConversionIsActive ? (
        renderConversionSection()
      ) : (
        <Box
          padding="m"
          display="flex"
          flexDirection="column"
          minHeight={220}
          height="100%"
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex={1}
            maxWidth={{ _: "100%", desktop: 330 }}
          >
            <Typography
              variant="medium"
              textAlign="center"
              color="neutralTones.5"
            >
              {authenticatedUser?.accountIsLinked
                ? exchangeContent?.linkedAutoConversionPlaceholder
                : exchangeContent?.unlinkedAutoConversionPlaceholder}
            </Typography>
          </Box>
          {authenticatedUser?.accountIsLinked &&
            !autoConversionIsActive &&
            exchangeContent && (
              <Button
                id="auto-conversion-toggle-button"
                variant="secondary"
                marginTop="auto"
                onClick={() => {
                  setAutoConversionIsActive(true);
                }}
                aria-label={exchangeContent.setupButtonAriaLabel}
              >
                {exchangeContent.setupButtonText}
              </Button>
            )}
        </Box>
      )}
    </>
  );
};

export default ConversionAutomaticSection;
