import {
  ParsedTwoCardsSection,
  TwoCardsSectionDTO,
} from "./twoCardsSectionParser.model";
import richTextToComponents from "../../../../utils/richTextToComponents";

const twoCardsSectionParser = (
  dto: TwoCardsSectionDTO["data"]
): ParsedTwoCardsSection["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const {
    headingOne,
    descriptionOne,
    greyBox = undefined,
    headingTwo = undefined,
    descriptionTwo = undefined,
  } = dto.items[0].fields;

  return {
    headingOne,
    descriptionOne: richTextToComponents(descriptionOne),
    greyBox: greyBox && richTextToComponents(greyBox),
    headingTwo,
    descriptionTwo: descriptionTwo && richTextToComponents(descriptionTwo),
  };
};

export default twoCardsSectionParser;
