import React from "react";
import css from "@styled-system/css";
import styled from "styled-components";

import Box from "../Box";
import { ITooltipProps } from "./Tooltip.model";

import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

const TooltipBox = styled(Box)`
  ${css({
    visibility: "hidden",

    transition: "color 0.5s, background-color 0.5s",

    "&:after": {
      content: "' '",
      visibility: "hidden",
      position: "absolute",
      top: "100%",
      left: "50%",
      marginLeft: "calc(-50% + 10px)",
      borderWidth: "8px",
      borderStyle: "solid",
      borderColor: "transparent",
      transition: "color 0.5s, border-color 0.5s",
    },
  })}
`;

const TooltipCard = styled(Box)`
  ${css({
    "&:hover": {
      "& > div": {
        visibility: "visible",
        backgroundColor: getConfig<string>(
          config.colors,
          "tertiary",
          "tertiary"
        ),
        opacity: 0.9,
        color: "neutralTones.0",

        "&:after": {
          visibility: "visible",
          borderColor: `${getConfig<string>(
            config.colors,
            "tertiary",
            "tertiary"
          )} transparent transparent transparent`,
          opacity: 0.9,
        },
        "& > p": {
          color: "neutralTones.0",
        },
      },
    },
  })}
`;

const Tooltip: React.FC<ITooltipProps> = ({ children, infoMessage }) => {
  if (!infoMessage) {
    return <>{children}</>;
  }

  return (
    <TooltipCard
      aria-label="Tooltip Container"
      position="relative"
      width={{ _: "inherit", desktop: "auto" }}
    >
      {children}
      <TooltipBox
        color="transparent"
        backgroundColor="transparent"
        width="max-content"
        maxWidth="15em"
        padding="xs"
        borderRadius="4px"
        position="absolute"
        bottom="calc(100% + 15px)"
        aria-label="Tooltip Message"
      >
        {infoMessage}
      </TooltipBox>
    </TooltipCard>
  );
};

export default Tooltip;
