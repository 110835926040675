import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import LoadingPage from "../components/LoadingPage";
import { RoutesEnum } from "../config/routes.enum";
import { useAuthentication } from "../context/Authentication/AuthenticationContext";
import { setQueryToHref } from "../utils/routing";

interface PrivateRouteProps extends RouteProps {
  children?: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, ...rest }) => {
  const { user, isLoadingUser, isPartnerPageVariant } = useAuthentication();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoadingUser) {
          return <LoadingPage />;
        }

        const href = RoutesEnum.LANDING.replace(
          ":id",
          props.match.params.id || "undefined"
        );
        const hrefWithQuery = isPartnerPageVariant
          ? setQueryToHref(href, { origin: "external" })
          : href;

        if (!user) {
          return <Redirect to={hrefWithQuery} />;
        }

        return children;
      }}
    />
  );
};

export default PrivateRoute;
