export type TPromotionTypes = "Partner Page" | "Homepage Carousel";

export type TPromotionTypesEnum = Record<string, TPromotionTypes>;

const promotionTypesIds: TPromotionTypesEnum = {
  PARTNER_PAGE: "Partner Page",
  HOMEPAGE: "Homepage Carousel",
};

export { promotionTypesIds };
