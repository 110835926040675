import React from "react";

import Box from "../Box";
import Loading from "../Loading";

const LoadingPage: React.FC = () => {
  return (
    <Box
      position="absolute"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      backgroundColor="neutralTones.1"
      display="flex"
      alignItems="center"
      justifyContent="center"
      role="alert"
      zIndex={1}
    >
      <Loading size="large" />
    </Box>
  );
};

export default LoadingPage;
