import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import app from "../../../../package.json";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import GoBackLink from "../../../components/GoBackLink";
import LoadingPage from "../../../components/LoadingPage";
import { RoutesEnum } from "../../../config/routes.enum";
import { useAuthentication } from "../../../context/Authentication/AuthenticationContext";
import { useNotifications } from "../../../context/Notifications/NotificationsContext";
import { ParsedHeader } from "../../../services/Contentful/parsers/header/headerParser.model";
import { ParsedFooter } from "../../../services/Contentful/parsers/footer/footerParser.model";
import { usePartnershipContext } from "../../../context/Partnership/PartnershipContext";
import { useFeatureToggleContext } from "../../../context/FeatureToggle/FeatureToggleProvider";
import { setQueryToHref } from "../../../utils/routing";
import {
  getFooterContent,
  getHeaderContent,
} from "../../../services/Contentful";
import { DefaultLayoutProps } from "./DefaultLayout.model";
import { trackers } from "../../../utils/ga";
import config from "../../../build-config.json";
import sharedComponentsConfig from "../../../config/sharedComponentsConfig";
import {
  Header as ExternalHeader,
  Footer as ExternalFooter,
  MODE_CODES,
  PRODUCT_CODES,
  useChannelFeatureFlag,
} from "@channel/shared-components";
import NotificationCenter from "../../../components/NotificationsCenter";

type TPageContent = {
  header: ParsedHeader["data"];
  footer: ParsedFooter["data"];
};

type LinkProps = React.ComponentPropsWithoutRef<typeof Link>;

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  const [pageContent, setPageContent] = useState<TPageContent | undefined>(
    undefined
  );
  const opCoId = config.opCoId as string;
  const { locale, opco } =
    sharedComponentsConfig[opCoId as keyof typeof sharedComponentsConfig];
  const {
    handleLogin,
    handleLogout,
    user: authenticatedUser,
    isLoadingUser,
    isPartnerPageVariant,
  } = useAuthentication();
  const { isLoadingNotifications } = useNotifications();
  const history = useHistory();
  const { pathname } = useLocation();
  const features = useFeatureToggleContext();

  const {
    data: { pairId, urlPartnerPath },
  } = usePartnershipContext();

  const handleLoginAction = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      trackers.trackAuthentication({
        location: event.currentTarget.id,
        user_action: "log in",
      });
      handleLogin();
    },
    [handleLogin]
  );

  const handleLogoutAction = useCallback(() => {
    trackers.trackAuthentication({
      location: "header button",
      user_action: "log out",
    });
    handleLogout();
  }, [handleLogout]);

  const ForwardedLink = forwardRef<HTMLAnchorElement, LinkProps>(
    (props, ref) => {
      return (
        <a
          ref={ref}
          {...props}
          role="menuitem"
          tabIndex={0}
          onClick={(event) => {
            props?.onClick?.(event);
            trackers.trackNavigation("sitewide main navigation");
          }}
          onKeyUp={() => {
            trackers.trackNavigation("sitewide main navigation");
          }}
        >
          {props.children}
        </a>
      );
    }
  );

  const routerOptions = {
    as: ForwardedLink,
    usesTo: false,
    product: PRODUCT_CODES.EXCHANGE,
  };

  const auth = {
    loginProps: {
      onClick: handleLoginAction,
    },
    logoutProps: {
      onClick: handleLogoutAction,
    },
  };

  const { showSharedHeaderAndFooter } = features ?? {};
  const { data: isFeatureAvailable } = useChannelFeatureFlag(
    {
      featureFlagKey: "release-1",
      product: PRODUCT_CODES.EXCHANGE,
      opco: opco,
    },
    !!showSharedHeaderAndFooter,
    process.env.REACT_APP_SHARED_COMPONENTS_URL
  );

  // We introduce multiple feature flags from different sources.
  // One from the shared components team and the other from our Contentful service to ensure control of visibility on our end.
  const shouldUseSharedComponents =
    isFeatureAvailable && showSharedHeaderAndFooter;

  useEffect(() => {
    const getPageContent = async (pairId: string) => {
      const { data: headerContent, error: headerContentError } =
        await getHeaderContent(pairId);
      const { data: footerContent, error: footerContentError } =
        await getFooterContent(pairId);

      if ([headerContentError, footerContentError].some((error) => error)) {
        history.push(RoutesEnum.OOPS);
      }

      if (footerContent && headerContent) {
        setPageContent({
          header: headerContent,
          footer: footerContent,
        });
      }
    };
    !isLoadingNotifications && getPageContent(pairId);
  }, [history, isLoadingNotifications, pairId]);

  const getGoBackButtonProps = useCallback(() => {
    const { goBackButtonLabel, topLink, topLinkPartnerVersion } = {
      ...pageContent?.header,
    };

    if (
      ![
        RoutesEnum.LANDING.replace(":id", urlPartnerPath),
        RoutesEnum.EXCHANGE.replace(":id", urlPartnerPath),
      ].includes(history.location.pathname)
    ) {
      const href = RoutesEnum.LANDING.replace(":id", urlPartnerPath);
      const hrefWithQuery = isPartnerPageVariant
        ? setQueryToHref(href, { origin: "external" })
        : href;
      return { label: goBackButtonLabel || "", to: hrefWithQuery };
    }

    if (isPartnerPageVariant && topLinkPartnerVersion) {
      return {
        label: topLinkPartnerVersion.label,
        to: topLinkPartnerVersion.url,
      };
    }

    if (!isPartnerPageVariant && topLink) {
      return { label: topLink.label, to: topLink.url };
    }

    return { label: goBackButtonLabel || "", to: RoutesEnum.HOME };
  }, [history, isPartnerPageVariant, pageContent, urlPartnerPath]);

  const layout = () => (
    <>
      <meta name="version" content={app.version} />

      {shouldUseSharedComponents ? (
        <ExternalHeader
          auth={auth}
          locale={locale}
          currentPath={pathname}
          mode={MODE_CODES.COLLECT}
          baseUrl={process.env.REACT_APP_SHARED_COMPONENTS_URL}
          opco={opco}
          routerOptions={routerOptions}
          {...(authenticatedUser && {
            balance: authenticatedUser?.baseAccount?.balance?.amount,
            user: {
              name: authenticatedUser?.username,
            },
          })}
        />
      ) : (
        pageContent?.header && (
          <Header
            content={{
              ...pageContent.header,
            }}
            handleLogin={handleLoginAction}
            handleLogout={handleLogoutAction}
            isLoadingUser={isLoadingUser}
            user={{
              membershipNumber: authenticatedUser?.membershipNumber || "",
              balance: authenticatedUser?.baseAccount?.balance?.amount,
              username: authenticatedUser?.username,
            }}
          />
        )
      )}
      <NotificationCenter isLegacyHeader={!shouldUseSharedComponents} />
      <GoBackLink {...getGoBackButtonProps()} />
      {children}
      {shouldUseSharedComponents ? (
        <ExternalFooter
          locale={locale}
          currentPath={pathname}
          mode={MODE_CODES.COLLECT}
          baseUrl={process.env.REACT_APP_SHARED_COMPONENTS_URL}
          opco={opco}
          routerOptions={routerOptions}
        />
      ) : (
        !shouldUseSharedComponents &&
        pageContent?.footer && <Footer {...pageContent?.footer} />
      )}
    </>
  );

  return (
    <>{pageContent && !isLoadingNotifications ? layout() : <LoadingPage />}</>
  );
};

export default DefaultLayout;
