const variants = {
  prop: "size",
  variants: {
    small: {
      // total 16 with borders
      width: 12,
      height: 12,
      border: "2px solid #f3f3f3",
      borderTop: "2px solid #a9a8a9",
    },
    medium: {
      // total 32 with borders
      width: 24,
      height: 24,
      border: "4px solid #e3e3e3",
      borderTop: "4px solid #a9a8a9",
    },
    // total 64 with borders
    large: {
      width: 48,
      height: 48,
      border: "8px solid #f3f3f3",
      borderTop: "8px solid #a9a8a9",
    },
  },
};

export default variants;
