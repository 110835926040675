import React, { useState } from "react";

import AoiButtonsHandler from "../../AoiButtonsHandler";
import Box from "../../Box";
import Dropdown from "../../Dropdown";
import Typography from "../../Typography";
import { useAutoConversion } from "../../../context/AutoConversion/AutoConversionContext";
import { DropdownProps } from "../../Dropdown/Dropdown.model";

const AmountStep = (): JSX.Element => {
  const { activeStep, configPayloadState, handleActiveStep } =
    useAutoConversion();

  const amountDropdown: DropdownProps = {
    id: "amountDropdown",
    variant: "tertiary",
    placeholder: {
      value: "Select amount",
    },
    options: [
      { value: "All" },
      { value: "1000" },
      { value: "2500" },
      { value: "5000" },
      { value: "10000" },
      { value: "25000" },
      { value: "50000" },
      { value: "100000" },
      { value: "250000" },
      { value: "500000" },
    ],
  };
  const [currentAmount, setCurrentAmount] = useState(
    configPayloadState.amountOfPoints
  );

  const handleAmount = (index: number) => {
    setCurrentAmount(amountDropdown.options[index].value);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" flex={1}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flexGrow={1}
      >
        <Typography variant="small" color="neutralTones.7" fontSize="xxs">
          Amount of points:
        </Typography>
        <Dropdown
          id={"dropdown"}
          placeholder={amountDropdown.placeholder}
          options={amountDropdown.options}
          variant={amountDropdown.variant}
          defaultSelectedIndex={amountDropdown.options.findIndex(
            (option) => option.value === configPayloadState.amountOfPoints
          )}
          onChange={handleAmount}
        ></Dropdown>
      </Box>
      <AoiButtonsHandler
        nextButtonProps={{
          variant: "tertiary",
          disabled: !currentAmount,
          padding: "s",
          onClick: () =>
            handleActiveStep(activeStep + 1, "set amount of points", {
              amountOfPoints: currentAmount,
            }),
        }}
      />
    </Box>
  );
};
export default AmountStep;
