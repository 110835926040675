import React, { Children } from "react";

export const getChildrenWithDuplicates = (
  children: React.ReactNode,
  width: number
): React.ReactNode[] | null => {
  /* eslint @typescript-eslint/no-explicit-any: "off" */
  const clonedChildren = Children.map(children, (element: any) => {
    if (React.isValidElement(element)) {
      const props: Record<string, number> = { width };
      return React.cloneElement(element, props);
    }
  });

  if (!clonedChildren) {
    return null;
  }

  if (clonedChildren.length < 2) {
    return clonedChildren;
  }

  const firstChildren = React.cloneElement(clonedChildren[0], {
    key: "first-clone",
  });
  const lastChildren = React.cloneElement(
    clonedChildren[clonedChildren.length - 1],
    { key: "last-clone" }
  );

  return [lastChildren, ...clonedChildren, firstChildren];
};
