import { useEffect, useState } from "react";

const useExpiration = (expirationDate?: Date): boolean => {
  const MAX_DELAY_VALUE = 2147483647;
  const [hasExpired, setHasExpired] = useState(
    Math.abs(new Date(expirationDate || new Date()).getTime()) <
      new Date().getTime()
  );

  const timeUntilBegins =
    new Date(expirationDate || new Date()).getTime() - new Date().getTime();

  useEffect(() => {
    if (!expirationDate || hasExpired || timeUntilBegins > MAX_DELAY_VALUE) {
      return;
    }
    const timeoutId = setTimeout(() => {
      setHasExpired(true);
    }, timeUntilBegins);
    return () => clearTimeout(timeoutId);
  }, [expirationDate, hasExpired, timeUntilBegins]);

  return hasExpired;
};

export default useExpiration;
