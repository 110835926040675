import { APIError } from "../../Global.model";
import { isSessionExpired } from "../../utils/strings";
import { IAuthContext } from "../../context/Authentication/AuthenticationContext.model";

interface Response<T> extends APIError {
  data?: T;
}

interface Args {
  url: string;
  options?: RequestInit;
  expireUserSession?: IAuthContext["expireUserSession"];
  params?: {
    [key: string]: string;
  };
}

const fetchData = async <T>({
  url,
  options = {},
  expireUserSession,
  params = {},
}: Args): Promise<Response<T>> => {
  const urlWithParams = new URL(url);

  Object.entries(params).forEach(([key, value]) =>
    urlWithParams.searchParams.set(key, value)
  );

  try {
    const response = await fetch(urlWithParams.toString(), options);
    const data = await response.json();

    if (!response.ok) {
      const { code, status } = data.error;
      if (isSessionExpired({ code, status })) {
        expireUserSession?.(true);
      }
      return data as APIError;
    }

    return {
      data,
    };
  } catch (error) {
    return error as APIError;
  }
};

export default fetchData;
