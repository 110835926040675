import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

const listVariants = {
  primary: {
    backgroundColor: "neutralTones.0",
    borderColor: "neutralTones.4",
    borderTop: "none",
  },
  secondary: {
    bottom: 48,
    backgroundColor: getConfig<string>(config.colors, "tertiary", "tertiary"),
    borderColor: "neutralTones.5",
    borderRadius: "4px",
    borderBottom: "none",
  },
  tertiary: {
    backgroundColor: "neutralTones.0",
    borderColor: "neutralTones.4",
    borderTop: "none",
  },
};

const listItemVariants = {
  primary: {
    paddingX: "xxl-2",
    backgroundColor: "neutralTones.0",
    borderColor: "neutralTones.4",
    color: "neutralTones.5",
    "&:hover, &:focus, &[aria-selected='true']": {
      color: "secondary.0",
    },
  },
  secondary: {
    paddingX: "m",
    backgroundColor: getConfig<string>(config.colors, "tertiary", "tertiary"),
    borderColor: "neutralTones.5",
    color: "neutralTones.0",
    "&:hover, &:focus, &[aria-selected='true']": {
      backgroundColor: "neutralTones.5",
      color: "neutralTones.0",
    },
  },
  tertiary: {
    paddingX: "xxl-2",
    backgroundColor: "neutralTones.0",
    borderColor: "neutralTones.4",
    color: "#3585D6",
    "&:hover, &:focus, &[aria-selected='true']": {
      color: "secondary.0",
    },
  },
};

export { listVariants, listItemVariants };
