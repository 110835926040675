import React from "react";
import styled from "styled-components";
import css from "@styled-system/css";

import Box from "../Box";
import Icon from "../Icon";
import Typography from "../Typography";
import { ErrorMessageProps } from "./ErrorMessage.model";

const ErrorBox = styled(Box)`
  ${css({
    "&:before": {
      content: "''",
      position: "absolute",
      left: 20,
      top: -10,
      border: "solid 5px transparent",
      borderBottomColor: "error.0",
      zIndex: 1,
    },
  })}
`;

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  message,
  additionalDetails = "",
}) => {
  return (
    <ErrorBox
      role="alert"
      position="relative"
      width="100%"
      display="flex"
      alignItems={additionalDetails ? "flex-start" : "center"}
      padding="xs"
      backgroundColor="error.0"
    >
      <Icon
        type="regular"
        name="circle-xmark"
        color="error.1"
        width="l"
        height="l"
        marginRight="xs"
      />
      <Box>
        <Typography variant="small" color="error.1" fontWeight="bold">
          {message}
        </Typography>
        {additionalDetails && (
          <Typography variant="small" color="error.1" lineHeight="normal">
            {additionalDetails}
          </Typography>
        )}
      </Box>
    </ErrorBox>
  );
};

export default ErrorMessage;
