import React from "react";
import styled from "styled-components";
import { css } from "@styled-system/css";

import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";
import Typography from "../Typography";
import { ConversionRateSwitchProps } from "./ConversionRateSwitch.model";
import { useAuthentication } from "../../context/Authentication/AuthenticationContext";
import { usePartnershipContext } from "../../context/Partnership/PartnershipContext";
import {
  formatStringAsCapitalizedString,
  replacePlaceholderString,
} from "../../utils/strings";

const RateContainer = styled(Box)`
  ${css({
    "&:before": {
      content: "''",
      display: "block",
      borderLeft: "2px solid",
      borderColor: "neutralTones.3",
      marginLeft: "l",
      height: "l",
    },
    "&:after": {
      content: "''",
      display: "block",
      borderLeft: "2px solid",
      borderColor: "neutralTones.3",
      marginLeft: "l",
      height: "l",
    },
  })}
`;

const ConversionRateSwitch: React.FC<ConversionRateSwitchProps> = ({
  content,
  debit,
  credit,
  isSwitchEnabledGlobal,
  isSwitchDisabledLocal,
  handleSwitchDirection,
}) => {
  const { user } = useAuthentication();
  const {
    data: { basePartnerId },
  } = usePartnershipContext();
  const debitCurrency =
    debit.id === basePartnerId
      ? user && user.baseAccount?.balance?.currency
      : user && user.partnerAccount?.balance?.currency;
  const creditCurrency =
    credit.id === basePartnerId
      ? user && user.baseAccount?.balance?.currency
      : user && user.partnerAccount?.balance?.currency;

  const exchangeRateContent =
    debit.points &&
    debit.rate &&
    credit.points &&
    debitCurrency &&
    creditCurrency
      ? {
          debitMin: debit.points.min.toString(),
          debitPointsName: formatStringAsCapitalizedString(debitCurrency),
          creditMin:
            debitCurrency === "NECTAR POINTS"
              ? (debit.points.min * debit.rate).toString()
              : credit.points.min.toString(),
          creditPointsName: formatStringAsCapitalizedString(creditCurrency),
        }
      : null;
  return (
    <Box
      display="flex"
      justifyContent={exchangeRateContent ? "space-between" : "flex-end"}
      alignItems="center"
    >
      {exchangeRateContent && (
        <RateContainer height="100%">
          <Typography
            id="conversion-rate"
            variant="small"
            color="neutralTones.5"
            backgroundColor="neutralTones.3"
            paddingY="xxs"
            paddingX="xs"
          >
            {replacePlaceholderString(
              content.exchangeRateText,
              exchangeRateContent
            )}
          </Typography>
        </RateContainer>
      )}
      {isSwitchEnabledGlobal && (
        <Button
          id="switch-direction"
          variant="icon"
          height="xxl-2"
          width="xxl-2"
          marginRight={"s"}
          my={"s"}
          aria-label={content.switchButtonAriaLabel}
          onClick={handleSwitchDirection}
          disabled={isSwitchDisabledLocal}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon type="regular" name="arrow-down-arrow-up" />
        </Button>
      )}
    </Box>
  );
};

export default ConversionRateSwitch;
