import { PairPartnersDTO, ParsedPairPartners } from "./pairPartners.model";

const pairPartnersParser = (
  dto: PairPartnersDTO["data"]
): ParsedPairPartners["data"] | undefined => {
  if (!dto || dto.items.length === 0) {
    return undefined;
  }

  const { partners } = dto.items[0].fields;

  return {
    partners: partners.map((partner) => ({
      id: partner.fields.partnerId,
      name: partner.fields.partnerName,
      smallLogo: {
        url: partner.fields.currencyLogo.fields.image.fields.file.url,
        alt: partner.fields.currencyLogo.fields.alt,
      },
      fullLogo: {
        url: partner.fields?.fullLogo?.fields.image.fields.file.url || "",
        alt: partner.fields?.fullLogo?.fields.alt || "",
      },
    })),
  };
};

export default pairPartnersParser;
