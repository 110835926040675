const isSessionExpired = ({
  code,
  status,
}: {
  code: string;
  status: number;
}): boolean => {
  const sessionErrorCodes = ["UNAUTHORIZED", "SESSION_NOT_FOUND"];

  return status === 401 && sessionErrorCodes.includes(code.toLocaleUpperCase());
};

export default isSessionExpired;
