import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { useHistory } from "react-router";

import bannersIds from "../../dictionaries/bannersIds";
import modalsIds from "../../dictionaries/modalsIds";
import tooltipsIds from "../../dictionaries/tooltipsIds";
import Accordion from "../../components/Accordion";
import Box from "../../components/Box";
import ConversionAutomaticSection from "../../components/ConversionAutomaticSection";
import ConversionBox from "../../components/ConversionBox";
import ConversionManualSection from "../../components/ConversionManualSection";
import FeatureToggle from "../../components/FeatureToggle";
import HeroSection from "../../components/HeroSection";
import Layout from "../../components/Layout";
import LinkSummary from "../../components/LinkSummary";
import LoadingPage from "../../components/LoadingPage";
import MainContainer from "../../components/MainContainer";
import Typography from "../../components/Typography";
import Promotions from "../../components/Promotions";
import GamingAction from "../../components/GamingAction";
import { useNotifications } from "../../context/Notifications/NotificationsContext";
import { RoutesEnum } from "../../config/routes.enum";
import { useAuthentication } from "../../context/Authentication/AuthenticationContext";
import { linkNewAccount, unlinkAccount } from "../../services/Accounts";
import { useTransaction } from "../../context/Transaction/TransactionContext";
import { useAutoConversion } from "../../context/AutoConversion/AutoConversionContext";
import { removeSearchParams } from "../../utils/routing";
import { trackers } from "../../utils/ga";
import { ParsedConversionSection } from "../../services/Contentful/parsers/conversionSection/conversionSectionParser.model";
import { ParsedHeroSection } from "../../services/Contentful/parsers/heroSection/heroSectionParser.model";
import { ParsedLinkAccountsSection } from "../../services/Contentful/parsers/linkAccountsSection/linkAccountsSectionParser.model";
import { ParsedPage } from "../../services/Contentful/parsers/page/pageParser.model";
import { ParsedTooltips } from "../../services/Contentful/parsers/tooltips/tooltipsParser.model";
import { usePartnershipContext } from "../../context/Partnership/PartnershipContext";
import { ConversionStatusTemplatesProps } from "../../services/Contentful/Contentful.model";
import { ConversionType } from "../../components/ConversionStatus/ConversionStatus.model";
import { ParsedPromotion } from "../../services/Contentful/parsers/promotion/promotionParser.model";
import {
  getConversionSectionContent,
  getHeroSectionContent,
  getLinkAccountsSectionContent,
  getPageContent,
  getPromotionContent,
  getTooltipsContent,
} from "../../services/Contentful";
import { promotionTypesIds } from "../../dictionaries/promotionTypesIds";
import { GamingCampaign } from "../../dictionaries/gamingCampaignId";
import { StateStatus } from "../../dictionaries/stateStatus";

type TPageContent = {
  exchangePage: ParsedPage["data"];
  heroSection: ParsedHeroSection["data"];
  conversionSection: ParsedConversionSection["data"];
  linkAccountsSection: ParsedLinkAccountsSection["data"];
  tooltips: ParsedTooltips["data"];
  promotion?: ParsedPromotion["data"];
};

type TCategorizedTemplates = {
  [key in ConversionType]: ConversionStatusTemplatesProps[];
};

interface ExchangePageState {
  pageContent?: TPageContent;
  isLoading: boolean;
}

type ActionTypes = "update page content" | "loading" | "finished loading";

interface Action {
  type: ActionTypes;
  /* eslint @typescript-eslint/no-explicit-any: "off" */
  payload?: any;
}
const initialState: ExchangePageState = {
  pageContent: undefined,
  isLoading: false,
};

function exchangeReducer(
  state: ExchangePageState,
  action: Action
): ExchangePageState {
  switch (action.type) {
    case "update page content":
      return {
        ...state,
        pageContent: action.payload,
      };
    case "loading":
      return {
        ...state,
        isLoading: true,
      };
    case "finished loading":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}

const Exchange: React.FC = () => {
  const history = useHistory();
  const [{ isLoading, pageContent }, dispatch] = useReducer(
    exchangeReducer,
    initialState
  );
  const { getModalTemplate, addBanner, addModal } = useNotifications();
  const { handleGetTransactionInfo, transaction } = useTransaction();
  const { aoiState } = useAutoConversion();
  const {
    user: authenticatedUser,
    handleUnlinkingAccount,
    isLoadingUser,
    expireUserSession,
  } = useAuthentication();
  const {
    data: { pairId, basePartnerId, externalPartnerId, urlPartnerPath },
  } = usePartnershipContext();
  const [gamingStorageContent, setGamingStorageContent] = useState(() => {
    try {
      const value = window.sessionStorage.getItem(GamingCampaign.BAEC_LOUNGE);

      if (value && urlPartnerPath === "nectar") {
        return JSON.parse(value);
      }
      return null;
    } catch (err) {
      return null;
    }
  });

  const getUnlinkingLimitTooltip = useCallback(
    () =>
      pageContent?.tooltips?.find(
        (tooltip) => tooltip.id === tooltipsIds.unlinkingLimit
      )?.tooltipMessage || "",
    [pageContent?.tooltips]
  );

  const unlinkingLimitTooltip = getUnlinkingLimitTooltip();

  const maximumLinkingTimesReached = useCallback(() => {
    return authenticatedUser?.linkingCaps?.remaining === 0;
  }, [authenticatedUser?.linkingCaps?.remaining]);

  const handleLinkAccount = useCallback(async () => {
    if (!externalPartnerId || maximumLinkingTimesReached()) {
      return;
    }

    trackers.trackExchangeOperation("link account", {
      link_from: basePartnerId?.toLocaleLowerCase(),
      link_to: externalPartnerId.toLocaleLowerCase(),
      memberID: authenticatedUser?.membershipNumber,
      point_of_origin: basePartnerId,
      is_linked: authenticatedUser?.accountIsLinked,
    });

    dispatch({ type: "loading" });
    const { data, error } = await linkNewAccount(
      externalPartnerId,
      expireUserSession
    );

    if (error) {
      addBanner({ bannerId: bannersIds.LINKING_ERROR });
    }

    if (data?.url) {
      window.location.assign(data.url);
    }
  }, [
    authenticatedUser?.accountIsLinked,
    basePartnerId,
    externalPartnerId,
    addBanner,
    expireUserSession,
    maximumLinkingTimesReached,
    authenticatedUser?.membershipNumber,
  ]);

  const handleUnlinkAccountAction = useCallback(async () => {
    if (!externalPartnerId) {
      return;
    }
    dispatch({ type: "loading" });
    const { data, error } = await unlinkAccount(
      externalPartnerId,
      expireUserSession
    );
    if (data) {
      trackers.trackExchangeOperation("unlink account", {
        link_from: basePartnerId?.toLocaleLowerCase(),
        link_to: externalPartnerId.toLocaleLowerCase(),
        point_of_origin: basePartnerId,
        is_linked: authenticatedUser?.accountIsLinked,
      });

      handleUnlinkingAccount();
    }

    if (error) {
      addBanner({ bannerId: bannersIds.INTERNAL_ERROR });
    }

    dispatch({ type: "finished loading" });
  }, [
    addBanner,
    authenticatedUser?.accountIsLinked,
    basePartnerId,
    externalPartnerId,
    handleUnlinkingAccount,
    expireUserSession,
  ]);

  const handleUnlinkAccount = () => {
    const unlinkingModal = getModalTemplate(modalsIds.UNLINKING);

    if (!unlinkingModal) {
      return;
    }

    const modal = maximumLinkingTimesReached()
      ? unlinkingModal
      : { ...unlinkingModal, secondaryMessage: undefined };

    addModal({
      ...modal,
      handleClickCta: handleUnlinkAccountAction,
      hasCloseButton: true,
    });
  };

  const showErrorModal = useCallback(
    (errors: string) => {
      // For link we return the different mismatch type errros separated by , but the modal should be the same for al errors
      // we plan to mount the modals for each error type and then we can remove this includes check
      let modal = getModalTemplate(modalsIds.GENERIC_ERROR);

      const mappedError = modalsIds[errors];
      if (mappedError) {
        modal = getModalTemplate(mappedError);
      } else if (errors.toUpperCase().includes("MISMATCH")) {
        modal = getModalTemplate(modalsIds.MATCHING_ERRORS);
      }

      if (!modal) {
        return;
      }

      const redirectToUrl = () => {
        modal?.redirectionWebsite &&
          window.location.assign(modal.redirectionWebsite);
      };

      addModal(
        {
          ...modal,
          handleClickCta: redirectToUrl,
          hasCloseButton: true,
        },
        () => removeSearchParams(["error", "operation", "linking_error"])
      );
    },
    [addModal, getModalTemplate]
  );

  const categorizedTemplates = useMemo(
    () =>
      pageContent?.conversionSection?.conversionStatusTemplates.reduce(
        (acc, template) => {
          acc[template.type] = acc[template.type] || [];
          acc[template.type].push(template);
          return acc;
        },
        {} as TCategorizedTemplates
      ),
    [pageContent?.conversionSection?.conversionStatusTemplates]
  );

  useEffect(() => {
    const getContent = async (pairId: string) => {
      const { data: pageContent, error: pageContentError } =
        await getPageContent(pairId, "Exchange");
      const { data: heroSectionContent, error: heroSectionError } =
        await getHeroSectionContent(pairId);
      const { data: conversionSectionContent, error: conversionSectionError } =
        await getConversionSectionContent(pairId);
      const { data: promotionContent, error: promotionContentError } =
        await getPromotionContent(pairId);
      const {
        data: linkAccountSectionContent,
        error: linkAccountSectionError,
      } = await getLinkAccountsSectionContent(pairId);
      const { data: tooltipsContent, error: tooltipsError } =
        await getTooltipsContent(pairId);

      if (
        [
          pageContentError,
          heroSectionError,
          conversionSectionError,
          linkAccountSectionError,
          tooltipsError,
          promotionContentError,
        ].some((error) => error)
      ) {
        history.push(RoutesEnum.OOPS);
        return;
      }

      dispatch({
        type: "update page content",
        payload: {
          exchangePage: pageContent,
          heroSection: heroSectionContent,
          conversionSection: conversionSectionContent,
          linkAccountsSection: linkAccountSectionContent,
          tooltips: tooltipsContent,
          promotion: promotionContent?.filter((promotion) =>
            promotion.promotionType.includes(promotionTypesIds.PARTNER_PAGE)
          ),
        },
      });
    };
    getContent(pairId);
  }, [history, pairId, aoiState, authenticatedUser?.accountIsLinked]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const allowedOperations = ["link", "login"];

    const errors = params.get("error") || params.get("linking_error");
    const queryOperation = params.get("operation") || "";
    const operation =
      params.has("operation") && allowedOperations.includes(queryOperation)
        ? params.get("operation")
        : null;
    const gamingParam = params.get(GamingCampaign.BAEC_LOUNGE);

    if (urlPartnerPath === "nectar" && gamingParam) {
      window.sessionStorage.setItem(
        GamingCampaign.BAEC_LOUNGE,
        JSON.stringify({ allowed: true })
      );
      removeSearchParams([GamingCampaign.BAEC_LOUNGE]);
      setGamingStorageContent({ allowed: true });
    }
    // Displays success linking and login operations banners
    if (operation && !errors) {
      addBanner({
        bannerId: bannersIds[operation.toLocaleUpperCase()],
        handleAdditionalOnClose: () => removeSearchParams(["operation"]),
      });
      return;
    }

    // Displays success aoi subscription. It's being considered that the state param only exists if it's aoi subscription
    // which is wrong, we can have state param for other operations as well, such as mfa
    const stateParam = params.get("state");

    if (stateParam && stateParam === StateStatus.SUCCESS) {
      addBanner({
        bannerId: bannersIds.AOI_SUBSCRIPTION_SUCCESS,
        handleAdditionalOnClose: () => removeSearchParams(["state"]),
      });
      return;
    }

    if (errors) {
      // Displays error banners that are related with linking/login operations if they are mapped
      const errorBanner = bannersIds[errors];
      if (operation && errorBanner) {
        addBanner({
          bannerId: errorBanner,
          handleAdditionalOnClose: () =>
            removeSearchParams(["error", "operation", "linking_error"]),
        });
        return;
      } else {
        //If the banner is not mapped for login or link operations show an error modal
        // Assuming we should show an error modal for any other errors
        showErrorModal(errors);
      }
    }
  }, [addBanner, addModal, showErrorModal, urlPartnerPath]);

  useEffect(() => {
    if (!isLoadingUser && !authenticatedUser) {
      history.push(RoutesEnum.LANDING.replace(":id", urlPartnerPath));
    }
  }, [authenticatedUser, isLoadingUser, history, urlPartnerPath]);

  useEffect(() => {
    const getMfaTransactionStatus = async (transactionId: string) => {
      await handleGetTransactionInfo(transactionId);
      removeSearchParams(["id"]);
    };

    const params = new URLSearchParams(location.search);
    const transactionId = params.has("id") ? params.get("id") : "";

    if (transactionId && !transaction) {
      getMfaTransactionStatus(transactionId);
    }
  }, [handleGetTransactionInfo, transaction]);

  useEffect(() => {
    if (
      authenticatedUser?.partnerAccount?.permissions?.qualifiedToExchange ===
      false
    ) {
      const modal = getModalTemplate(modalsIds.AVIOS_QUALIFIED);

      if (!modal) {
        return;
      }

      addModal({
        ...modal,
        hasCloseButton: true,
      });
    }
  }, [
    addModal,
    authenticatedUser?.partnerAccount?.permissions?.qualifiedToExchange,
    getModalTemplate,
  ]);

  const promotionRules = [
    {
      accountLinkedAoiNotActive: Boolean(
        authenticatedUser?.accountIsLinked && !Object.keys(aoiState).length
      ),
    },
  ];

  if (!pageContent) {
    return <LoadingPage />;
  }

  return (
    <MainContainer>
      <Layout>
        {pageContent.exchangePage?.pageTitle && (
          <Typography
            variant="h1"
            color="text"
            mb="xl"
            px={{ _: "l", tablet: 0 }}
            data-cy="header-title"
          >
            {pageContent.exchangePage.pageTitle}
          </Typography>
        )}
        <Promotions content={pageContent.promotion} rules={promotionRules} />

        {pageContent?.heroSection && (
          <HeroSection
            {...pageContent.heroSection}
            displayButton={!authenticatedUser?.accountIsLinked}
            buttonAction={
              authenticatedUser?.accountIsLinked
                ? handleUnlinkAccount
                : handleLinkAccount
            }
            mb="m"
            disableButton={maximumLinkingTimesReached()}
            disabledButtonTooltip={
              maximumLinkingTimesReached() && unlinkingLimitTooltip
            }
            isLoading={isLoading}
          />
        )}
        {gamingStorageContent && (
          <GamingAction storageHandler={setGamingStorageContent} />
        )}
        {pageContent?.linkAccountsSection && (
          <LinkSummary
            mb="m"
            content={pageContent.linkAccountsSection}
            handleUnlinkAccount={handleUnlinkAccount}
            handleLinkAccount={handleLinkAccount}
            disabledButtonTooltip={unlinkingLimitTooltip}
          />
        )}
        {pageContent?.conversionSection && (
          <Box backgroundColor="neutralTones.0" py="m">
            {pageContent.conversionSection.displayTitle && (
              <Typography
                variant="h2"
                color="text"
                mx="m"
                mb="m"
                data-cy="product-description-title"
              >
                {pageContent.conversionSection.title}
              </Typography>
            )}
            {pageContent.conversionSection.displayManualConversion &&
              pageContent.conversionSection.manualConversionBoxProps && (
                <ConversionBox
                  {...pageContent.conversionSection.manualConversionBoxProps}
                >
                  {categorizedTemplates && (
                    <ConversionManualSection
                      unlinkedConversionPlaceholder={
                        pageContent.conversionSection
                          .unlinkedConversionPlaceholder
                      }
                      conversionManualProps={
                        pageContent.conversionSection.conversionManualProps
                      }
                      exchangeContent={
                        pageContent.conversionSection.manualConversionBoxProps
                          ?.exchangeBox
                      }
                      conversionStatusTemplates={categorizedTemplates.manual}
                    />
                  )}
                </ConversionBox>
              )}
            {pageContent.conversionSection.displayAutoConversion &&
              pageContent.conversionSection.autoConversionBoxProps && (
                <FeatureToggle feature="AOI">
                  <ConversionBox
                    {...pageContent.conversionSection.autoConversionBoxProps}
                  >
                    {categorizedTemplates &&
                      pageContent.conversionSection.autoConversionBoxProps
                        .exchangeBox && (
                        <ConversionAutomaticSection
                          exchangeContent={{
                            ...pageContent.conversionSection
                              .autoConversionBoxProps.exchangeBox,
                          }}
                          conversionStatusTemplates={categorizedTemplates.aoi}
                          isSwitchEnabledGlobal={
                            pageContent.conversionSection.conversionManualProps
                              .isSwitchEnabledGlobal
                          }
                        />
                      )}
                  </ConversionBox>
                </FeatureToggle>
              )}
            {pageContent.conversionSection.displayAccordion &&
              pageContent.conversionSection.accordionSections && (
                <Box px="m">
                  <Accordion
                    sections={pageContent.conversionSection.accordionSections}
                  />
                </Box>
              )}
          </Box>
        )}
      </Layout>
    </MainContainer>
  );
};

export default Exchange;
