import { useEffect } from "react";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import { trackers } from "../../utils/ga";

interface IGAListener extends RouteComponentProps {
  children: JSX.Element;
}

const GAListener = ({ children, history }: IGAListener): JSX.Element => {
  useEffect(() => {
    trackers.trackPageView();

    return history.listen(trackers.trackPageView);
  }, [history]);

  return children;
};

export default withRouter(GAListener);
