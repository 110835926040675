import React from "react";
import css from "@styled-system/css";
import styled from "styled-components";

import Box from "../Box";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";
import { BulletsProps } from "./Bullets.model";

const Bullet = styled.button<LayoutProps & SpaceProps & { isActive: boolean }>`
  cursor: pointer;
  border: none;
  border-radius: 50%;
  transition: background-color 0.5s;

  ${layout}
  ${space}
  ${(props) =>
    css({
      backgroundColor: props.isActive ? "neutralTones.6" : "neutralTones.4",
    })}
`;

const Bullets: React.FC<BulletsProps> = ({
  activeIndex,
  numberOfBullets,
  setActiveIndex,
}) => {
  return (
    <Box
      role="radiogroup"
      width="100%"
      pt="xl"
      display="flex"
      justifyContent="center"
    >
      {Array(numberOfBullets)
        .fill(undefined)
        .map((_bullet, index) => (
          <Bullet
            role="radio"
            key={index}
            data-active={index === activeIndex}
            isActive={index === activeIndex}
            width="s"
            height="s"
            mr={index < numberOfBullets - 1 ? "l" : 0}
            onClick={() =>
              setActiveIndex(numberOfBullets < 2 ? index : index + 1)
            }
          />
        ))}
    </Box>
  );
};

export default Bullets;
