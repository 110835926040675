import {
  ParsedTermsAndConditionsPage,
  TermsAndConditionsPageDTO,
} from "./termsAndConditionsPageParser.model";
import richTextToComponents from "../../../../utils/richTextToComponents";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const termsAndConditionsPageParser = (
  dto: TermsAndConditionsPageDTO["data"]
): ParsedTermsAndConditionsPage["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const { title, terms, accordion } = dto.items[0].fields;

  return {
    title,
    terms: richTextToComponents(terms),
    accordionSections: accordion?.fields.sections.map((section) => ({
      header: section.fields.header,
      body: documentToReactComponents(section.fields.body),
    })),
  };
};

export default termsAndConditionsPageParser;
