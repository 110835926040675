import React from "react";

import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";
import SuccessInfoBox from "../SuccessInfoBox";
import Typography from "../Typography";
import { ButtonVariant } from "../Button/Button.model";
import { aoiStatusIds } from "../../dictionaries/aoiStatusIds";
import {
  ConversionStatusProps,
  TConversionStatus,
} from "./ConversionStatus.model";
import { usePartnershipContext } from "../../context/Partnership/PartnershipContext";
import {
  formatStringAsCapitalizedString,
  replacePlaceholderString,
  formatNumberAsLocalisedString,
} from "../../utils/strings";

const headingColorMap: Record<TConversionStatus, string> = {
  success: "success.0",
  pending: "warning.1",
  failure: "error.0",
  active: "text",
};

const getButtonVariant = (status: TConversionStatus) => {
  const buttonStyleMap: Record<TConversionStatus, ButtonVariant> = {
    success: "secondary",
    pending: "outlined",
    failure: "outlined",
    active: "text",
  };
  return buttonStyleMap[status];
};

const ConversionStatus: React.FC<ConversionStatusProps> = ({
  status,
  amountConverted = undefined,
  handleClickCta,
  isLoading = false,
  content,
  type = "manual",
  periodicity = "",
  sourcePartnerId,
}) => {
  const { getPartners } = usePartnershipContext();
  const {
    iconName,
    heading,
    description,
    conversionDirectionLabel,
    conversionDirectionText,
    amountConvertedLabel,
    amountConvertedText,
    conversionFrequencyLabel,
    ctaButtonText,
  } = content;
  const showBottomBorder = status.toLocaleUpperCase() !== aoiStatusIds.ACTIVE;

  const headingWrapperProps = showBottomBorder
    ? {
        borderBottom: "1px solid",
        borderColor: "neutralTones.3",
      }
    : {};

  const buttonIdentifier =
    status.toLocaleUpperCase() === aoiStatusIds.ACTIVE && type === "aoi"
      ? {
          id: "unsubscribe-aoi-button",
        }
      : {};

  const getTransactionDirectionLabel = (id: string) => {
    const { creditPartner: source, debitPartner: destination } =
      getPartners(id);
    return {
      source: formatStringAsCapitalizedString(source.id),
      destination: formatStringAsCapitalizedString(destination.id),
    };
  };

  return (
    <Box
      padding="m"
      pt="s"
      display="flex"
      flexDirection="column"
      minHeight={280}
    >
      {(iconName || heading) && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingBottom="s"
          {...headingWrapperProps}
        >
          {iconName && (
            <Icon
              type="regular"
              name={iconName}
              color={headingColorMap[status]}
              width={30}
              height={30}
              marginRight="s"
              rotate={status === "pending" ? "180deg" : "0"}
            />
          )}
          {heading && (
            <Typography
              variant="xl"
              textAlign="left"
              paddingX={type === "aoi" ? "s" : 0}
              color={headingColorMap[status]}
            >
              {heading}
            </Typography>
          )}
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-evenly"
        marginTop={showBottomBorder ? "m" : 0}
        flex={1}
      >
        <Typography
          variant="sxm"
          color="neutralTones.5"
          textAlign={status === "success" ? "left" : "center"}
          maxWidth={{ desktop: 330 }}
        >
          {description}
        </Typography>
        {conversionDirectionLabel &&
          conversionDirectionText &&
          sourcePartnerId && (
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <SuccessInfoBox
                label={conversionDirectionLabel}
                text={replacePlaceholderString(
                  conversionDirectionText,
                  getTransactionDirectionLabel(sourcePartnerId)
                )}
              />
              {amountConvertedLabel &&
                amountConvertedText &&
                amountConverted && (
                  <SuccessInfoBox
                    label={amountConvertedLabel}
                    text={replacePlaceholderString(amountConvertedText, {
                      amountConverted:
                        formatNumberAsLocalisedString(amountConverted),
                      source: formatStringAsCapitalizedString(
                        getPartners(sourcePartnerId).debitPartner
                          .currencyName || ""
                      ),
                    })}
                  />
                )}
              {conversionFrequencyLabel && (
                <SuccessInfoBox
                  label={conversionFrequencyLabel}
                  text={periodicity}
                />
              )}
            </Box>
          )}

        <Button
          {...buttonIdentifier}
          variant={getButtonVariant(status)}
          fontSize="s"
          marginTop="auto"
          onClick={handleClickCta}
          loading={isLoading}
          disabled={isLoading}
        >
          {ctaButtonText}
        </Button>
      </Box>
    </Box>
  );
};

export default ConversionStatus;
