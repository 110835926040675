import React from "react";

import AoiButtonsHandler from "../../AoiButtonsHandler";
import Box from "../../Box";
import SuccessInfoBox from "../../SuccessInfoBox";
import { formatStringAsCapitalizedString } from "../../../utils/strings";
import { useAutoConversion } from "../../../context/AutoConversion/AutoConversionContext";

import config from "../../../build-config.json";
import getConfig from "../../../utils/configGetter";

const ConfirmationStep = (): JSX.Element => {
  const { configPayloadState, handleAoiSubscription, isLoadingAoiInfo } =
    useAutoConversion();
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" flex={1}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flexGrow={1}
      >
        <SuccessInfoBox
          variant="small"
          color={getConfig<string>(config.colors, "tertiary", "tertiary")}
          label={"Conversion direction"}
          text={`${formatStringAsCapitalizedString(
            configPayloadState.sourcePartner || ""
          )} to ${formatStringAsCapitalizedString(
            configPayloadState.destinationPartner || ""
          )}`}
        />
        <SuccessInfoBox
          variant="small"
          color={getConfig<string>(config.colors, "tertiary", "tertiary")}
          label={"Amount of points"}
          text={configPayloadState.amountOfPoints || ""}
        />
        <SuccessInfoBox
          variant="small"
          color={getConfig<string>(config.colors, "tertiary", "tertiary")}
          label={"Conversion frequency"}
          text={configPayloadState.conversionFrequency || ""}
        />
      </Box>
      <AoiButtonsHandler
        nextButtonProps={{
          variant: "secondary",
          disabled: isLoadingAoiInfo,
          padding: 13,
          onClick: handleAoiSubscription,
        }}
      />
    </Box>
  );
};

export default ConfirmationStep;
