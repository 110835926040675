import React, { useEffect, useState } from "react";
import Promotion from "../Promotion";
import { IPromotionsProps } from "./Promotions.model";

const Promotions = ({
  content,
  rules,
}: IPromotionsProps): JSX.Element | null => {
  const [qualifiedPromotions, setQualifiedPromotions] =
    useState<IPromotionsProps["content"]>();

  useEffect(() => {
    setQualifiedPromotions(() => {
      if (!content) return;

      if (!rules || !rules.length)
        return content.filter((promotion) => {
          if (!promotion.displayConditions) return promotion;
        });

      const ruleSet = Object.values(rules).reduce((rulesAcc, rule) => {
        Object.entries(rule).forEach(([ruleName, satisfied]) => {
          if (satisfied) rulesAcc.push(ruleName);
        });
        return rulesAcc;
      }, [] as string[]);

      return content.filter((promotion) => {
        if (!promotion.displayConditions) return promotion;

        return promotion.displayConditions.every((promotionRule) =>
          ruleSet.includes(promotionRule)
        );
      });
    });
  }, [content, rules]);

  if (!qualifiedPromotions) return null;

  return (
    <>
      {qualifiedPromotions.map((promotion) => (
        <Promotion key={promotion.id} {...promotion} />
      ))}
    </>
  );
};

export default Promotions;
