import { PortalProps } from "./Portal.model";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

function createRootElement(id: string): HTMLDivElement {
  const rootContainer = document.createElement("div");
  rootContainer.setAttribute("id", id);
  rootContainer.setAttribute("data-testid", id);
  return rootContainer;
}

function addRootElement(rootElem: Element) {
  if (!document.body.lastElementChild) {
    return;
  }
  document.body.insertBefore(
    rootElem,
    document.body.lastElementChild.nextElementSibling
  );
}

function removeElement(
  ref: React.MutableRefObject<HTMLDivElement | null>,
  parentElem: Element
) {
  if (ref.current) {
    ref.current.remove();
  }
  if (!parentElem.childElementCount) {
    parentElem.remove();
  }
}

const Portal: React.FC<PortalProps> = ({ id, shouldShow, children }) => {
  const rootElemRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const existingParent = document.querySelector(`#${id}`);
    const parentElem = existingParent || createRootElement(id);

    if (!shouldShow) {
      return removeElement(rootElemRef, parentElem);
    }

    if (!existingParent) {
      addRootElement(parentElem);
    }

    if (parentElem && rootElemRef.current) {
      parentElem.appendChild(rootElemRef.current);
    }

    return () => removeElement(rootElemRef, parentElem);
  }, [id, shouldShow]);

  function getRootElem() {
    if (!rootElemRef.current) {
      rootElemRef.current = document.createElement("div");
    }
    return rootElemRef.current;
  }

  const target = getRootElem();

  return createPortal(children, target);
};

export default Portal;
