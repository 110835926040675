import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";
import SuccessInfoBox from "../SuccessInfoBox";
import Typography from "../Typography";
import { ConversionAutomaticSectionProps } from "./ConversionAuto.model";
import { useAuthentication } from "../../context/Authentication/AuthenticationContext";
import { usePartnershipContext } from "../../context/Partnership/PartnershipContext";
import { useAutoConversion } from "../../context/AutoConversion/AutoConversionContext";
import { trackers } from "../../utils/ga";
import { GridProps } from "styled-system";
import {
  formatStringAsCapitalizedString,
  replacePlaceholderString,
} from "../../utils/strings";
import useAccountPermissions from "../../pages/Exchange/useAccountPermissions";

import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

const Logo = styled.img`
  height: 44px;
`;

const LogoContainer = styled.div<GridProps>`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: ${(props) => props.gridArea};
`;

const ContainerGrid = styled(Box)`
  display: grid;
  grid-template-areas: "leftPartner switcher rightPartner";
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
`;

const ConversionAuto: React.FC<ConversionAutomaticSectionProps> = ({
  isSwitchEnabledGlobal = false,
  exchangeContent = undefined,
  handleAoiSetup,
}) => {
  const { user: authenticatedUser } = useAuthentication();
  const {
    data: { basePartnerId, externalPartnerId },
    getPartners,
  } = usePartnershipContext();

  const [ownerId, setOwnerId] = useState(basePartnerId);

  const { creditPartner, debitPartner } = getPartners(ownerId || "");

  const { handleAoiSubscription, isLoadingAoiInfo, dispatchConfigPayload } =
    useAutoConversion();

  const { isSwitchDisabledLocal } = useAccountPermissions({
    isSwitchEnabledGlobal: isSwitchEnabledGlobal,
    setOwnerId,
  });

  const handleSwitchDirection = useCallback(() => {
    setOwnerId((prevOwnerId) => {
      const sourcePartnerId =
        prevOwnerId === basePartnerId ? externalPartnerId : basePartnerId;
      const destinationPartnerId =
        prevOwnerId === basePartnerId ? basePartnerId : externalPartnerId;

      trackers.trackExchangeOperation("switch partner", {
        memberID: authenticatedUser?.membershipNumber,
        convert_from: destinationPartnerId,
        convert_to: sourcePartnerId,
        point_of_origin: basePartnerId,
        location: "auto conversion component",
      });

      return sourcePartnerId;
    });
  }, [authenticatedUser?.membershipNumber, basePartnerId, externalPartnerId]);

  useEffect(() => {
    dispatchConfigPayload({
      type: "set direction",
      payload: {
        sourcePartner: debitPartner.id,
        destinationPartner: creditPartner.id,
      },
    });
  }, [debitPartner, creditPartner, dispatchConfigPayload]);

  if (!exchangeContent) {
    return <></>;
  }

  return (
    <Box
      padding="m"
      pt="s"
      display="flex"
      flexDirection="column"
      minHeight={280}
    >
      <Typography
        mb="s"
        variant="xl"
        textAlign="center"
        color={getConfig<string>(config.colors, "tertiary", "tertiary")}
      >
        {exchangeContent.heading}
      </Typography>
      <ContainerGrid mb="s">
        <LogoContainer gridArea="leftPartner">
          <Logo
            src={debitPartner.smallLogo?.url}
            alt={debitPartner.smallLogo?.alt}
          />
        </LogoContainer>
        {!isSwitchDisabledLocal && (
          <Box gridArea="switcher">
            <Button
              id="switch-direction"
              variant="icon"
              height="xxl-2"
              width="xxl-2"
              aria-label={exchangeContent.switchButtonAriaLabel}
              onClick={handleSwitchDirection}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon type="regular" name="arrow-down-arrow-up" rotate="90deg" />
            </Button>
          </Box>
        )}
        <LogoContainer gridArea="rightPartner">
          <Logo
            src={creditPartner.smallLogo?.url}
            alt={creditPartner.smallLogo?.alt}
          />
        </LogoContainer>
      </ContainerGrid>
      <Box
        marginBottom="xs"
        display="flex"
        flexDirection="column"
        flex={1}
        justifyContent="flex-end"
      >
        <SuccessInfoBox
          label={exchangeContent.conversionDirectionLabel || ""}
          text={
            exchangeContent.conversionDirectionText
              ? replacePlaceholderString(
                  exchangeContent.conversionDirectionText,
                  {
                    source: formatStringAsCapitalizedString(debitPartner.id),
                    destination: formatStringAsCapitalizedString(
                      creditPartner.id
                    ),
                  }
                )
              : ""
          }
        />
        <SuccessInfoBox
          label={exchangeContent.conversionFrequencyLabel || ""}
          text={exchangeContent.periodicity}
        />
      </Box>
      <Button
        id="confirm-auto-conversion-button"
        variant="secondary"
        fontSize="s"
        mb="m"
        onClick={handleAoiSubscription}
        loading={isLoadingAoiInfo}
        disabled={isLoadingAoiInfo}
        aria-label={exchangeContent.confirmButtonAriaLabel}
      >
        {exchangeContent.confirmButtonText}
      </Button>
      <Button
        id="cancel-auto-conversion-button"
        variant="text"
        fontSize="s"
        onClick={handleAoiSetup}
        disabled={isLoadingAoiInfo}
        aria-label={exchangeContent.cancelButtonAriaLabel}
      >
        {exchangeContent.cancelButtonText}
      </Button>
    </Box>
  );
};

export default ConversionAuto;
