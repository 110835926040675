import fireGaEvent from "./fireGaEvent";
import {
  TAdditionalVariables,
  TAuthAction,
  TNavigationElement,
  TPartnerNavigationElement,
  TUserAction,
} from "./tracker.model.ts";

const trackNavigation = (
  nav_element: TNavigationElement | TPartnerNavigationElement<string>
) => {
  fireGaEvent("navigation", {
    nav_element,
  });
};

const trackPageView = () => {
  fireGaEvent("gtm.js");
};

const trackAuthentication = ({
  location,
  user_action,
}: {
  location: string;
  user_action: TAuthAction;
}) => {
  fireGaEvent("online_loginbutton", {
    location,
    user_action,
  });
};

const trackExchangeOperation = (
  user_action: TUserAction,
  additionalVariables?: TAdditionalVariables
) => {
  fireGaEvent("exchange_operation", {
    user_action,
    ...additionalVariables,
  });
};

const trackPromotionInteraction = (
  additionalVariables?: TAdditionalVariables
) => {
  fireGaEvent("promo_click", {
    ...additionalVariables,
  });
};

export {
  trackNavigation,
  trackPageView,
  trackAuthentication,
  trackExchangeOperation,
  trackPromotionInteraction,
};
