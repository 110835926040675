const createSecureRandomKey = (): number => {
  const KEY_LENGTH = 15;

  const crypto = window.crypto;

  const combinationArray = new Uint32Array(KEY_LENGTH);
  crypto.getRandomValues(combinationArray);

  const randomKey = combinationArray[0];

  return randomKey;
};

export default createSecureRandomKey;
