import React, { useCallback, useEffect, useRef, useState } from "react";

import modalsIds from "../../dictionaries/modalsIds";
import useAccountPermissions from "../../pages/Exchange/useAccountPermissions";
import Box from "../Box";
import Button from "../Button";
import ConversionQuickSelect from "../ConversionQuickSelect";
import ConversionRateSwitch from "../ConversionRateSwitch";
import Input from "../Input";
import { ConversionQuickSelectOptions } from "../ConversionQuickSelect/ConversionQuickSelect.model";
import {
  ConversionInputErrorType,
  ConversionManualProps,
} from "./ConversionManual.model";
import { useTransaction } from "../../context/Transaction/TransactionContext";
import { useAuthentication } from "../../context/Authentication/AuthenticationContext";
import { useNotifications } from "../../context/Notifications/NotificationsContext";
import { useFeatureToggleContext } from "../../context/FeatureToggle/FeatureToggleProvider";
import { usePartnershipContext } from "../../context/Partnership/PartnershipContext";
import { trackers } from "../../utils/ga";
import { replacePlaceholderString } from "../../utils/strings";
import { getInputError, validateInput } from "./helpers";

const ConversionManual: React.FC<ConversionManualProps> = ({
  isSwitchEnabledGlobal = false,
  isQuickOptionsEnabled = false,
  exchangeContent = undefined,
}) => {
  const [debitAmount, setDebitAmount] = useState<number>(0);
  const [inputError, setInputError] = useState<ConversionInputErrorType>();
  const [quickSelectOptions, setQuickSelectOptions] =
    useState<ConversionQuickSelectOptions>({
      prev: null,
      next: null,
      max: null,
    });
  const {
    data: { basePartnerId, externalPartnerId, partnersConfiguration },
    getPartners,
  } = usePartnershipContext();
  const [ownerId, setOwnerId] = useState(() => {
    const sessionStorageValue = sessionStorage.getItem(
      "manualConversionSourceId"
    );

    if (!sessionStorageValue) {
      return basePartnerId;
    }

    if ([basePartnerId, externalPartnerId].includes(sessionStorageValue)) {
      return sessionStorageValue;
    }

    sessionStorage.removeItem("manualConversionSourceId");
    return basePartnerId;
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const features = useFeatureToggleContext();
  const { handlePlaceOrder, transactionIsPending } = useTransaction();
  const { user: authenticatedUser } = useAuthentication();
  const { getModalTemplate, addModal } = useNotifications();
  const { isInputDisabled, isSwitchDisabledLocal, disableSubmitButton } =
    useAccountPermissions({
      isSwitchEnabledGlobal: isSwitchEnabledGlobal,
      setOwnerId,
    });
  const { creditPartner, debitPartner } = getPartners(ownerId || "");
  const debitPartnerRate =
    debitPartner?.id && partnersConfiguration
      ? partnersConfiguration[debitPartner.id].rate
      : 0;
  const creditAmount = inputError
    ? 0
    : Math.floor(debitAmount * debitPartnerRate);
  const isConvertButtonDisabled = debitPartner?.points
    ? [
        !Boolean(debitAmount),
        debitAmount % debitPartner.points?.increment !== 0,
        Boolean(inputError),
        transactionIsPending,
        disableSubmitButton,
      ].some((status) => status)
    : false;
  const shouldShowQuickOptions =
    isQuickOptionsEnabled &&
    (quickSelectOptions.prev ||
      quickSelectOptions.next ||
      quickSelectOptions.max);

  const switchDirection = () => {
    setOwnerId((prevOwnerId) => {
      const sourcePartnerId =
        prevOwnerId === basePartnerId ? externalPartnerId : basePartnerId;
      const destinationPartnerId =
        prevOwnerId === basePartnerId ? basePartnerId : externalPartnerId;

      sourcePartnerId &&
        sessionStorage.setItem("manualConversionSourceId", sourcePartnerId);

      trackers.trackExchangeOperation("switch partner", {
        memberID: authenticatedUser?.membershipNumber,
        convert_from: destinationPartnerId,
        convert_to: sourcePartnerId,
        point_of_origin: basePartnerId,
        location: "manual conversion component",
      });

      return sourcePartnerId;
    });
    setDebitAmount(0);
    setInputError(undefined);
    clearQuickSelectOptions();
  };

  const handleSwitchDirection = () => {
    if (!debitPartner || !creditPartner || isSwitchDisabledLocal) {
      return;
    }

    if (
      features &&
      features.switchDirectionModal &&
      creditPartner.id === "AVIOS"
    ) {
      const modal = getModalTemplate(modalsIds.SWITCH_DIRECTION);
      if (!modal) {
        return;
      }
      const redirectToUrl = () => {
        modal?.redirectionWebsite &&
          window.location.assign(modal.redirectionWebsite);
      };

      addModal({
        ...modal,
        handleClickCta: redirectToUrl,
        handleAdditionalOnClose: switchDirection,
      });
      return;
    }
    switchDirection();
  };

  const clearQuickSelectOptions = () => {
    setQuickSelectOptions({
      prev: null,
      next: null,
      max: null,
    });
  };

  const handleWheel = (e: WheelEvent) => {
    e.preventDefault();
  };

  const handleClickQuickSelect =
    (amount: number | null): React.MouseEventHandler<HTMLButtonElement> =>
    () => {
      setDebitAmount(amount || 0);
      clearQuickSelectOptions();
      setInputError(undefined);
    };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amount = parseInt(event.target.value) || 0;
    setDebitAmount(amount);
    const { isValid, errorType, quickSelectPoints } = validateInput({
      debitPartner,
      authenticatedUser,
      basePartnerId,
      partnersConfiguration,
      debitAmount: amount,
    });
    quickSelectPoints && setQuickSelectOptions(quickSelectPoints);
    setInputError(isValid ? undefined : errorType);
  };

  const handleClickConvert = useCallback(async () => {
    if (!partnersConfiguration || !debitPartner || !creditPartner) {
      return;
    }

    const payload = {
      amount: debitAmount,
      targetRate: debitPartner.rate || 0,
      targetAmount: creditAmount,
      source: debitPartner.id,
      destination: creditPartner.id,
    };
    await handlePlaceOrder(payload);
  }, [
    debitAmount,
    partnersConfiguration,
    debitPartner,
    creditAmount,
    creditPartner,
    handlePlaceOrder,
  ]);

  /* Add event listener to disable scroll on the conversion manual component.
   * The event was causing the values to change once the user scrolled.
   */
  useEffect(() => {
    const ref = inputRef.current;
    ref?.addEventListener("wheel", handleWheel, { passive: false });

    return () => ref?.removeEventListener("wheel", handleWheel);
  }, []);

  if (!exchangeContent || !debitPartner?.points || !creditPartner?.points) {
    return <></>;
  }

  return (
    <Box padding="m" display="flex" flexDirection="column">
      <Input
        ref={inputRef}
        disabled={isInputDisabled || transactionIsPending}
        variant="secondary"
        id={`convert-sender`}
        data-cy={debitPartner.id}
        type="number"
        maxLength={20}
        label={exchangeContent.debitLabel}
        placeholder={replacePlaceholderString(
          exchangeContent.debitPlaceholder,
          {
            debitMin: debitPartner.points.min.toString(),
          }
        )}
        value={debitAmount || ""}
        onChange={handleInputChange}
        imageUrl={debitPartner.smallLogo?.url}
        alt={debitPartner.smallLogo?.alt}
        error={inputError && getInputError(exchangeContent, inputError)}
      />
      {shouldShowQuickOptions &&
        exchangeContent.quickSelectLabel &&
        exchangeContent.quickSelectTotalValidLabel && (
          <ConversionQuickSelect
            content={{
              quickSelectLabel: exchangeContent.quickSelectLabel,
              quickSelectTotalValid: exchangeContent.quickSelectTotalValidLabel,
            }}
            quickSelectOptions={quickSelectOptions}
            handleClickQuickSelect={handleClickQuickSelect}
          />
        )}
      <ConversionRateSwitch
        content={{
          exchangeRateText: exchangeContent.exchangeRateText,
          switchButtonAriaLabel: exchangeContent.switchButtonAriaLabel || "",
        }}
        debit={debitPartner}
        credit={creditPartner}
        isSwitchEnabledGlobal={isSwitchEnabledGlobal}
        isSwitchDisabledLocal={transactionIsPending || isSwitchDisabledLocal}
        handleSwitchDirection={handleSwitchDirection}
      />
      <Input
        disabled
        variant="secondary"
        id={`convert-receiver`}
        data-cy={creditPartner.id}
        type="number"
        maxLength={20}
        label={exchangeContent.creditLabel}
        placeholder={replacePlaceholderString(
          exchangeContent.creditPlaceholder,
          {
            creditMin:
              debitPartner.id === "NECTAR" && debitPartner?.rate
                ? (debitPartner.points.min * debitPartner.rate).toString()
                : creditPartner.points.min.toString(),
          }
        )}
        value={creditAmount || ""}
        onChange={() => {}}
        imageUrl={creditPartner.smallLogo?.url}
        alt={creditPartner.smallLogo?.alt}
      />
      <Button
        id="convert-button"
        disabled={isConvertButtonDisabled}
        variant="secondary"
        fontSize="s"
        marginTop="xl"
        onClick={handleClickConvert}
        loading={transactionIsPending}
      >
        {exchangeContent.convertButtonText}
      </Button>
    </Box>
  );
};

export default ConversionManual;
