import { APIError } from "../../Global.model";

import { useAuthentication } from "../../context/Authentication/AuthenticationContext";
import useSWR from "swr";
import fetcher from "./fetcher";

interface State<T> extends APIError {
  data?: T;
}

function useFetch<T = unknown>(url?: string, options?: RequestInit): State<T> {
  const { expireUserSession } = useAuthentication();

  const sessionIsExpired = (response: Response) => {
    const sessionErrorCodes = ["UNAUTHORIZED", "SESSION_NOT_FOUND"];

    return (
      response.status === 401 &&
      sessionErrorCodes.includes(response.statusText.toLocaleUpperCase())
    );
  };

  const swrConfig = {
    shouldRetryOnError: false,
  };

  const { data = undefined, error = undefined } = useSWR<T>(
    [url, options],
    fetcher,
    swrConfig
  );

  if (error) {
    if (sessionIsExpired(error)) {
      expireUserSession(true);
    }
  }
  return {
    data,
    error,
  };
}

export default useFetch;
