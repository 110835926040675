const replacePlaceholderString = (
  textWithPlaceholders: string,
  values: {
    [key: string]: string;
  }
): string => {
  let finalString = textWithPlaceholders;

  for (const entry of Object.entries(values)) {
    const [key, value] = entry;
    const replaceRegex = new window.RegExp(`{{${key}}}`, "g");

    finalString = finalString.replace(replaceRegex, value);
  }

  return finalString;
};

export default replacePlaceholderString;
