import { AnchorVariant } from "./Link.model";

type TVariantKeys = AnchorVariant;

const variants: {
  [key in TVariantKeys]: unknown;
} = {
  primary: {
    backgroundColor: "primary.0",
    color: "neutralTones.0",
    height: 48,
    padding: "16px 32px",
    display: "block",
    textAlign: "center",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "primary.1",
    },
    "&:disabled": {
      opacity: 0.5,
    },
    "&:disabled:hover": {
      backgroundColor: "primary.0",
      opacity: 0.5,
    },
  },
  secondary: {
    backgroundColor: "secondary.0",
    color: "neutralTones.0",
    height: 48,
    padding: "16px 32px",
    display: "block",
    textAlign: "center",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "secondary.1",
    },
    "&:disabled": {
      opacity: 0.5,
    },
    "&:disabled:hover": {
      backgroundColor: "secondary.0",
      opacity: 0.5,
    },
  },
  text: {
    backgroundColor: "transparent",
    color: "secondary.0",
    padding: 0,
    display: "block",
    textAlign: "center",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",

    "&:hover": {
      color: "secondary.1",
    },
    "&:disabled": {
      opacity: 0.5,
    },
    "&:disabled:hover": {
      color: "secondary.0",
      opacity: 0.5,
    },
  },
  outlined: {
    backgroundColor: "transparent",
    color: "secondary.0",
    border: "2px solid",
    borderColor: "secondary.0",
    height: 48,
    padding: "16px 32px",
    display: "block",
    textAlign: "center",
    borderRadius: "4px",
    cursor: "pointer",

    "&:hover": {
      color: "secondary.1",
      borderColor: "secondary.1",
    },
    "&:disabled": {
      opacity: 0.5,
    },
    "&:disabled:hover": {
      color: "secondary.0",
      borderColor: "secondary.0",
      opacity: 0.5,
    },
  },
  navAnchor: {
    backgroundColor: "transparent",
    borderRadius: "0px",
    color: "text",
    paddingX: "xl",
    paddingY: "m",
    display: "block",
    whiteSpace: "nowrap",

    "&:hover": {
      color: "secondary.0",
      backgroundColor: "neutralTones.1",

      svg: {
        fill: "secondary.0",
      },
    },
    "&:disabled": {
      opacity: 0.5,
    },
    "&:disabled:hover": {
      backgroundColor: "secondary.0",
      opacity: 0.5,
    },
  },
  navAnchorIcon: {
    backgroundColor: "transparent",
    borderRadius: "0px",
    color: "secondary.3",
    alignSelf: "flex-start",
    whiteSpace: "nowrap",
    position: "relative",
    display: "inline-block",

    "&:after": {
      content: "''",
      position: "relative",
      width: "7px",
      height: "7px",
      borderTop: "1px solid #2e5c99",
      borderRight: "1px solid #2e5c99",
      transform: "rotate(45deg)",
      marginLeft: "xs",
      display: "inline-block",
    },

    "&:hover": {
      color: "secondary.0",
      textDecoration: "underline",
    },
    "&:disabled": {
      opacity: 0.5,
    },
    "&:disabled:hover": {
      backgroundColor: "secondary.0",
      opacity: 0.5,
    },
  },
};

export default variants;
