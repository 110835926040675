import { ExchangeBox } from "../../ConversionBox/ConversionBox.model";
import {
  ConversionInputErrorType,
  IErrorType,
  IInputErrorArgs,
  IPoints,
  IPointsArgs,
  IQuickSelectPoints,
} from "../ConversionManual.model";

const getInputError = (exchangeContent: ExchangeBox, type: string) => {
  return exchangeContent.inputErrors
    .filter((error) => error.type === type)
    .map((error) => ({
      message: error.message,
      additionalDetails: error.additionalDetails,
    }))[0];
};

function getErrorType({
  debitPartner,
  partnersConfiguration,
  debitPartnerBalance,
  debitPartnerPointsRemaining,
  debitAmount,
}: IErrorType): ConversionInputErrorType {
  const debitPartnerRate =
    debitPartner?.id && partnersConfiguration
      ? partnersConfiguration[debitPartner.id].rate
      : null;

  if (
    !debitPartner.points ||
    !debitPartnerRate ||
    debitPartnerBalance === undefined
  ) {
    return undefined;
  }

  // validation;
  if (debitPartnerBalance < debitPartner.points?.min) {
    return "insufficientBalance";
  }

  // if the amount exceeds the interval maximum allowed
  if (
    (debitPartnerPointsRemaining ?? debitPartnerBalance) <
    debitPartner.points?.min
  ) {
    return "reachedMaxAllowance";
  }

  if (debitAmount < debitPartner.points?.min) {
    return "belowMinimum";
  }

  if (
    (debitPartner.points?.max && debitAmount > debitPartner.points.max) ||
    debitAmount > (debitPartnerPointsRemaining ?? debitPartnerBalance)
  ) {
    return "aboveMaximum";
  }

  if (debitAmount % debitPartner.points?.increment !== 0) {
    return "invalidIncrement";
  }

  return undefined;
}

function validateInput({
  debitPartner,
  basePartnerId,
  authenticatedUser,
  partnersConfiguration,
  debitAmount,
}: IInputErrorArgs) {
  const debitPartnerBalance =
    debitPartner?.id === basePartnerId
      ? authenticatedUser?.baseAccount?.balance?.amount
      : authenticatedUser?.partnerAccount?.balance?.amount;

  //TODO: handle BP scenario where there is a point limit
  // information within the balance
  const debitPartnerPointsRemaining =
    debitPartner?.id === basePartnerId
      ? authenticatedUser?.baseAccount?.remainingPoints?.manual.debit
          ?.remainingPoints
      : authenticatedUser?.partnerAccount?.remainingPoints?.manual.debit
          ?.remainingPoints;

  const errorType = getErrorType({
    debitPartner,
    debitPartnerBalance,
    debitPartnerPointsRemaining,
    partnersConfiguration,
    debitAmount,
  });

  const quickSelectPoints = getPoints({
    debitAmount,
    debitPartner,
    errorType,
    debitPartnerBalance,
    debitPartnerPointsRemaining,
  });
  return {
    isValid: !Boolean(errorType),
    errorType,
    quickSelectPoints,
  };
}

function getPoints({
  errorType,
  debitAmount,
  debitPartner,
  debitPartnerBalance,
  debitPartnerPointsRemaining,
}: IPointsArgs): IPoints {
  if (!errorType) {
    return {
      prev: null,
      next: null,
      max: null,
    };
  }
  return {
    prev: ["invalidIncrement"].includes(errorType)
      ? getPrevPoints({
          debitAmount,
          debitPartner,
          debitPartnerBalance,
          debitPartnerPointsRemaining,
        })
      : null,
    next: ["invalidIncrement", "belowMinimum"].includes(errorType)
      ? getNextPoints({
          debitAmount,
          debitPartner,
          debitPartnerBalance,
          debitPartnerPointsRemaining,
        })
      : null,
    max: ["invalidIncrement", "belowMinimum", "aboveMaximum"].includes(
      errorType
    )
      ? getMaxPoints({
          debitAmount,
          debitPartner,
          debitPartnerBalance,
          debitPartnerPointsRemaining,
        })
      : null,
  };
}

const getMaxPoints = ({
  debitPartnerPointsRemaining = 0,
  debitPartnerBalance = 0,
  debitPartner,
}: Partial<IQuickSelectPoints>) => {
  //Get the maximum points that can be used
  //(remaining points if we have a limitation or actual balance)
  const maxPoints =
    debitPartnerPointsRemaining &&
    debitPartnerPointsRemaining < debitPartnerBalance
      ? debitPartnerPointsRemaining
      : debitPartnerBalance;

  //Get the maximum amount from the maximum available that can be used based on the actual increment
  const remainder = debitPartner?.points?.increment
    ? maxPoints && maxPoints % debitPartner.points?.increment
    : 0;

  if (!maxPoints) {
    return null;
  }

  return remainder === 0 ? maxPoints : maxPoints - remainder;
};

const getPrevPoints = ({
  debitAmount,
  debitPartnerPointsRemaining = 0,
  debitPartnerBalance = 0,
  debitPartner,
}: IQuickSelectPoints) => {
  const availablePoints =
    debitPartnerPointsRemaining !== 0
      ? debitPartnerPointsRemaining
      : debitPartnerBalance;
  if (debitAmount > availablePoints) {
    return null;
  }

  if (!debitPartner.points) {
    return null;
  }
  const remainder = debitAmount % debitPartner.points?.increment;
  return remainder === 0
    ? debitAmount - debitPartner.points?.increment
    : debitAmount - remainder;
};

const getNextPoints = ({
  debitAmount,
  debitPartnerPointsRemaining = 0,
  debitPartnerBalance = 0,
  debitPartner,
}: IQuickSelectPoints) => {
  // if the amount exceeds the maximum allowed, return
  const availablePoints =
    debitPartnerPointsRemaining !== 0
      ? debitPartnerPointsRemaining
      : debitPartnerBalance;
  if (debitAmount > availablePoints) {
    return null;
  }

  if (!debitPartner.points) {
    return null;
  }

  if (debitAmount < debitPartner.points?.min) {
    return debitPartner.points?.min;
  }

  const remainder = debitAmount % debitPartner.points?.increment;
  return debitAmount - remainder + debitPartner.points?.increment;
};

export {
  getInputError,
  getErrorType,
  validateInput,
  getPoints,
  getMaxPoints,
  getPrevPoints,
  getNextPoints,
};
