import React from "react";

import Box from "../Box";
import Typography from "../Typography";
import { TTypographyVariants } from "../Typography/Typography.model";

const SuccessInfoBox = ({
  label,
  text,
  variant = "sxm",
  color = "neutralTones.5",
}: {
  label: string;
  text: string;
  variant?: TTypographyVariants;
  color?: string;
}): JSX.Element => (
  <Box display="flex" justifyContent="space-between" marginBottom="xs">
    <Typography variant={variant} color={color}>
      {`${label}:`}
    </Typography>
    <Typography
      variant={variant}
      textAlign="right"
      color={color}
      fontWeight="bold"
    >
      {text}
    </Typography>
  </Box>
);

export default SuccessInfoBox;
