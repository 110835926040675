import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import { space, SpaceProps } from "styled-system";

import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";
import Typography from "../Typography";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { formatNumberAsLocalisedString } from "../../utils/strings";
import { UserInfoProps } from "./UserInfo.model";

import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

const StyledBox = styled(Box)`
  border: 1px solid;
  box-shadow: 0 6px 6px 0 rgb(0 0 0 / 24%);
  z-index: 10;
  ${css({
    borderColor: "neutralTones.2",
  })}
`;

const StyledImg = styled.img<SpaceProps>`
  ${css({
    height: "xxl-3",
  })}
  ${space}
`;

const UserInfo: React.FC<UserInfoProps> = ({
  content,
  handleLogout,
  membershipNumber,
  balance,
  username = "",
}) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useOnClickOutside(boxRef, handleClickOutside);

  const handleToggle = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClickLogout = () => {
    setIsOpen(false);
    handleLogout();
  };

  const processUsername = (
    name: string | null | undefined
  ): { firstNamePart: string; surnamePart: string } => {
    if (!name) {
      return { firstNamePart: "", surnamePart: "" };
    }
    const nameParts = name.trim().split(" ");
    const firstNamePart = nameParts.slice(0, -1).join(" ");
    const surnamePart = nameParts.slice(-1)[0];
    return { firstNamePart, surnamePart };
  };

  const { firstNamePart, surnamePart } = processUsername(username);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    const handleEscape = (event: KeyboardEvent) => {
      const key = event.code;

      if (key === "Escape") {
        handleClose();
      }
    };

    document.addEventListener("keyup", handleEscape);

    return () => {
      document.removeEventListener("keyup", handleEscape);
    };
  }, [isOpen]);

  return (
    <Box position="relative" ref={boxRef}>
      <Button
        id="user-info-button"
        variant="text"
        onClick={handleToggle}
        aria-label={content.mainAriaLabel}
      >
        <Icon
          type="regular"
          name="user"
          color={getConfig(config.colors, "link", "secondary.0")}
          width="l"
          height="l"
        />
        {username && (
          <>
            <Typography
              variant="large"
              color={getConfig(config.colors, "link", "secondary.0")}
              htmlTag="span"
              marginLeft="xxs"
              data-hj-suppress
              data-cy="first-names"
            >
              {firstNamePart}
            </Typography>{" "}
            <Typography
              variant="large"
              color={getConfig(config.colors, "link", "secondary.0")}
              htmlTag="span"
              data-cy="last-name"
            >
              {surnamePart}
            </Typography>
          </>
        )}
      </Button>
      {isOpen && (
        <>
          <StyledBox
            role="dialog"
            aria-modal="true"
            aria-label={content.mainAriaLabel}
            position="absolute"
            top={26}
            right={0}
            width={270}
            display="flex"
            flexDirection="column"
          >
            <Box
              backgroundColor="neutralTones.0"
              padding="m"
              display="flex"
              justifyContent="space-between"
            >
              <Box>
                <Typography
                  variant="medium"
                  color="neutralTones.7"
                  data-cy="membership-number-label"
                >
                  {content.membershipNumberLabel}
                </Typography>
                <Typography
                  id="user-info-membership-number"
                  variant="medium"
                  color="neutralTones.7"
                  marginBottom="xs"
                  data-cy="popup-membership-number"
                >
                  {membershipNumber}
                </Typography>
                <Button
                  id="user-info-logout-button"
                  variant="text"
                  onClick={handleClickLogout}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  aria-label={content.logoutLabel}
                >
                  <Typography
                    variant="medium"
                    color="secondary.0"
                    htmlTag="span"
                  >
                    {content.logoutLabel}
                  </Typography>
                  <Icon
                    type="regular"
                    name="arrow-right-from-bracket"
                    color={getConfig(config.colors, "link", "secondary.0")}
                    width="m"
                    marginLeft="xs"
                  />
                </Button>
              </Box>
              <Button
                id="user-info-close-button"
                variant="text"
                height="xl"
                onClick={handleClose}
                aria-label={content.closeButtonAriaLabel}
              >
                <Icon
                  type="regular"
                  name="xmark"
                  width="l"
                  height="l"
                  color={getConfig<string>(
                    config.colors,
                    "tertiary",
                    "tertiary"
                  )}
                />
              </Button>
            </Box>
            <Box
              backgroundColor="neutralTones.1"
              padding="m"
              display="flex"
              alignItems="center"
            >
              {content.partnerImage && (
                <StyledImg
                  src={content.partnerImage.imgSrc}
                  alt={content.partnerImage.imgAlt}
                  marginRight="xs"
                  data-cy="partner-image"
                />
              )}
              <Box>
                <Typography
                  variant="medium"
                  color="neutralTones.7"
                  htmlTag="div"
                >
                  {content.balanceLabel}
                </Typography>
                <Typography
                  id="user-info-balance"
                  variant="medium"
                  color="neutralTones.7"
                  fontWeight="bold"
                  htmlTag="span"
                  marginBottom="xs"
                  data-cy="balance"
                >
                  {balance !== undefined
                    ? formatNumberAsLocalisedString(balance)
                    : content.unavailableBalanceLabel}
                </Typography>
              </Box>
            </Box>
          </StyledBox>
        </>
      )}
    </Box>
  );
};

export default UserInfo;
