import { useCallback, useEffect } from "react";

export type OpCos = "AVIOS" | "IBERIA";

export const metaContent: { [OpCo in OpCos]: Record<string, string> } = {
  AVIOS: {
    qatar:
      "Transfer Avios from Qatar Privilege Club to British Airways Executive Club.",
    bp: "Make life even more rewarding by converting BPme Rewards points to Avios, to fuel new adventures.",
    nectar:
      "Make life even more rewarding by converting Nectar points to Avios - and vice versa, to start building new stories.",
  },
  IBERIA: {
    qatar: "Transferir Avios del Qatar Privilege Club a Iberia Plus.",
  },
};

export const titleContent: { [OpCo in OpCos]: Record<string, string> } = {
  AVIOS: {
    qatar: "British Airways | Qatar | Avios | Conversion",
    bp: "British Airways | BP | Avios | Conversion",
    nectar: "British Airways | Nectar | Avios | Conversion",
  },
  IBERIA: {
    qatar: "Iberia | Qatar | Avios | Conversión",
  },
};

export const useHeaderManipulation = (opCo: OpCos, partner: string) => {
  const updateTitle = useCallback(() => {
    const title =
      titleContent[opCo][partner] || "British Airways | Avios | Conversion";
    document.title = title;
  }, [opCo, partner]);

  const updateMetaTag = useCallback(() => {
    const metaTag =
      metaContent[opCo][partner] ||
      "Make life even more rewarding by converting points to Avios.";
    const metaTagElement = document.querySelector('meta[name="description"]');
    metaTagElement?.setAttribute("content", metaTag);
  }, [opCo, partner]);

  useEffect(() => {
    updateTitle();
    updateMetaTag();
  }, [updateTitle, updateMetaTag]);
};
