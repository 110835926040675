import app from "../../../package.json";

import { datadogRum } from "@datadog/browser-rum";

const { REACT_APP_ATLAS_URL } = process.env;

datadogRum.init({
  applicationId: `${process.env.REACT_APP_RUM_APPLICATION_ID || ""}`,
  clientToken: `${process.env.REACT_APP_RUM_CLIENT_TOKEN || ""}`,
  site: "datadoghq.eu",
  service: "collection---exchange",
  version: app.version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 50,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
  trackFrustrations: true,
  silentMultipleInit: true,
  allowedTracingUrls: [(url) => url.startsWith(String(REACT_APP_ATLAS_URL))],
  beforeSend: (event) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const redactData = (ddBeforeSendData: any) =>
      ddBeforeSendData.replace(/code=[^&]*/, "code=REDACTED");

    if (event?.view) {
      event.view.url = redactData(event.view.url);
      event.view.referrer = redactData(event.view.referrer);
    }

    //@ts-expect-error event not typed
    if (event?.error?.resource) {
      //@ts-expect-error event not typed
      event.error.resource.url = redactData(event.error.resource.url);
    }

    if (event?.resource) {
      //@ts-expect-error event not typed
      event.resource.url = redactData(event.resource.url);
    }
  },
});

datadogRum.startSessionReplayRecording();

export default datadogRum;
