import React from "react";
import { useHistory } from "react-router-dom";

import Box from "../../components/Box";
import Layout from "../../components/Layout";
import LoadingPage from "../../components/LoadingPage";
import MainContainer from "../../components/MainContainer";
import TopicsContainer from "../../components/TopicsContainer";
import Typography from "../../components/Typography";
import { RoutesEnum } from "../../config/routes.enum";
import { useGetPrivacyAndSecurityPageContent } from "../../services/Contentful";
import { usePartnershipContext } from "../../context/Partnership/PartnershipContext";

const PrivacyAndSecurity: React.FC = () => {
  const history = useHistory();
  const {
    data: { pairId },
  } = usePartnershipContext();
  const { data: pageContent, error } =
    useGetPrivacyAndSecurityPageContent(pairId);

  if (error) {
    history.push(RoutesEnum.OOPS);
  }

  if (!pageContent && !error) {
    return <LoadingPage />;
  }

  return (
    <MainContainer>
      <Layout>
        <TopicsContainer
          backgroundColor="neutralTones.0"
          py="xxl-2"
          px={{ _: "m", desktop: "xxl-4" }}
        >
          {pageContent && (
            <>
              <Typography
                variant="h1"
                lineHeight="m"
                marginBottom="xl"
                color="text"
              >
                {pageContent.title}
              </Typography>
              <Box as="section">{pageContent.policies}</Box>
            </>
          )}
        </TopicsContainer>
      </Layout>
    </MainContainer>
  );
};

export default PrivacyAndSecurity;
