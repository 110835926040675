import {
  LinkAccountsSectionDTO,
  ParsedLinkAccountsSection,
} from "./linkAccountsSectionParser.model";

const linkAccountsSectionParser = (
  dto: LinkAccountsSectionDTO["data"]
): ParsedLinkAccountsSection["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const {
    basePartner,
    externalPartner,
    linkedButtonText,
    unlinkedButtonText,
    linkedHeader,
    unlinkedHeader,
    unavailableBalanceLabel,
    unavailableMembershipNumberLabel,
    unavailableCurrencyLabel,
  } = dto.items[0].fields;

  return {
    linkedHeader,
    unlinkedHeader,
    linkedButtonText,
    unlinkedButtonText,
    unavailableBalanceLabel,
    unavailableMembershipNumberLabel,
    unavailableCurrencyLabel,
    basePartnerInfo: {
      membershipLabel: basePartner.fields.membershipLabel,
      membershipNumberPrefix: basePartner.fields.membershipNumberPrefix || "",
    },
    externalPartnerInfo: {
      membershipLabel: externalPartner.fields.membershipLabel,
      membershipNumberPrefix:
        externalPartner.fields.membershipNumberPrefix || "",
    },
  };
};

export default linkAccountsSectionParser;
