import React from "react";

import AoiHeading from "../AoiHeading";
import AoiStepIndicator from "../AoiStepIndicator";
import Box from "../Box";
import Step from "../Step";
import { ConversionAutomaticSectionV2Props } from "./ConversionAutoV2.model";

const ConversionAutoV2: React.FC<ConversionAutomaticSectionV2Props> = ({
  exchangeContent = undefined,
  handleAoiSetup,
}) => {
  if (!exchangeContent) {
    return <></>;
  }

  return (
    <>
      <Box px="m" py="s" display="flex" flexDirection="column" minHeight={293}>
        <AoiHeading
          handleAoiSetup={handleAoiSetup}
          exchangeContent={exchangeContent}
        />
        <AoiStepIndicator />
        <Step exchangeContent={exchangeContent} />
      </Box>
    </>
  );
};
export default ConversionAutoV2;
