/* eslint-disable react/jsx-key */
import React from "react";

import FeatureToggleProvider from "./FeatureToggle/FeatureToggleProvider";
import NotificationsProvider from "./Notifications/NotificationsProvider";
import PartnershipContextProvider from "./Partnership/PartnershipContext";
import { AuthenticationContextProvider } from "./Authentication/AuthenticationContext";

type ContextProvidersProps = {
  children: React.ReactNode;
};

const ContextProviders: React.FC<ContextProvidersProps> = ({ children }) => {
  const providers = [
    <PartnershipContextProvider />,
    <FeatureToggleProvider />,
    <NotificationsProvider />,
    <AuthenticationContextProvider />,
  ];

  return (
    <>
      {providers.reduceRight(
        (acc: React.ReactNode, Provider: React.ReactElement) => {
          return React.cloneElement(Provider, {}, acc);
        },
        children
      )}
    </>
  );
};

export default ContextProviders;
