import { RefObject, useEffect } from "react";

type AnyEvent = MouseEvent | TouchEvent;

function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  callback: (event: AnyEvent) => void
): void {
  useEffect(() => {
    const checkIfClickedOutside = (event: AnyEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      callback(event);
    };

    window.addEventListener("click", checkIfClickedOutside);
    window.addEventListener("touchstart", checkIfClickedOutside);
    return () => {
      window.removeEventListener("click", checkIfClickedOutside);
      window.removeEventListener("touchstart", checkIfClickedOutside);
    };
  }, [ref, callback]);
}

export default useOnClickOutside;
