import { createContext, useContext } from "react";

import { IAutoConversionContext } from "./AutoConversionContext.model";

const AutoConversionContext = createContext<IAutoConversionContext>(
  {} as IAutoConversionContext
);

const useAutoConversion = (): IAutoConversionContext => {
  const context = useContext(AutoConversionContext);
  if (!Object.values(context).length) {
    throw new Error(
      "useAutoConversion must be used within a AutoConversionContextProvider"
    );
  }
  return context;
};

export { AutoConversionContext, useAutoConversion };

AutoConversionContext.displayName = "AutoConversionContext";
