const removeSearchParams = (searchParams: string[]): void => {
  const params = new URLSearchParams(window.location.search);

  searchParams.forEach((param) => {
    if (params.has(param)) {
      params.delete(param);
    }
  });

  window.history.replaceState(
    {},
    "",
    `${location.pathname}${Array.from(params).length ? `?${params}` : ""}`
  );
};

export default removeSearchParams;
