import React from "react";
import { ThemeProvider } from "styled-components";

import { ThemeProps } from "./theme";

interface ThemeProviderProps {
  theme: ThemeProps;
  children: React.ReactNode;
}

const ExchangeThemeProvider: React.FC<ThemeProviderProps> = ({
  theme,
  children,
}: ThemeProviderProps) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default ExchangeThemeProvider;
