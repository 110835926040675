import React from "react";
import styled from "styled-components";
import { layout, LayoutProps, space, SpaceProps } from "styled-system";

import Box from "../Box";
import Typography from "../Typography";
import { ThreeColumnsSectionProps } from "./ThreeColumnsSection.model";

const StyledImage = styled.img<SpaceProps | LayoutProps>`
  ${space}
  ${layout}
  height: auto;
`;

const ThreeColumnsSection: React.FC<ThreeColumnsSectionProps> = ({
  header,
  sections,
}) => {
  return (
    <>
      {header && (
        <Typography variant="title" color="text" mb="xxl-2">
          {header}
        </Typography>
      )}
      <Box
        display="grid"
        gridTemplateColumns={{
          _: "1fr",
          tablet: "repeat(auto-fit, minmax(160px, 1fr))",
        }}
        gridGap={40}
        paddingTop="m"
      >
        {sections.map((section) => (
          <Box
            key={section.title}
            mb="xl"
            display="flex"
            flexDirection="column"
            alignItems="baseline"
          >
            <Box height={80} display="flex" alignItems="flex-end" mb="l">
              <StyledImage src={section.imageUrl} alt={section.imageAlt} />
            </Box>

            <Typography variant="h6" color="text" mb="s">
              {section.title}
            </Typography>
            <Typography variant="medium" color="neutralTones.7">
              {section.description}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default ThreeColumnsSection;
