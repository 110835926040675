import React from "react";
import css from "@styled-system/css";
import styled from "styled-components";

import Box from "../Box";
import StepHandler from "./StepHandler";
import Typography from "../Typography";
import { useAutoConversion } from "../../context/AutoConversion/AutoConversionContext";

import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

const StepContainer = styled(Box)<{ isCompleted: boolean }>`
  ${(props) =>
    css({
      "&:first-child button": {
        marginLeft: "50%",
        transform: "translateX(-12px)",
      },
      "&:last-child button": {
        marginLeft: "-50%",
        transform: "translateX(12px)",
      },
      "&:not(:first-child) button:before, &:not(:last-child) button:after": {
        content: "' '",
        backgroundColor: props.isCompleted ? "secondary.0" : "neutralTones.4",
        height: "2px",
        display: "block",
        flex: 1,
      },
    })}
`;

const AoiStepIndicator: React.FC = () => {
  const { steps, activeStep, handleActiveStep } = useAutoConversion();

  const getStepColor = (stepNumber: number) => {
    if (stepNumber === activeStep) return "secondary.0";
    if (stepNumber < activeStep)
      return getConfig<string>(config.colors, "tertiary", "tertiary");
    return "neutralTones.7";
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {steps.map((step) => (
        <StepContainer
          key={step.stepNumber}
          isCompleted={step.isCompleted}
          flex="1"
        >
          <Typography
            variant="small"
            textAlign="center"
            fontWeight="regular"
            display="flex"
            lineHeight="xxxs"
            verticalAlign="center"
            color={getStepColor(step.stepNumber)}
            maxWidth={step.stepNumber !== 2 ? "65px" : "50px"}
            margin="0 auto 5px"
          >
            {step.label}
          </Typography>
          <StepHandler
            display="flex"
            alignItems="center"
            border={0}
            width="100%"
            isActive={step.stepNumber === activeStep}
            disabled={step.stepNumber >= activeStep}
            isCompleted={step.isCompleted}
            onClick={() => handleActiveStep(step.stepNumber)}
          />
        </StepContainer>
      ))}
    </Box>
  );
};

export default AoiStepIndicator;
