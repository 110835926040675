import React from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import { Link as RRLink } from "react-router-dom";
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from "styled-system";
import { AnchorVariant, LinkProps } from "./Link.model";
import variants from "./variants";

import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

type TStylingProps = SpaceProps &
  FlexboxProps &
  LayoutProps & {
    color: string;
    variant?: AnchorVariant;
    isFixed?: boolean;
    isActive?: boolean;
    shouldBeHighlighted?: boolean;
  };
const StyledAnchor = styled.a<TStylingProps>`
  font-family: ${getConfig(config.fonts, "link", "MyliusModern")}, sans-serif;
  text-decoration: none;

  ${(props) =>
    css({
      fontSize: getConfig(config.fontSizes, "link", "16px"),
      color: props.color,
    })}
  ${variant({ variants })}
  ${(props) =>
    props.isFixed &&
    css({
      position: "fixed",
      left: 0,
      paddingY: "14px",
      backgroundColor: "neutralTones.0",
      boxShadow: "3px 0 4px -2px rgb(32 33 36 / 40%)",
    })}
    ${(props) =>
    props.isActive &&
    css({
      color: getConfig<string>(config.colors, "tertiary", "tertiary"),
      backgroundColor: "neutralTones.1",
    })}
  ${(props) =>
    props.shouldBeHighlighted &&
    css({
      color: "primary.1",
      "&:hover": {
        color: "primary.1",
      },
    })}    
  ${space}
  ${flexbox}
`;

const StyledLink = styled(RRLink).withConfig({
  shouldForwardProp: (prop) =>
    ![
      "isFixed",
      "isActive",
      "shouldBeHighlighted",
      "paddingX",
      "paddingY",
    ].includes(prop),
})<TStylingProps>`
  font-family: ${getConfig(config.fonts, "link", "MyliusModern")}, sans-serif;
  text-decoration: none;

  ${(props) =>
    css({
      fontSize: getConfig(config.fontSizes, "link", "16px"),
      color: props.color,
    })}
  ${variant({ variants })}
    ${(props) =>
    props.isFixed &&
    css({
      position: "fixed",
      left: 0,
      paddingY: "14px",
      backgroundColor: "neutralTones.0",
      boxShadow: "3px 0 4px -2px rgb(32 33 36 / 40%)",
    })}
      ${(props) =>
    props.isActive &&
    css({
      color: getConfig<string>(config.colors, "tertiary", "tertiary"),
      backgroundColor: "neutralTones.1",
    })}
    ${(props) =>
    props.shouldBeHighlighted &&
    css({
      color: "primary.1",
      "&:hover": {
        color: "primary.1",
      },
    })}
  ${space}
  ${flexbox}
  ${layout}
`;

const Link: React.FC<LinkProps> = ({
  children,
  to,
  color = "secondary.0",
  openInNewTab = false,
  useAnchor = false,
  onClick = undefined,
  ...props
}) => {
  const isPathAbsolute = to.indexOf("http") > -1;
  const anchorProps = {
    target: openInNewTab ? "_blank" : "_self",
    rel: openInNewTab ? "noopener noreferrer" : undefined,
  };

  if (isPathAbsolute || useAnchor) {
    return (
      <StyledAnchor
        onClick={onClick}
        href={to}
        color={color}
        {...anchorProps}
        {...props}
      >
        {children}
      </StyledAnchor>
    );
  }

  return (
    <StyledLink
      onClick={onClick}
      to={to}
      color={color}
      {...anchorProps}
      {...props}
    >
      {children}
    </StyledLink>
  );
};

export default Link;
