import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import bannersIds from "../../dictionaries/bannersIds";
import datadogRum from "../../utils/ddRum";
import { useNotifications } from "../Notifications/NotificationsContext";
import { trackers } from "../../utils/ga";
import { usePartnershipContext } from "../Partnership/PartnershipContext";
import { getOrderById, placeOrder } from "../../services/Exchange";
import {
  AuthenticationContext,
  useAuthentication,
} from "../Authentication/AuthenticationContext";
import {
  GetOrderByIdDTO,
  PlaceOrderData,
} from "../../services/Exchange/Exchange.model";
import {
  systemErrorStatusIds,
  transactionErrorStatusIds,
  transactionStatusIds,
  transactionErrorsRulesIds,
} from "../../dictionaries/transactionStatusIds";
import { TransactionContextProviderProps } from "./TransactionContextProvider.model";
import modalsIds from "../../dictionaries/modalsIds";

type TTransaction = GetOrderByIdDTO["data"];

export type TTransactionContext = {
  transaction: TTransaction;
  transactionIsPending: boolean;
  handleTransaction: (transaction: TTransaction) => void;
  setTransactionIsPending: (status: boolean) => void;
  handlePlaceOrder: (payload: PlaceOrderData) => Promise<void>;
  handleGetTransactionInfo: (orderId: string) => Promise<void>;
  clearTransactionInfo: () => void;
};

const TransactionContext = createContext({} as TTransactionContext);

const TransactionContextProvider: React.FC<TransactionContextProviderProps> = ({
  children,
}) => {
  const [transactionIsPending, setTransactionIsPending] = useState(false);
  const [transaction, setTransaction] = useState<TTransaction>(undefined);
  const { updateRemainingPoints } = useContext(AuthenticationContext);

  const {
    getUpdatedBalance,
    expireUserSession,
    user: authenticatedUser,
    isPartnerPageVariant,
  } = useAuthentication();
  const {
    data: { pairId, basePartnerId, externalPartnerId },
    getPairConfigData,
  } = usePartnershipContext();
  const { getModalTemplate, addBanner, addModal } = useNotifications();

  const handleTransaction = (transactionInfo: TTransaction) => {
    setTransaction(transactionInfo);
  };

  const handleGetTransactionInfo = useCallback(
    async (orderId: string) => {
      const { data: orderDetails, error: orderDetailsError } =
        await getOrderById(orderId, expireUserSession, "manual");

      if (orderDetailsError) {
        //In this case, the order could be placed but is not possible to confirm if it fails or not
        handleTransaction({
          status: orderDetailsError.code,
        } as TTransaction);
      }

      if (orderDetails && "status" in orderDetails) {
        const trackingParams = {
          baseAccountId: authenticatedUser?.membershipNumber,
          partnerAccountId: authenticatedUser?.partnerAccount?.partialId || "",
          directionConversion: `${orderDetails.source.partnerId}-${orderDetails.destination.partnerId}`,
          manualOrAOI: orderDetails.mode,
          pointOrigin: basePartnerId,
          debitedAmount: orderDetails.debit.amount,
          creditedAmount: orderDetails.credit.amount,
        };

        trackers.trackExchangeOperation("convert", {
          memberID: authenticatedUser?.membershipNumber,
          convert_from: orderDetails.source.partnerId,
          convert_from_points: orderDetails.debit.amount,
          convert_to: orderDetails.destination.partnerId,
          convert_to_points: orderDetails.credit.amount,
          point_of_origin: basePartnerId,
          location: "manual conversion component",
        });

        datadogRum.addAction("points converted", trackingParams);

        handleTransaction(orderDetails);
      }
    },
    [expireUserSession, authenticatedUser, basePartnerId]
  );

  const handlePlaceOrder = useCallback(
    async (payload: PlaceOrderData) => {
      if (!externalPartnerId) {
        setTransactionIsPending(false);
        return;
      }

      setTransactionIsPending(true);

      const redirectionPairId =
        isPartnerPageVariant && basePartnerId
          ? basePartnerId
          : externalPartnerId;

      const { data: placedOrderData, error: placedOrderError } =
        await placeOrder(payload, pairId, redirectionPairId, expireUserSession);

      if (placedOrderError) {
        handleTransaction({
          status: placedOrderError.code,
        } as TTransaction);
        setTransactionIsPending(false);
        return;
      }

      //If the place order request timed out, set it as pending
      if (!placedOrderData) {
        handleTransaction({
          status: "pending",
        } as TTransaction);
      }

      if (placedOrderData && "url" in placedOrderData) {
        window.location.assign(placedOrderData.url);
        return;
      }

      if (placedOrderData && "order" in placedOrderData) {
        const { order } = placedOrderData;

        if (order && "status" in order) {
          await handleGetTransactionInfo(order.transactionId);
          await updateRemainingPoints(pairId);
        }
      }

      await getPairConfigData(pairId);
      await getUpdatedBalance();
      setTransactionIsPending(false);
    },
    [
      externalPartnerId,
      isPartnerPageVariant,
      basePartnerId,
      pairId,
      expireUserSession,
      getPairConfigData,
      getUpdatedBalance,
      handleGetTransactionInfo,
      updateRemainingPoints,
    ]
  );

  const handleFinishedTransaction = useCallback(() => {
    let banner = bannersIds.EXCHANGE_PENDING;

    if (transaction?.status === transactionStatusIds.COMPLETED) {
      banner = bannersIds.EXCHANGE_SUCCESS;
    }

    if (transactionErrorStatusIds.includes(transaction?.status || "")) {
      if (
        transaction?.status === transactionStatusIds.INVALID &&
        transaction?.errors.length
      ) {
        const transactionError = transaction?.errors[0];
        const mappedModalError =
          transactionErrorsRulesIds[transactionError.code];

        if (mappedModalError) {
          const modal = getModalTemplate(modalsIds[mappedModalError]);
          if (!modal) {
            return;
          }

          const redirectToUrl = () => {
            modal?.redirectionWebsite &&
              window.location.assign(modal.redirectionWebsite);
          };

          return addModal({
            ...modal,
            handleClickCta: redirectToUrl,
            hasCloseButton: true,
          });
        }
      }
      banner = bannersIds.EXCHANGE_FAILED;
    }

    if (systemErrorStatusIds.includes(transaction?.status || "")) {
      banner = bannersIds.INTERNAL_ERROR;
    }

    addBanner({ bannerId: banner });
  }, [transaction, addBanner, addModal, getModalTemplate]);

  const clearTransactionInfo = () => {
    setTransaction(undefined);
    setTransactionIsPending(false);
  };

  useEffect(() => {
    if (transaction) {
      handleFinishedTransaction();
    }
  }, [handleFinishedTransaction, transaction]);

  return (
    <TransactionContext.Provider
      value={{
        transaction,
        transactionIsPending,
        handleTransaction,
        setTransactionIsPending,
        handlePlaceOrder,
        handleGetTransactionInfo,
        clearTransactionInfo,
      }}
    >
      {children}
    </TransactionContext.Provider>
  );
};

const useTransaction = (): TTransactionContext => {
  const context = useContext(TransactionContext);

  if (!Object.keys(context).length) {
    throw new Error(
      "useTransaction must be used within a TransactionContextProvider"
    );
  }

  return context;
};

export { TransactionContext, TransactionContextProvider, useTransaction };

TransactionContext.displayName = "TransactionContext";
