import React from "react";
import styled from "styled-components";
import { css } from "@styled-system/css";

import { ConversionQuickSelectProps } from "./ConversionQuickSelect.model";

import Box from "../Box";
import Button from "../Button";
import Typography from "../Typography";

const QuickSelectButton = styled(Button)`
  ${css({
    height: 32,
    minWidth: 85,
    marginTop: "s",
    marginRight: "s",
    paddingX: "m",
    paddingY: 0,
  })}
`;

const ConversionQuickSelect: React.FC<ConversionQuickSelectProps> = ({
  content,
  quickSelectOptions,
  handleClickQuickSelect,
}) => {
  const shouldShowPrev =
    Boolean(quickSelectOptions.prev) &&
    quickSelectOptions.prev !== quickSelectOptions.max;

  const shouldShowNext =
    quickSelectOptions.next &&
    quickSelectOptions.next !== quickSelectOptions.max;

  return (
    <Box
      marginLeft="l"
      paddingTop="s"
      paddingLeft="m"
      borderLeft="2px solid"
      borderColor="neutralTones.3"
    >
      <Typography variant="small">{content.quickSelectLabel}</Typography>
      {shouldShowPrev && (
        <QuickSelectButton
          variant="outlined"
          onClick={handleClickQuickSelect(quickSelectOptions.prev)}
        >
          {quickSelectOptions.prev}
        </QuickSelectButton>
      )}
      {shouldShowNext && (
        <QuickSelectButton
          variant="outlined"
          onClick={handleClickQuickSelect(quickSelectOptions.next)}
        >
          {quickSelectOptions.next}
        </QuickSelectButton>
      )}
      {quickSelectOptions.max && (
        <QuickSelectButton
          variant="outlined"
          onClick={handleClickQuickSelect(quickSelectOptions.max)}
        >
          {content.quickSelectTotalValid}
        </QuickSelectButton>
      )}
    </Box>
  );
};

export default ConversionQuickSelect;
