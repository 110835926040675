import { useEffect, useState } from "react";

const useActivation = (startDate?: Date): boolean => {
  const [isActive, setIsActive] = useState(
    new Date().getTime() > new Date(startDate || new Date()).getTime()
  );

  useEffect(() => {
    if (!startDate || isActive) {
      return;
    }
    const timeoutId = setTimeout(() => {
      setIsActive(true);
    }, Math.abs(new Date().getTime() - new Date(startDate).getTime()));
    return () => clearTimeout(timeoutId);
  }, [startDate, isActive]);
  return isActive;
};

export default useActivation;
