export default async function fetcher<JSON = unknown>(
  url: RequestInfo,
  options?: RequestInit
): Promise<JSON> {
  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return await response.json();
  } catch (error) {
    throw error;
  }
}
