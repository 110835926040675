import React from "react";

import Box from "../Box";
import Icon from "../Icon";
import { TIconKey, TStepIconProps } from "./StepIcon.model";

const StepIcon = ({ name }: TStepIconProps): JSX.Element => {
  const stepsMapping: Record<TIconKey, JSX.Element> = {
    stepActive: (
      <Box
        borderRadius="50%"
        borderWidth="1px"
        borderColor="secondary.0"
        borderStyle="solid"
        display="flex"
        zIndex="2"
      >
        <Icon
          type="duotone"
          name="circle"
          width={22}
          height={22}
          color="neutralTones.0"
        />
      </Box>
    ),
    stepCompleted: (
      <Icon
        type="solid"
        name="circle-check"
        width="xl"
        height="xl"
        color="secondary.0"
      />
    ),
    stepBasic: (
      <Icon
        type="solid"
        name="circle"
        width="xl"
        height="xl"
        color="neutralTones.4"
      />
    ),
  };
  return stepsMapping[name];
};
export default StepIcon;
