import { PageDTO, ParsedPage } from "./pageParser.model";

const pageParser = (dto: PageDTO["data"]): ParsedPage["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const { title } = dto.items[0].fields;

  return {
    pageTitle: title,
  };
};

export default pageParser;
