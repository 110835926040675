import React, { lazy, Suspense } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";

import datadogRum from "../utils/ddRum";
import PrivateRoute from "./PrivateRoute";
import PublicOnlyRoute from "./PublicOnlyRoute";
import LoadingPage from "../components/LoadingPage";
import DefaultLayout from "../pages/_layouts/Default";
import { RoutesEnum } from "../config/routes.enum";
import { useAuthentication } from "../context/Authentication/AuthenticationContext";
import { pairIds } from "../context/Partnership/PairIds";
import { Oops } from "../pages";

const Contact = lazy(
  () => import(/* webpackChunkName: "contact-us" */ "../pages/Contact/Contact")
);
const Cookies = lazy(
  () => import(/* webpackChunkName: "cookies" */ "../pages/Cookies/Cookies")
);
const Exchange = lazy(
  () => import(/* webpackChunkName: "exchange" */ "../pages/Exchange/Exchange")
);
const Faq = lazy(
  () => import(/* webpackChunkName: "faq" */ "../pages/Faq/Faq")
);
const Landing = lazy(
  () => import(/* webpackChunkName: "landing" */ "../pages/Landing")
);
const PrivacyAndSecurity = lazy(
  () =>
    import(
      /* webpackChunkName: "privacy-and-security" */ "../pages/PrivacyAndSecurity/PrivacyAndSecurity"
    )
);
const TermsAndConditions = lazy(
  () =>
    import(
      /* webpackChunkName: "terms-and-conditions" */ "../pages/TermsAndConditions/TermsAndConditions"
    )
);

const Routes = (): JSX.Element => {
  const history = useHistory();

  const { id: partnerName } = useParams<{ id: string }>();

  //Prevent access to invalid routes if partner name is not assigned as partner
  if (!Object.keys(pairIds).includes(partnerName))
    history.push(RoutesEnum.OOPS);

  const { user: authenticatedUser } = useAuthentication();

  datadogRum.setGlobalContextProperty(
    "isLoggedIn",
    Boolean(authenticatedUser?.membershipNumber)
  );

  datadogRum.setGlobalContextProperty("program", {
    id: "ba",
    countryCode: "GB",
  });

  if (authenticatedUser?.membershipNumber) {
    datadogRum.setUser({
      id: authenticatedUser.membershipNumber,
      balance: authenticatedUser.baseAccount?.balance?.amount,
    });
  }

  return (
    <DefaultLayout>
      <Suspense fallback={<LoadingPage />}>
        <Switch>
          <PublicOnlyRoute exact path={RoutesEnum.LANDING}>
            <Landing />
          </PublicOnlyRoute>
          <PrivateRoute exact path={RoutesEnum.EXCHANGE}>
            <Exchange />
          </PrivateRoute>
          <Route path={RoutesEnum.FAQ}>
            <Faq />
          </Route>
          <Route path={RoutesEnum.COOKIES}>
            <Cookies />
          </Route>
          <Route path={RoutesEnum.TERMS_AND_CONDITIONS}>
            <TermsAndConditions />
          </Route>
          <Route path={RoutesEnum.PRIVACY_AND_SECURITY}>
            <PrivacyAndSecurity />
          </Route>
          <Route path={RoutesEnum.CONTACT}>
            <Contact />
          </Route>
          <Route path="*">
            <Oops />
          </Route>
        </Switch>
      </Suspense>
    </DefaultLayout>
  );
};

export default Routes;
