import { useCallback, useEffect, useState } from "react";

import modalsIds from "../../dictionaries/modalsIds";
import { useAuthentication } from "../../context/Authentication/AuthenticationContext";
import { useNotifications } from "../../context/Notifications/NotificationsContext";
import { usePartnershipContext } from "../../context/Partnership/PartnershipContext";
import { ModalProps } from "../../components/Modal/Modal.model";

interface IAccountPermissionsProps {
  isSwitchEnabledGlobal: boolean;
  setOwnerId: React.Dispatch<React.SetStateAction<string | undefined>>;
}

interface IAccountPermissions {
  isSwitchDisabledLocal: boolean;
  isInputDisabled: boolean;
  disableSubmitButton: boolean;
}

const useAccountPermissions = ({
  isSwitchEnabledGlobal,
  setOwnerId,
}: IAccountPermissionsProps): IAccountPermissions => {
  const [isSwitchDisabledLocal, setIsSwitchDisabledLocal] = useState<boolean>(
    !isSwitchEnabledGlobal
  );
  const [isInputDisabled, setIsInputDisabled] = useState<boolean>(false);
  const [disableSubmitButton, setDisableSubmitButton] =
    useState<boolean>(false);
  const { user } = useAuthentication();
  const {
    data: { basePartnerId, externalPartnerId, partnersConfiguration },
  } = usePartnershipContext();
  const { addModal, getModalTemplate } = useNotifications();

  const basePairConfigPermission =
    partnersConfiguration && basePartnerId
      ? partnersConfiguration[basePartnerId].operations.DEBIT
      : null;

  const externalPairConfigPermission =
    partnersConfiguration && externalPartnerId
      ? partnersConfiguration[externalPartnerId].operations.DEBIT
      : null;

  const baseAccountPermissions = user?.baseAccount?.permissions || null;
  const externalAccountPermissions = user?.partnerAccount?.permissions || null;

  const allowBaseToExternal = useCallback(() => {
    setOwnerId(externalPartnerId);
  }, [externalPartnerId, setOwnerId]);

  const allowExternalToBase = useCallback(() => {
    setOwnerId(basePartnerId);
  }, [basePartnerId, setOwnerId]);

  const displayErrorModal = useCallback(
    (modalTemplate?: ModalProps) => {
      if (modalTemplate) {
        addModal({ ...modalTemplate, hasCloseButton: true });
      }
    },
    [addModal]
  );

  useEffect(() => {
    const handleBaseToExternal = () => {
      const modal = getModalTemplate(modalsIds.BASE_TO_EXTERNAL_ALLOWED);
      displayErrorModal(modal);
      allowBaseToExternal();
      !isSwitchDisabledLocal && setIsSwitchDisabledLocal(true);
      isInputDisabled && setIsInputDisabled(false);
      disableSubmitButton && setDisableSubmitButton(false);
    };

    const handleExternalToBase = () => {
      const modal = getModalTemplate(modalsIds.EXTERNAL_TO_BASE_ALLOWED);
      displayErrorModal(modal);
      allowExternalToBase();
      !isSwitchDisabledLocal && setIsSwitchDisabledLocal(true);
      isInputDisabled && setIsInputDisabled(false);
      disableSubmitButton && setDisableSubmitButton(false);
    };

    if (
      !partnersConfiguration ||
      baseAccountPermissions === null ||
      externalAccountPermissions === null ||
      user?.baseAccount?.permissions?.qualifiedToExchange === false ||
      user?.partnerAccount?.permissions?.qualifiedToExchange === false
    ) {
      setIsSwitchDisabledLocal(true);
      setIsInputDisabled(true);
      setDisableSubmitButton(true);
      return;
    }

    if (!basePairConfigPermission && !externalPairConfigPermission) {
      const modal = getModalTemplate(modalsIds.BOTH_PAIR_FALSE);
      displayErrorModal(modal);
      setIsSwitchDisabledLocal(true);
      setIsInputDisabled(true);
      setDisableSubmitButton(true);
      return;
    }

    if (!basePairConfigPermission) {
      if (!baseAccountPermissions.credit || !externalAccountPermissions.debit) {
        const modal = getModalTemplate(modalsIds.BASE_DEBIT_FALSE);
        displayErrorModal(modal);
        setIsSwitchDisabledLocal(true);
        setIsInputDisabled(true);
        setDisableSubmitButton(true);
        return;
      }

      handleExternalToBase();
      return;
    }

    if (!externalPairConfigPermission) {
      if (!baseAccountPermissions.debit || !externalAccountPermissions.credit) {
        const modal = getModalTemplate(modalsIds.EXTERNAL_DEBIT_FALSE);
        displayErrorModal(modal);
        setIsSwitchDisabledLocal(true);
        setIsInputDisabled(true);
        setDisableSubmitButton(true);
        return;
      }

      handleBaseToExternal();
      return;
    }

    if (
      Object.values(baseAccountPermissions).every((permission) => permission) &&
      Object.values(externalAccountPermissions).every(
        (permission) => permission
      )
    ) {
      isSwitchDisabledLocal && setIsSwitchDisabledLocal(false);
      isInputDisabled && setIsInputDisabled(false);
      disableSubmitButton && setDisableSubmitButton(false);
      return;
    }

    if (baseAccountPermissions.debit && externalAccountPermissions.credit) {
      handleBaseToExternal();
      return;
    }

    if (externalAccountPermissions.debit && baseAccountPermissions.credit) {
      handleExternalToBase();
      return;
    }

    const modal = getModalTemplate(modalsIds.BOTH_PAIR_TRUE);
    displayErrorModal(modal);
    setIsSwitchDisabledLocal(true);
    setIsInputDisabled(true);
    setDisableSubmitButton(true);
  }, [
    allowBaseToExternal,
    allowExternalToBase,
    basePairConfigPermission,
    baseAccountPermissions,
    disableSubmitButton,
    displayErrorModal,
    externalPairConfigPermission,
    externalAccountPermissions,
    getModalTemplate,
    isInputDisabled,
    isSwitchDisabledLocal,
    partnersConfiguration,
    user?.baseAccount?.permissions?.qualifiedToExchange,
    user?.partnerAccount?.permissions?.qualifiedToExchange,
  ]);

  return {
    isSwitchDisabledLocal,
    isInputDisabled,
    disableSubmitButton,
  };
};

export default useAccountPermissions;
