import React, { useEffect } from "react";
import styled from "styled-components";
import { variant } from "styled-system";

import Box from "../Box";
import Icon from "../Icon";
import Layout from "../Layout";
import Typography from "../Typography";
import variants from "./variants";
import { BannerProps, BannerVariant } from "./Banner.model";
import { useNotifications } from "../../context/Notifications/NotificationsContext";

const BannerContainer = styled(Box)<{ variant: BannerVariant }>`
  ${variant({ variants })}
`;

const BottomLine = styled(Box)`
  opacity: 0.3;
`;

const Banner: React.FC<BannerProps> = ({
  id,
  variant,
  header,
  message,
  iconName,
  timeToClose = 7000,
  handleAdditionalOnClose,
}) => {
  const { removeBanner } = useNotifications();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeBanner(id);
      handleAdditionalOnClose?.();
    }, timeToClose);

    return () => {
      clearTimeout(timer);
    };
  }, [id, removeBanner, timeToClose, handleAdditionalOnClose]);

  return (
    <BannerContainer pt="m" variant={variant} role="alert">
      <Layout display="flex" alignItems="center" px="l">
        {iconName && (
          <Icon
            type="regular"
            name={iconName}
            width="xxl"
            height="xxl"
            mr="xxl"
            color={`${variant}.1`}
          />
        )}
        {(header || message) && (
          <Box display="flex" flexDirection="column">
            {header && (
              <Typography variant="large" htmlTag="h4" color={`${variant}.1`}>
                {header}
              </Typography>
            )}
            {message && (
              <Typography variant="large" color={`${variant}.1`}>
                {message}
              </Typography>
            )}
          </Box>
        )}
      </Layout>
      <BottomLine mt="m" backgroundColor="neutralTones.0" height="xxs" />
    </BannerContainer>
  );
};

export default Banner;
