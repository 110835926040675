import richTextToComponents from "../../../../utils/richTextToComponents";

import {
  LoginSectionDTO,
  ParsedLoginSection,
} from "./loginSectionParser.model";

const loginSectionParser = (
  dto: LoginSectionDTO["data"]
): ParsedLoginSection["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const { description, buttonText, accordion } = dto.items[0].fields;

  return {
    description,
    buttonText,
    accordionSections: accordion.fields.sections.map((section) => ({
      header: section.fields.header,
      body: richTextToComponents(section.fields.body),
    })),
  };
};

export default loginSectionParser;
