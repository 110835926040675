type TModalsIds = {
  [key: string]: string;
};
const modalsIds: TModalsIds = {
  SESSIONEXPIRED: "SESSION_EXPIRED_MODAL",
  UNLINKING: "UNLINKING_MODAL",
  MATCHING_ERRORS: "MATCHING_ERRORS_MODAL",
  BOTH_PAIR_FALSE: "BOTH_PAIR_FALSE",
  BASE_DEBIT_FALSE: "BASE_DEBIT_FALSE",
  EXTERNAL_DEBIT_FALSE: "EXTERNAL_DEBIT_FALSE",
  EXTERNAL_TO_BASE_ALLOWED: "EXTERNAL_TO_BASE_ALLOWED",
  BASE_TO_EXTERNAL_ALLOWED: "BASE_TO_EXTERNAL_ALLOWED",
  BOTH_PAIR_TRUE: "BOTH_PAIR_TRUE",
  UNSUBSCRIBE_AOI: "UNSUBSCRIBE_MODAL",
  SWITCH_DIRECTION: "SWITCH_DIRECTION",
  ACCOUNT_TEMPORARY_BLOCKED: "ACCOUNT_TEMPORARY_BLOCKED",
  AVIOS_QUALIFIED: "AVIOS_QUALIFIED",
  CANCEL_AOI_SETUP: "CANCEL_AOI_SETUP",
  GENERIC_ERROR: "GENERIC_ERROR_MODAL",
  "data validation error": "MATCHING_ERRORS_MODAL",
  "link error": "GENERIC_ERROR_MODAL",
};

export default modalsIds;
