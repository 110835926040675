import React from "react";

import Banner from "../Banner";
import Box from "../Box";
import Modal from "../Modal";
import { useNotifications } from "../../context/Notifications/NotificationsContext";
import Portal from "../Portal";

interface NotificationCenterProps {
  isLegacyHeader?: boolean;
}

const NotificationCenter: React.FC<NotificationCenterProps> = ({
  isLegacyHeader = false,
}) => {
  const { banners, modal } = useNotifications();

  if (!banners && !modal) {
    return <></>;
  }
  return (
    <>
      {banners && banners.length > 0 && (
        <Box
          data-testid="notifications-center"
          position={isLegacyHeader ? "fixed" : "unset"}
          top={{ _: 119, tablet: 135 }}
          width="100vw"
        >
          {banners.map((notification) => (
            <Banner key={notification.id} {...notification} />
          ))}
        </Box>
      )}
      {modal && (
        <Portal id="modal-root" shouldShow={Boolean(modal)}>
          <Modal {...modal} />
        </Portal>
      )}
    </>
  );
};

export default NotificationCenter;
