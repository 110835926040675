import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

const variants = {
  h1: {
    fontSize: "h1",
    lineHeight: "xxl",
    letterSpacing: 0,
    fontWeight: "regular",
    fontFamily: getConfig(config.fonts, "heading", ""),
  },
  h2: {
    fontSize: "h2",
    lineHeight: "l",
    letterSpacing: 0,
    fontWeight: "regular",
  },
  h3: {
    fontSize: "h3",
    lineHeight: "l",
    letterSpacing: 0,
    fontWeight: "regular",
  },
  h4: {
    fontSize: "h4",
    lineHeight: "l",
    letterSpacing: 0,
    fontWeight: "regular",
  },
  h5: {
    fontSize: "h5",
    lineHeight: "xxs",
    letterSpacing: 0,
    fontWeight: "bold",
  },
  h6: {
    fontSize: "h6",
    lineHeight: "xxs",
    letterSpacing: 0,
    fontWeight: "regular",
    fontFamily: getConfig(config.fonts, "heading", ""),
  },
  small: {
    fontSize: "xxs",
    lineHeight: "xxs",
    letterSpacing: 0,
    fontWeight: "regular",
  },
  medium: {
    fontSize: "xs",
    lineHeight: "xxs",
    letterSpacing: 0,
    fontWeight: "regular",
  },
  large: {
    fontSize: "s",
    lineHeight: "xxs",
    letterSpacing: 0,
    fontWeight: "regular",
  },
  xxl: {
    fontSize: "xl",
    lineHeight: "xxs",
    letterSpacing: 0,
    fontWeight: "regular",
  },
  xm: {
    fontSize: "h4",
    lineHeight: "s",
    letterSpacing: 0,
    fontWeight: "regular",
  },
  title: {
    fontSize: "h2",
    lineHeight: "s",
    letterSpacing: 0,
    fontWeight: "regular",
    fontFamily: getConfig(config.fonts, "heading", ""),
  },
  subtitle: {
    fontSize: "m",
    lineHeight: "xxs",
    letterSpacing: 0,
    fontWeight: "regular",
  },
  xl: {
    fontSize: "l",
    lineHeight: "s",
    letterSpacing: 0,
    fontWeight: "regular",
  },
  sxm: {
    fontSize: "h4",
    lineHeight: "xs",
    letterSpacing: 0,
    fontWeight: "regular",
  },
};

export default variants;
