import React from "react";

import Accordion from "../Accordion";
import Box from "../Box";
import Button from "../Button";
import Typography from "../Typography";
import { LoginSectionProps } from "./LoginSection.model";

const LoginSection: React.FC<LoginSectionProps> = ({
  handleLogin,
  description,
  buttonText,
  accordionSections,
  isLoading,
}) => {
  return (
    <Box display="flex" flexDirection="column" p="m" paddingTop="xl">
      <Box
        display="flex"
        flexDirection={{ _: "column", desktop: "row" }}
        justifyContent="space-between"
        mb={{ _: "l", desktop: "m" }}
      >
        <Typography
          variant="medium"
          color="neutralTones.7"
          mb={{ _: "xxl-2", desktop: 0 }}
          mr={{ _: 0, desktop: "xxl-3" }}
        >
          {description}
        </Typography>

        <Button
          id="homepage bottom button"
          variant="primary"
          onClick={handleLogin}
          width={{ _: "100%", desktop: 298 }}
          loading={isLoading}
        >
          {buttonText}
        </Button>
      </Box>
      <Accordion sections={accordionSections} />
    </Box>
  );
};

export default LoginSection;
