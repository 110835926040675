import { LOCALE_CODES, OPCO_CODES } from "@channel/shared-components";

export default {
  AVIOS: {
    locale: LOCALE_CODES["en-GB"],
    opco: OPCO_CODES.BAEC,
  },
  IBERIA: {
    locale: LOCALE_CODES["es-ES"],
    opco: OPCO_CODES.IBP,
  },
};
