import React from "react";
import styled from "styled-components";
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  TypographyProps as SSTypographyProps,
  typography,
  variant,
} from "styled-system";

import { TypographyProps } from "./Typography.model";
import variants from "./variants";

import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

const StyledTypography = styled.p<
  SSTypographyProps &
    SpaceProps &
    ColorProps &
    LayoutProps & {
      bold: boolean;
      italic: boolean;
      underline: boolean;
      whiteSpace?: string;
    }
>`
  font-family: ${getConfig(config.fonts, "primary", "MyliusModern")}, sans-serif;
  ${variant({ variants })}
  ${typography}
  ${space}
  ${color}
  ${layout}
  ${(props) => {
    return props.bold ? "font-weight: 700;" : "";
  }}
  ${(props) => {
    return props.italic ? "font-style: italic;" : "";
  }}
  ${(props) => {
    return props.underline ? "text-decoration: underline;" : "";
  }}
  ${(props) => {
    return props.whiteSpace ? `white-space: ${props.whiteSpace};` : "";
  }}
`;

const defaultMapping: Record<string, React.ElementType> = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  small: "p",
  medium: "p",
  large: "p",
  xxl: "p",
  sxm: "p",
  xm: "p",
  xl: "p",
  title: "h2",
  subtitle: "p",
};

const Typography: React.FC<TypographyProps> = ({
  children,
  htmlTag,
  variant = "medium",
  color = "neutralTones.6",
  ...props
}) => {
  return (
    <StyledTypography
      as={htmlTag || defaultMapping[variant]}
      variant={variant}
      color={color}
      {...props}
    >
      {children}
    </StyledTypography>
  );
};

export default Typography;
