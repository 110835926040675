import styled from "styled-components";
import {
  border,
  flexbox,
  fontSize,
  grid,
  layout,
  lineHeight,
  position,
  space,
  color as ssColor,
} from "styled-system";

import { BoxProps } from "./Box.model";

const Box = styled.div<BoxProps & { background?: string }>`
  ${space}
  ${ssColor}
  ${layout}
  ${flexbox}
  ${position}
  ${border}
  ${grid}
  ${fontSize}
  ${lineHeight}
  background: ${(props) => props.background};
`;

export default Box;
