import styled from "styled-components";
import css from "@styled-system/css";

import Box from "../../components/Box";

const TopicsContainer = styled(Box)`
  p {
    ${css({
      fontSize: "h4",
      lineHeight: "s",
    })}

    & + p {
      ${css({
        marginTop: "m",
      })}
    }
  }
`;

export default TopicsContainer;
