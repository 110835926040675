function setQueryToHref(
  href: string,
  queries: { [key: string]: string }
): string {
  if (Object.keys(queries).length === 0) {
    return href;
  }

  const hasQueries = href.includes("?");
  const queriesString = Object.entries(queries)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  return hasQueries ? `${href}&${queriesString}` : `${href}?${queriesString}`;
}

export default setQueryToHref;
