import linkingCapsParser from "./parsers/linkingPairCapsParser";
import fetchData from "../Api";
import getPairConfigParser from "./parsers/getPairConfigParser";
import { IAuthContext } from "../../context/Authentication/AuthenticationContext.model";
import {
  ExchangePairCapsDTO,
  LinkingPairCapsDTO,
  PairConfigurationDTO,
  ParsedLinkingPairCaps,
  ParsedPairConfiguration,
} from "./Pairs.model";

const { REACT_APP_ATLAS_URL } = process.env;

const getPairConfiguration = async (
  pairId: string
): Promise<ParsedPairConfiguration | null> => {
  if (pairId === "") {
    return null;
  }

  const { data, error } = await fetchData<PairConfigurationDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/pairs/${pairId}`,
  });
  return { data: getPairConfigParser(data), error };
};

const getPairRemainingPoints = async (
  pairId: string
): Promise<ExchangePairCapsDTO | null> => {
  if (pairId === "") {
    return null;
  }

  const { data, error } = await fetchData<ExchangePairCapsDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/account/pairs/${pairId}/remaining-points`,
    options: {
      credentials: "include",
    },
  });
  return { data, error };
};

const getLinkingPairCaps = async (
  pairId: string,
  expireUserSession: IAuthContext["expireUserSession"]
): Promise<ParsedLinkingPairCaps | null> => {
  if (!pairId) {
    return null;
  }

  const { data, error } = await fetchData<LinkingPairCapsDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/account/pairs/${pairId}/linking-caps`,
    options: {
      credentials: "include",
    },
    expireUserSession,
  });
  return { data: linkingCapsParser(data), error };
};

export { getPairConfiguration, getPairRemainingPoints, getLinkingPairCaps };
