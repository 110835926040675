import React from "react";
import styled from "styled-components";
import { css } from "@styled-system/css";

import Box from "../Box";
import Layout from "../Layout";
import Link from "../Link";
import Typography from "../Typography";
import { FooterProps } from "./Footer.model";
import { FlexboxProps, space, SpaceProps } from "styled-system";
import { setQueryToHref } from "../../utils/routing";
import { useAuthentication } from "../../context/Authentication/AuthenticationContext";
import { replacePlaceholderString } from "../../utils/strings";
import { trackers } from "../../utils/ga";

import theme from "../../theme";
import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

const PartnerLogo = styled.img<SpaceProps>`
  width: auto;
  height: 40px;
  ${space}
`;

const StyledLink = styled(Link)<{ flex?: FlexboxProps["flex"] }>`
  ${(props) =>
    css({
      fontSize: getConfig(config.fontSizes, "link", "14px"),
      lineHeight: "xxxs",
      flex: props.flex,
    })}
`;

const BoxWithBorder = styled(Box)`
  border-bottom: 1px solid;
  ${css({
    borderColor: getConfig<string>(
      config.footer,
      "borderColor",
      "neutralTones.7"
    ),
  })}
`;

const Footer: React.FC<FooterProps> = ({
  links,
  partnerLogoUrl,
  partnerLogoAlt,
  partnerLink,
  partnerText,
  copyrightText,
}) => {
  const { isPartnerPageVariant } = useAuthentication();

  return (
    <Box
      as="footer"
      background={getConfig<string>(
        config.footer,
        "backgroundColor",
        String(theme.colors.tertiary)
      )}
      color={getConfig<string>(config.footer, "textColor", "neutralTones.0")}
    >
      <BoxWithBorder as="section" minHeight={72}>
        <Layout>
          <Box display="flex" flexWrap="wrap" px={{ _: "l", tablet: 0 }}>
            {links.map((link) => {
              const href = link.url.startsWith("http")
                ? link.url
                : `/partner/${link.url}`;
              const hrefWithQuery = isPartnerPageVariant
                ? setQueryToHref(href, { origin: "external" })
                : href;

              return (
                <StyledLink
                  to={hrefWithQuery}
                  key={link.label}
                  color={getConfig<string>(
                    config.colors,
                    "link",
                    "neutralTones.0"
                  )}
                  mx={0}
                  mt="xxl-2"
                  mb="l"
                  mr={{ _: 0, desktop: "xxl-4" }}
                  flex={{ _: "0 0 50%", desktop: "unset" }}
                  onClick={() =>
                    trackers.trackNavigation("sitewide footer navigation")
                  }
                >
                  {link.label}
                </StyledLink>
              );
            })}
          </Box>
        </Layout>
      </BoxWithBorder>
      <Box
        as="section"
        background={getConfig<string>(
          config.footer,
          "backgroundColorBottom",
          String(theme.colors.tertiary)
        )}
      >
        <Layout>
          <Box
            p="l"
            px={{ _: "l", tablet: 0 }}
            display="flex"
            flexDirection={{ _: "column", tablet: "row" }}
            alignItems={{ _: "flex-start", tablet: "center" }}
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" mb={{ _: "s", tablet: 0 }}>
              {partnerLogoUrl && (
                <PartnerLogo src={partnerLogoUrl} alt={partnerLogoAlt} mr="m" />
              )}
              <StyledLink
                onClick={() =>
                  trackers.trackNavigation("sitewide BA footer link")
                }
                to={partnerLink}
                color="neutralTones.0"
                openInNewTab
              >
                {partnerText}
              </StyledLink>
            </Box>
            <Typography
              variant="small"
              color={getConfig<string>(
                config.footer,
                "copyrightTextColor",
                "neutralTones.4"
              )}
            >
              {replacePlaceholderString(copyrightText, {
                currentYear: new Date().getFullYear().toString(),
              })}
            </Typography>
          </Box>
        </Layout>
      </Box>
    </Box>
  );
};

export default Footer;
