import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { RoutesEnum } from "../../config/routes.enum";

const Logout = (): JSX.Element => {
  const history = useHistory();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    if (searchParams.has("partnerId") && searchParams.get("partnerId")) {
      const partnerId = searchParams.get("partnerId");
      if (partnerId) {
        history.replace(`partner/${partnerId}?operation=logout`);
      }
      return;
    }
    history.replace(RoutesEnum.OOPS);
  });
  return <></>;
};

export default Logout;
