import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  variant,
} from "styled-system";

import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";
import Typography from "../Typography";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { listItemVariants, listVariants } from "./variants";
import { ButtonVariant } from "../Button/Button.model";
import { DropdownProps, DropdownVariant } from "./Dropdown.model";

const variantMap: {
  [key: string]: ButtonVariant;
} = {
  primary: "selectPrimary",
  secondary: "selectSecondary",
  tertiary: "selectTertiary",
};

const StyledList = styled.ul<
  LayoutProps &
    SpaceProps &
    FlexboxProps &
    PositionProps & { variant: DropdownVariant }
>`
  list-style-type: none;
  ${css({
    borderWidth: "1px",
    borderStyle: "solid",
  })}
  ${variant({ variants: listVariants })}
  ${position}
  ${layout}
  ${space}
  ${flexbox}
`;

const StyledListItem = styled.li<
  LayoutProps & SpaceProps & FlexboxProps & { variant: DropdownVariant }
>`
  border: none;
  cursor: pointer;
  ${css({
    fontSize: "xs",
    "&:first-of-type": {
      paddingTop: "s",
    },
    "&:last-of-type": {
      paddingBottom: "s",
    },
  })}
  ${variant({ variants: listItemVariants })}
  ${layout}
  ${space}
  ${flexbox}
`;

const StyledImage = styled.img<SpaceProps>`
  ${space}
  ${css({
    minWidth: "xxl-5",
    objectFit: "contain",
    height: "l",
  })}
`;

const Dropdown: React.FC<DropdownProps> = ({
  id,
  label = "",
  placeholder,
  options,
  defaultSelectedIndex = -1,
  required = false,
  onChange,
  variant,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [selectedOptionIndex, setSelectedOptionIndex] =
    useState<number>(defaultSelectedIndex);

  const headerContent =
    selectedOptionIndex >= 0 ? options[selectedOptionIndex] : placeholder;

  const handleClickOutside = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  useOnClickOutside(containerRef, handleClickOutside);

  const toggleIsOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleSelectOption = (optionIndex: number) => {
    if (selectedOptionIndex === optionIndex) {
      return;
    }

    setSelectedOptionIndex(optionIndex);
    onChange?.(optionIndex);
  };

  const handleClickOption = (optionIndex: number) => {
    handleSelectOption(optionIndex);
    setIsOpen(false);
  };

  const handleKeyDownOption: React.KeyboardEventHandler<HTMLButtonElement> = (
    evt
  ) => {
    // Implementation based on
    // https://www.w3.org/TR/wai-aria-practices-1.1/examples/listbox/listbox-collapsible.html

    const focusItem = (newSelectedIndex: number) => {
      const listElement = listRef.current;

      if (!listElement) {
        return;
      }

      const listItemElement = document.getElementById(
        `listbox-${id}-option-${newSelectedIndex}`
      );

      if (!listItemElement) {
        return;
      }

      if (listElement.scrollHeight > listElement.clientHeight) {
        const scrollBottom = listElement.clientHeight + listElement.scrollTop;
        const elementBottom =
          listItemElement.offsetTop + listItemElement.offsetHeight;
        if (elementBottom > scrollBottom) {
          listElement.scrollTop = elementBottom - listElement.clientHeight;
        } else if (listItemElement.offsetTop < listElement.scrollTop) {
          listElement.scrollTop = listItemElement.offsetTop;
        }
      }
    };

    const handleNext = () => {
      if (!isOpen) {
        setIsOpen(true);
      }

      if (selectedOptionIndex === options.length - 1) {
        return;
      }

      const newSelectedIndex = selectedOptionIndex + 1;
      handleSelectOption(newSelectedIndex);
      focusItem(newSelectedIndex);
    };

    const handlePrevious = () => {
      if (!isOpen) {
        setIsOpen(true);
      }

      if (selectedOptionIndex === 0) {
        return;
      }

      const newSelectedIndex = selectedOptionIndex - 1;
      handleSelectOption(newSelectedIndex);
      focusItem(newSelectedIndex);
    };

    const handleFirst = () => {
      if (!isOpen) {
        return;
      }
      const newSelectedIndex = 0;
      handleSelectOption(newSelectedIndex);
      focusItem(newSelectedIndex);
    };

    const handleLast = () => {
      if (!isOpen) {
        return;
      }
      const newSelectedIndex = options.length - 1;
      handleSelectOption(newSelectedIndex);
      focusItem(newSelectedIndex);
    };

    const keyPressed = evt.code;

    switch (keyPressed) {
      case "ArrowDown":
        evt.preventDefault();
        handleNext();
        break;
      case "ArrowUp":
        evt.preventDefault();
        handlePrevious();
        break;
      case "Home":
      case "PageUp":
        evt.preventDefault();
        handleFirst();
        break;
      case "End":
      case "PageDown":
        evt.preventDefault();
        handleLast();

        break;
      case "Escape":
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setSelectedOptionIndex(defaultSelectedIndex);
  }, [defaultSelectedIndex]);

  return (
    <Box ref={containerRef} position="relative" {...props}>
      {label && (
        <Typography
          id={`listbox-${id}-label`}
          variant="large"
          color="text"
          htmlTag="label"
        >
          {`${label}${required ? " *" : ""}`}
        </Typography>
      )}
      <Button
        id={`listbox-${id}-button`}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        variant={variantMap[variant]}
        fontSize="xs"
        onClick={toggleIsOpen}
        onKeyDown={handleKeyDownOption}
        px="l"
        mt={7}
      >
        <Box as="span" display="flex" alignItems="center">
          {headerContent.image && (
            <StyledImage
              marginRight="xs"
              src={headerContent.image}
              alt={
                headerContent.imageAlt
                  ? headerContent.imageAlt
                  : headerContent.value
              }
            />
          )}
          {headerContent.value}
        </Box>
        <Icon
          aria-hidden={true}
          type="regular"
          name="chevron-down"
          rotate={isOpen ? "180deg" : "0"}
          color={listItemVariants[variant].color}
        />
      </Button>
      <StyledList
        ref={listRef}
        aria-labelledby={`listbox-${id}-label listbox-${id}-button`}
        aria-activedescendant={
          selectedOptionIndex >= 0
            ? `listbox-${id}-option-${selectedOptionIndex}`
            : undefined
        }
        aria-required={required}
        tabIndex={-1}
        role="listbox"
        id={`listbox-${id}`}
        position="absolute"
        overflowY="auto"
        display={isOpen ? "flex" : "none"}
        flexDirection="column"
        justifyContent="space-between"
        width="100%"
        margin={0}
        maxHeight={175}
        variant={variant}
        zIndex={99}
      >
        {options.map((option, index) => (
          <StyledListItem
            key={index}
            aria-selected={selectedOptionIndex === index}
            role="option"
            id={`listbox-${id}-option-${index}`}
            variant={variant}
            onClick={() => handleClickOption(index)}
            width="100%"
            height="100%"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            paddingY="xs"
          >
            {option.image && (
              <StyledImage
                marginLeft={-30}
                src={option.image}
                alt={option.imageAlt ? option.imageAlt : option.value}
              />
            )}
            {option.value}
          </StyledListItem>
        ))}
      </StyledList>
    </Box>
  );
};

export default Dropdown;
