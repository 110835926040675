import React from "react";
import styled from "styled-components";
import {
  border,
  BorderProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system";

import Box from "../Box";
import Link from "../Link";
import Typography from "../Typography";
import { CarouselPromotionProps } from "./CariuselPromotion.model";
import { trackers } from "../../utils/ga";

const Image = styled.img<SpaceProps & LayoutProps & BorderProps>`
  object-fit: cover;
  ${space}
  ${layout}
  ${border}
`;

const CarouselPromotion: React.FC<CarouselPromotionProps> = ({
  image,
  logo,
  title,
  description,
  linkToPromotion,
  descriptionImage,
  ...rest
}) => {
  return (
    <Box
      as="li"
      backgroundColor="neutralTones.1"
      display="flex"
      flexDirection={{ _: "column", tablet: "row" }}
      px={{ _: "xxl-3" }}
      py={{ _: "xxl-2" }}
      {...rest}
    >
      {image?.url && image?.alt && (
        <Image
          src={image.url}
          alt={image.alt}
          borderRadius={8}
          width="100%"
          maxWidth={{ _: "100%", tablet: "50%", desktop: 490 }}
        />
      )}
      <Box
        m={{ _: image?.url && image?.alt ? "xl" : 0 }}
        flex="1"
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexDirection={{ _: "column", tablet: "row" }}
          alignItems={{ tablet: "center" }}
          mb={{ _: 0, tablet: "xl" }}
        >
          {title && (
            <Typography variant="h2" color="text" mb={{ _: "xl", tablet: 0 }}>
              {title}
            </Typography>
          )}
        </Box>
        {descriptionImage?.url && (
          <Image
            src={descriptionImage.url}
            alt={descriptionImage.alt}
            maxWidth={{ _: 254 }}
            mb={{ _: "s" }}
          />
        )}
        {description && <Box width="100%">{description}</Box>}
        {linkToPromotion && (
          <Link
            onClick={() =>
              trackers.trackPromotionInteraction({
                location: "promotion carousel",
                partner_name: logo?.alt,
                promotion_name: title,
              })
            }
            variant="navAnchorIcon"
            width={{ _: "100%", desktop: 220 }}
            to={linkToPromotion.url}
            mt={18}
          >
            {linkToPromotion.label}
          </Link>
        )}
      </Box>
    </Box>
  );
};

export default CarouselPromotion;
