import { HeroSectionDTO, ParsedHeroSection } from "./heroSectionParser.model";

const heroSectionParser = (
  dto: HeroSectionDTO["data"]
): ParsedHeroSection["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const {
    loginButtonText,
    linkingButtonText,
    description: text,
    heroImage,
    partner,
  } = dto.items[0].fields;

  return {
    loginButtonText,
    linkingButtonText,
    text,
    brandName: partner.fields.partnerName,
    logoUrl: partner.fields.fullLogo.fields.image.fields.file.url,
    logoAlt: partner.fields.fullLogo.fields.alt,
    heroImageUrl: heroImage.fields.image.fields.file.url,
    heroImageAlt: heroImage.fields.alt,
  };
};

export default heroSectionParser;
