import { PairConfigurationDTO, ParsedPairConfiguration } from "../Pairs.model";

const getPairConfigParser = (
  dto: PairConfigurationDTO["data"]
): ParsedPairConfiguration["data"] => {
  if (!dto) {
    return undefined;
  }

  const { partnersConfiguration, rate } = dto;

  return {
    rate,
    partnersConfiguration,
  };
};

export default getPairConfigParser;
