import css from "@styled-system/css";

import { TAccordionVariant } from "./Accordion.model";

type TVariantKeys = TAccordionVariant;

const variants: {
  [key in TVariantKeys]: unknown;
} = {
  default: css({
    borderColor: "neutralTones.4",
    paddingX: "xl",
  }),
  outline: css({
    borderTopColor: "neutralTones.4",
    borderBottomColor: "neutralTones.4",
  }),
};

const bodyVariants: {
  [key in TVariantKeys]: unknown;
} = {
  default: css({
    borderColor: "neutralTones.4",
    paddingX: "xl",
  }),
  outline: css({
    borderColor: "transparent",
  }),
};

export { variants, bodyVariants };
