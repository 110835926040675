import config from "../../build-config.json";
import fetchData from "../Api";
import useFetch from "../../hooks/useFetch";
import { APIError } from "../../Global.model";
import { TContent } from "./Contentful.model";
import {
  ContactUsPageDTO,
  ParsedContactUsPage,
} from "./parsers/contactUsPage/contactUsPageParser.model";
import {
  ConversionSectionDTO,
  ParsedConversionSection,
} from "./parsers/conversionSection/conversionSectionParser.model";
import {
  CookiesPageDTO,
  ParsedCookiesPage,
} from "./parsers/cookiesPage/cookiesPageParser.model";
import {
  FaqPageDTO,
  ParsedFaqPage,
} from "./parsers/faqPage/faqPageParser.model";
import { FooterDTO, ParsedFooter } from "./parsers/footer/footerParser.model";
import { HeaderDTO, ParsedHeader } from "./parsers/header/headerParser.model";
import {
  HeroSectionDTO,
  ParsedHeroSection,
} from "./parsers/heroSection/heroSectionParser.model";
import {
  HomepageDTO,
  ParsedHomepage,
} from "./parsers/homepage/homepageParser.model";
import {
  LinkAccountsSectionDTO,
  ParsedLinkAccountsSection,
} from "./parsers/linkAccountsSection/linkAccountsSectionParser.model";
import {
  LoginSectionDTO,
  ParsedLoginSection,
} from "./parsers/loginSection/loginSectionParser.model";
import {
  NotificationsDTO,
  ParsedNotifications,
} from "./parsers/notifications/notificationsParser.model";
import {
  OopsPageDTO,
  ParsedOopsPage,
} from "./parsers/oopsPage/oopsPageParser.model";
import { PageDTO, ParsedPage } from "./parsers/page/pageParser.model";
import {
  ParsedPrivacyAndSecurityPage,
  PrivacyAndSecurityPageDTO,
} from "./parsers/privacyAndSecurityPage/privacyAndSecurityPageParser.model";
import {
  ParsedPromotion,
  ParsedPromotions,
  PromotionDTO,
} from "./parsers/promotion/promotionParser.model";
import {
  ParsedTermsAndConditionsPage,
  TermsAndConditionsPageDTO,
} from "./parsers/termsAndConditionsPage/termsAndConditionsPageParser.model";
import {
  ParsedThreeColumnsSection,
  ThreeColumnsSectionsDTO,
} from "./parsers/threeColumnsSection/threeColumnsSectionParser.model";
import {
  ParsedTooltips,
  TooltipsDTO,
} from "./parsers/tooltips/tooltipsParser.model";
import {
  ParsedTwoCardsSection,
  TwoCardsSectionDTO,
} from "./parsers/twoCardsSection/twoCardsSectionParser.model";
import {
  FeatureToggleDTO,
  ParsedFeatureToggle,
} from "./parsers/featureToggle/featureToggleParser.model";
import {
  PairPartnersDTO,
  ParsedPairPartners,
} from "./parsers/pairPartners/pairPartners.model";
import {
  ParsedCleanRulesPage,
  CleanRulesPageDTO,
} from "./parsers/cleanRulesPage/cleanRulesPageParser.model";
import {
  contactUsPageParser,
  conversionSectionParser,
  cookiesPageParser,
  faqPageParser,
  featureToggleParser,
  footerParser,
  headerParser,
  heroSectionParser,
  homepageParser,
  linkAccountsSectionParser,
  loginSectionParser,
  notificationsParser,
  oopsPageParser,
  pageParser,
  pairPartnersParser,
  privacyAndSecurityPageParser,
  promotionParser,
  termsAndConditionsPageParser,
  threeColumnsSectionParser,
  tooltipsParser,
  twoCardsSectionParser,
  cleanRulesPageParser,
  versatilePromotionParser,
} from "./parsers";
import {
  ParsedVersatilePromotion,
  VersatilePromotionDTO,
} from "./parsers/versatilePromotion/versatilePromotionParser.model";

interface Response<T> extends APIError {
  data?: T;
}

const { REACT_APP_CONTENTFUL_API_BASE_URL } = process.env;

const urlBuilder = ({ content, contentHeaders, pairId }: TContent) => {
  /**
   - If there are multiple locales, we need to specify which one we want to use.
   - Right now, we only support one locale by partner so if it exists we will use it,
   otherwise we will use the default one configured in Contentful.
   -If there is only one locale defined in Contentful for the partner, 
   we don't have to specify it here, as it will be handled by Contentful.

   TODO: This logic must change once we support multiple locales by partner because
   Contentful accepts * to fetch all contents at once, but it will break all of the 
   content parsers due to the payload format returned.
   */
  const localization = config.locales.length
    ? `&locale=${config.locales[0]}`
    : "";

  const partnerId = pairId
    ? `&fields.partnerId.fields.id[match]=${pairId}&fields.partnerId.sys.contentType.sys.id=partnerId${localization}`
    : "";

  const params = new URLSearchParams({
    content_type: content,
    ...contentHeaders,
  });
  return `${REACT_APP_CONTENTFUL_API_BASE_URL}/entries?${params}${partnerId}`;
};

const getContent = async <T>({
  content,
  contentHeaders,
  pairId,
}: TContent): Promise<Response<T>> => {
  const { data, error } = await fetchData<T>({
    url: urlBuilder({ content, contentHeaders, pairId }),
  });
  return { data, error };
};

const getPageContent = async (
  pairId: string,
  pageType: string
): Promise<ParsedPage> => {
  const { data, error } = await getContent<PageDTO["data"]>({
    content: "page",
    contentHeaders: {
      "fields.pageType": pageType,
    },
    pairId,
  });
  return { data: pageParser(data), error };
};

const getHeroSectionContent = async (
  pairId: string
): Promise<ParsedHeroSection> => {
  const { data, error } = await getContent<HeroSectionDTO["data"]>({
    content: "heroSection",
    contentHeaders: {
      include: "2",
    },
    pairId,
  });
  return { data: heroSectionParser(data), error };
};

const getHeaderContent = async (pairId: string): Promise<ParsedHeader> => {
  const { data, error } = await getContent<HeaderDTO["data"]>({
    content: "header",
    contentHeaders: {
      include: "2",
    },
    pairId,
  });
  return { data: headerParser(data), error };
};

const getFooterContent = async (pairId: string): Promise<ParsedFooter> => {
  const { data, error } = await getContent<FooterDTO["data"]>({
    content: "footer",
    pairId,
  });
  return { data: footerParser(data), error };
};

const getPromotionContent = async (
  pairId: string
): Promise<ParsedPromotion> => {
  const { data, error } = await getContent<PromotionDTO["data"]>({
    content: "promotion",
    contentHeaders: {
      include: "2",
    },
    pairId,
  });
  return { data: promotionParser(data), error };
};

const getVersatilePromotionContent = async (
  pairId: string
): Promise<ParsedVersatilePromotion> => {
  const { data, error } = await getContent<VersatilePromotionDTO["data"]>({
    content: "versatilePromotion",
    contentHeaders: {
      include: "2",
    },
    pairId,
  });
  return { data: versatilePromotionParser(data), error };
};

const getThreeColumnsSectionContent = async (
  pairId: string
): Promise<ParsedThreeColumnsSection> => {
  const { data, error } = await getContent<ThreeColumnsSectionsDTO["data"]>({
    content: "threeColumnsSection",
    contentHeaders: {
      include: "2",
    },
    pairId,
  });
  return { data: threeColumnsSectionParser(data), error };
};

const getTwoCardsSectionContent = async (
  pairId: string
): Promise<ParsedTwoCardsSection> => {
  const { data, error } = await getContent<TwoCardsSectionDTO["data"]>({
    content: "twoCardsSection",
    pairId,
  });
  return { data: twoCardsSectionParser(data), error };
};

const getLoginSectionContent = async (
  pairId: string
): Promise<ParsedLoginSection> => {
  const { data, error } = await getContent<LoginSectionDTO["data"]>({
    content: "loginSection",
    contentHeaders: {
      include: "2",
    },
    pairId,
  });
  return { data: loginSectionParser(data), error };
};

const getLinkAccountsSectionContent = async (
  pairId: string
): Promise<ParsedLinkAccountsSection> => {
  const { data, error } = await getContent<LinkAccountsSectionDTO["data"]>({
    content: "linkAccountsSection",
    contentHeaders: {
      include: "3",
    },
    pairId,
  });

  return { data: linkAccountsSectionParser(data), error };
};

const getConversionSectionContent = async (
  pairId: string
): Promise<ParsedConversionSection> => {
  const { data, error } = await getContent<ConversionSectionDTO["data"]>({
    content: "convertSection",
    contentHeaders: {
      include: "3",
    },
    pairId,
  });
  return { data: conversionSectionParser(data), error };
};

const useGetOopsPage = (): ParsedOopsPage => {
  const { data, error } = useFetch<OopsPageDTO["data"]>(
    urlBuilder({
      content: "oopsPage",
    })
  );

  return { data: oopsPageParser(data), error };
};

const useGetFaqPageContent = (pairId: string): ParsedFaqPage => {
  const { data, error } = useFetch<FaqPageDTO["data"]>(
    urlBuilder({
      content: "faqPage",
      contentHeaders: {
        include: "2",
      },
      pairId,
    })
  );

  return { data: faqPageParser(data), error };
};

const useGetCookiesPageContent = (pairId: string): ParsedCookiesPage => {
  const { data, error } = useFetch<CookiesPageDTO["data"]>(
    urlBuilder({
      content: "cookiesPage",
      pairId,
    })
  );

  return { data: cookiesPageParser(data), error };
};

const useGetTermsAndConditionsPageContent = (
  pairId: string
): ParsedTermsAndConditionsPage => {
  const { data, error } = useFetch<TermsAndConditionsPageDTO["data"]>(
    urlBuilder({
      content: "termsAndConditionsPage",
      contentHeaders: {
        include: "2",
      },
      pairId,
    })
  );

  return { data: termsAndConditionsPageParser(data), error };
};

const useGetPrivacyAndSecurityPageContent = (
  pairId: string
): ParsedPrivacyAndSecurityPage => {
  const { data, error } = useFetch<PrivacyAndSecurityPageDTO["data"]>(
    urlBuilder({
      content: "privacyAndSecurityPage",
      pairId,
    })
  );

  return { data: privacyAndSecurityPageParser(data), error };
};

const getTooltipsContent = async (pairId: string): Promise<ParsedTooltips> => {
  const { data, error } = await getContent<TooltipsDTO["data"]>({
    content: "tooltips",
    pairId,
  });
  return { data: tooltipsParser(data), error };
};

const getNotificationsContent = async (
  pairId: string
): Promise<ParsedNotifications> => {
  const { data, error } = await getContent<NotificationsDTO["data"]>({
    content: "notifications",
    contentHeaders: {
      include: "2",
    },
    pairId: pairId || "NO_PARTNER",
  });
  return { data: notificationsParser(data), error };
};

const useGetContactUsPageContent = (pairId: string): ParsedContactUsPage => {
  const { data, error } = useFetch<ContactUsPageDTO["data"]>(
    urlBuilder({
      content: "contactUsPage",
      contentHeaders: {
        include: "2",
      },
      pairId,
    })
  );

  return { data: contactUsPageParser(data), error };
};

const getHomepageContent = async (): Promise<ParsedHomepage> => {
  const { data, error } = await getContent<HomepageDTO["data"]>({
    content: "partnersPage",
    contentHeaders: {
      include: "2",
    },
  });
  return { data: homepageParser(data), error };
};

const getFeatureToggleContent = async (
  pairId: string
): Promise<ParsedFeatureToggle> => {
  const { data, error } = await getContent<FeatureToggleDTO["data"]>({
    content: "featureToggle",
    contentHeaders: {
      include: "2",
    },
    pairId,
  });
  return { data: featureToggleParser(data), error };
};

const getPairPartnersContent = async (
  pairId: string
): Promise<ParsedPairPartners> => {
  const { data, error } = await getContent<PairPartnersDTO["data"]>({
    content: "pairPartners",
    contentHeaders: {
      include: "3",
    },
    pairId,
  });
  return { data: pairPartnersParser(data), error };
};

const getAllPromotionsContent = async (
  pairIds: string[]
): Promise<ParsedPromotions> => {
  const promises = pairIds.map((pairId) =>
    fetchData<PromotionDTO["data"]>({
      url: urlBuilder({
        content: "promotion",
        contentHeaders: {
          include: "2",
        },
        pairId,
      }),
    })
  );
  const results = await Promise.allSettled(promises);
  return {
    data: results.map((result) => {
      return result.status === "fulfilled"
        ? promotionParser(result.value.data)
        : undefined;
    }),
  };
};

const getCleanRulesPageContent = async (): Promise<ParsedCleanRulesPage> => {
  const { data, error } = await getContent<CleanRulesPageDTO["data"]>({
    content: "cleanRulesPage",
    contentHeaders: {
      include: "2",
    },
    pairId: "NO_PARTNER",
  });

  return { data: cleanRulesPageParser(data), error };
};

export {
  useGetOopsPage,
  useGetCookiesPageContent,
  useGetTermsAndConditionsPageContent,
  useGetPrivacyAndSecurityPageContent,
  useGetFaqPageContent,
  useGetContactUsPageContent,
  getPageContent,
  getHeroSectionContent,
  getHeaderContent,
  getFooterContent,
  getPromotionContent,
  getAllPromotionsContent,
  getThreeColumnsSectionContent,
  getTwoCardsSectionContent,
  getLoginSectionContent,
  getTooltipsContent,
  getLinkAccountsSectionContent,
  getConversionSectionContent,
  getNotificationsContent,
  getHomepageContent,
  getFeatureToggleContent,
  getPairPartnersContent,
  getCleanRulesPageContent,
  getVersatilePromotionContent,
};
