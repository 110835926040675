import { LinkingPairCapsDTO, ParsedLinkingPairCaps } from "../Pairs.model";

const linkingCapsParser = (
  dto: LinkingPairCapsDTO["data"]
): ParsedLinkingPairCaps["data"] => {
  if (!dto || dto.caps === undefined) {
    return undefined;
  }

  const { allowed, remaining, used } = dto.caps.account;

  return {
    allowed: allowed.linking,
    used: used.linking,
    remaining: remaining.linking,
  };
};

export default linkingCapsParser;
