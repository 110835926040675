import React from "react";

import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";
import Typography from "../Typography";
import modalsIds from "../../dictionaries/modalsIds";
import { AoiHeadingProps } from "./AoiHeading.model";
import { useAutoConversion } from "../../context/AutoConversion/AutoConversionContext";
import { useNotifications } from "../../context/Notifications/NotificationsContext";

import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

const AoiHeading: React.FC<AoiHeadingProps> = ({
  exchangeContent = undefined,
  handleAoiSetup,
}) => {
  const { configPayloadState, handleActiveStep } = useAutoConversion();
  const { addModal, getModalTemplate } = useNotifications();

  const handleAoiSetupCancel = () => {
    const modal = getModalTemplate(modalsIds.CANCEL_AOI_SETUP);

    if (!modal) {
      return;
    }
    addModal({
      ...modal,
      handleClickCta: () => {
        handleActiveStep(0, "reset operation", {});
        handleAoiSetup();
      },
      hasCloseButton: true,
    });
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb="xxl"
      mt="xxs"
      height="l"
    >
      <Typography
        variant="xl"
        width="100%"
        textAlign="center"
        color={getConfig<string>(config.colors, "tertiary", "tertiary")}
        pl="xl"
      >
        {exchangeContent?.heading}
      </Typography>
      <Button
        aria-label={exchangeContent?.cancelButtonAriaLabel}
        id="auto-conversion-close-button"
        variant="text"
        height="xxl"
        onClick={
          Object.values(configPayloadState).some((value) => Boolean(value))
            ? handleAoiSetupCancel
            : handleAoiSetup
        }
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon type="regular" name="xmark" width="xl" height="xl" />
      </Button>
    </Box>
  );
};

export default AoiHeading;
