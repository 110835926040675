type TAoiStatusKeys =
  | "ACTIVE"
  | "DELETED"
  | "UNSUBSCRIBED"
  | "SUBSCRIBED"
  | "ERROR"
  | "FAILURE"
  | "SUCCESS";

export type TAoiStatusIds = Record<TAoiStatusKeys, string>;

const aoiStatusIds: TAoiStatusIds = {
  ACTIVE: "ACTIVE",
  DELETED: "DELETED",
  UNSUBSCRIBED: "UNSUBSCRIBED",
  SUBSCRIBED: "SUBSCRIBED",
  ERROR: "ERROR",
  FAILURE: "FAILURE",
  SUCCESS: "SUCCESS",
};

export { aoiStatusIds };
