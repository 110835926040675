import config from "./build-config.json";
import getConfig from "./utils/configGetter";

export interface ThemeProps {
  /* eslint @typescript-eslint/no-explicit-any: "off" */
  breakpoints: any;
  colors: {
    [key: string]: string | string[];
  };
  fontSizes: {
    [key: string]: number | string;
  };
  fontWeights: {
    [key: string]: number;
  };
  lineHeights: {
    [key: string]: string;
  };
  space: {
    [key: string]: number;
  };
  sizes: {
    [key: string]: number;
  };
}

const breakpoints = ["600px", "980px", "1240px"];

const colors = {
  primary: ["#b73e3e", "#df2a39"],
  secondary: ["#2a78cd", "#3185ee", "#0054A6", "#2e5c99"],
  tertiary: "#021b41",
  neutralTones: [
    // 0
    "#fff",
    // 1
    "#eee",
    // 2
    "#e3e3e3",
    // 3
    "#d9d9d9",
    // 4
    "#a9a8a9",
    // 5
    "#52575a",
    // 6
    "#000",
    // 7
    "#656B6F",
  ],
  text: "#021b41",
  warning: ["#f2e189", "#52575a"],
  error: ["#b73e3e", "#fff"],
  success: ["#2a78cd", "#fff"],
};

const fontSizes = {
  h1: 30,
  h2: 24,
  h3: 20,
  h4: 16,
  h5: 14,
  h6: 14,
  xxs: 12,
  xs: 14,
  s: 16,
  m: 18,
  l: 20,
  xl: 24,
  xxl: 30,
};

const fontWeights = {
  regular: 400,
  bold: 700,
};

const lineHeights = {
  xxxs: "16px",
  xxs: "20px",
  xs: "22px",
  s: "24px",
  m: "26px",
  l: "30px",
  xl: "32px",
  xxl: "40px",
};

const space = {
  xxs: 4,
  xs: 8,
  s: 12,
  m: 16,
  l: 20,
  xl: 24,
  xxl: 28,
  "xxl-2": 32,
  "xxl-3": 36,
  "xxl-4": 40,
  "xxl-5": 58,
  "xxl-6": 68,
  "max-width": 1280,
};

const sizes = { ...space };

const theme: ThemeProps = {
  breakpoints,
  colors: { ...colors, ...getConfig(config.colors, "", {}) },
  fontSizes: { ...fontSizes, ...getConfig(config.fontSizes, "", {}) },
  fontWeights,
  lineHeights: { ...lineHeights, ...getConfig(config.lineHeights, "", {}) },
  space,
  sizes,
};

theme.breakpoints.tablet = breakpoints[0];
theme.breakpoints.desktop = breakpoints[1];
theme.breakpoints.desktopLarge = breakpoints[2];

export default theme;
