import { HeaderDTO, ParsedHeader } from "./headerParser.model";

const headerParser = (
  dto: HeaderDTO["data"]
): ParsedHeader["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const {
    headerButtons,
    logo,
    logoLink,
    labels,
    mobileLogo,
    goBackButtonLabel,
    topLink,
    topLinkPartnerVersion,
    loginButtonLabel,
  } = dto.items[0].fields;

  return {
    logoSrc: logo.fields.image.fields.file.url,
    logoAlt: logo.fields.alt,
    mobileLogoSrc: mobileLogo?.fields.image.fields.file.url || "",
    mobileLogoAlt: mobileLogo?.fields.alt || "",
    logoLink,
    buttons: headerButtons.map((button) => ({
      id: button.fields.id,
      name: button.fields.name,
      icon: button.fields.icon,
      buttonText: button.fields.buttonText || "",
      url: button.fields.url.fields.url,
      shouldBeDisplayed: button.fields.shouldBeDisplayed,
      buttonAriaLabel: button.fields.buttonAriaLabel,
      shouldBeHighlighted: button.fields.shouldBeHighlighted,
    })),
    labels: {
      mainAriaLabel: labels.fields.mainAriaLabel,
      membershipNumberLabel: labels.fields.membershipNumberLabel,
      balanceLabel: labels.fields.balanceLabel,
      logoutLabel: labels.fields.logoutLabel,
      closeButtonAriaLabel: labels.fields.closeButtonAriaLabel,
      unavailableBalanceLabel: labels.fields.unavailableBalanceLabel,
      partnerImage: {
        imgSrc: labels.fields.partnerImage.fields.image.fields.file.url,
        imgAlt: labels.fields.partnerImage.fields.alt,
      },
    },
    goBackButtonLabel,
    topLink: topLink
      ? {
          label: topLink.fields.label,
          url: topLink.fields.url,
        }
      : undefined,
    topLinkPartnerVersion: topLinkPartnerVersion
      ? {
          label: topLinkPartnerVersion.fields.label,
          url: topLinkPartnerVersion.fields.url,
        }
      : undefined,
    loginButtonLabel,
  };
};

export default headerParser;
