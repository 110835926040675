import richTextToComponents from "../../../../utils/richTextToComponents";
import { FaqPageDTO, ParsedFaqPage } from "./faqPageParser.model";

const cookiesPageParser = (
  dto: FaqPageDTO["data"]
): ParsedFaqPage["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const { title, subtitle, qa_accordion } = dto.items[0].fields;

  return {
    title,
    subtitle,
    qa_accordion: qa_accordion.fields.sections.map((item) => {
      return {
        header: item.fields.question,
        body: richTextToComponents(item.fields.answer),
      };
    }),
  };
};

export default cookiesPageParser;
