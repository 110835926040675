import {
  NotificationsDTO,
  ParsedNotifications,
} from "./notificationsParser.model";

const notificationsParser = (
  dto: NotificationsDTO["data"]
): ParsedNotifications["data"] => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const { banners, modals } = dto.items[0].fields;

  return {
    banners: banners.map((banner) => ({
      id: banner.fields.id,
      variant: banner.fields.variant,
      header: banner.fields.header,
      message: banner.fields.message,
      iconName: banner.fields.iconName,
    })),
    modals: modals.map((modal) => ({
      id: modal.fields.id,
      variant: modal.fields.variant,
      header: modal.fields.title,
      ctaText: modal.fields.confirmButtonText,
      cancelText: modal.fields.cancelButtonText || "",
      closeButtonAriaLabel: modal.fields.closeButtonText || "",
      message: modal.fields.modalMessage,
      secondaryMessage: modal.fields.modalSecondaryMessage,
      confirmationMessage: modal.fields.modalConfirmationMessage || "",
      redirectionWebsite: modal.fields.redirectionWebsite,
    })),
  };
};

export default notificationsParser;
