import React from "react";
import styled, { keyframes, css as styledCss } from "styled-components";
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  size,
  SizeProps,
  space,
  SpaceProps,
  style,
  typography,
  TypographyProps,
  variant,
} from "styled-system";
import { css } from "@styled-system/css";

import variants from "./variants";
import { ButtonProps, ButtonTextColor, ButtonVariant } from "./Button.model";

import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

const loadingAnimation = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

const getLoadingAnimation = () => styledCss`
  &:after {
    animation: ${loadingAnimation} 1.5s linear infinite;
  }
`;

const StyledButton = styled.button<
  SpaceProps &
    LayoutProps &
    TypographyProps &
    SizeProps &
    FlexboxProps & {
      variant: ButtonVariant;
      textColor?: ButtonTextColor;
      isLoading?: boolean;
    }
>`
  border: none;
  border-radius: 4px;
  font-family: ${getConfig(config.fonts, "button", "MyliusModern")}, sans-serif;
  cursor: pointer;
  ${css({
    fontSize: getConfig(config.fontSizes, "button", "s"),
    lineHeight: getConfig(config.lineHeights, "button", "xxs"),
  })}
  ${variant({ variants })}
  ${space}
  ${layout}
  ${typography}
  ${size}
  ${flexbox}
  ${style({
    prop: "textColor",
    cssProperty: "color",
    key: "colors",
  })}
  ${(props) =>
    props.textColor &&
    css({
      "&:hover": {
        color: props.textColor,
        filter: "brightness(125%)",
      },
    })}
  ${css({
    "&:disabled": {
      cursor: "not-allowed",
    },
  })}
  ${(props) =>
    props.isLoading &&
    css({
      position: "relative",
      color: "transparent!important",
      cursor: "progress",
      "&:after": {
        color: "white",
        content: "''",
        borderRadius: "50%",
        width: "16px",
        height: "16px",
        zIndex: "1",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translateY(-50%)",
        borderColor: variants[props.variant].color,
        borderLeftColor: "transparent",

        borderStyle: "solid",
        borderWidth: "2px",
      },
    })}
    ${getLoadingAnimation()}
`;

const Button: React.FC<ButtonProps> = ({
  children,
  disabled = false,
  onClick = () => {},
  onKeyDown = () => {},
  variant = "primary",
  type = "button",
  loading = false,
  ...props
}) => {
  return (
    <StyledButton
      disabled={loading || disabled}
      onClick={onClick}
      onKeyDown={onKeyDown}
      variant={variant}
      type={type}
      isLoading={loading}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
