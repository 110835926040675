import { createGlobalStyle } from "styled-components";
import config from "./build-config.json";
import getConfig from "./utils/configGetter";

const GlobalStyle = createGlobalStyle`
  #root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #eee
  }

  main {
    flex: 1
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: ${getConfig(
      config.fonts,
      "primary",
      "MyliusModern"
    )}, sans-serif;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export default GlobalStyle;
