import React, { useCallback, useEffect, useReducer, useState } from "react";
import bannersIds from "../../dictionaries/bannersIds";
import datadogRum from "../../utils/ddRum";
import {
  cancelSubscription,
  enableSubscription,
  getSubscriptionStatus,
} from "../../services/Aoi";
import { EnableSubscriptionData } from "../../services/Aoi/Aoi.model";
import { useAuthentication } from "../Authentication/AuthenticationContext";
import { useNotifications } from "../Notifications/NotificationsContext";
import { usePartnershipContext } from "../Partnership/PartnershipContext";
import { AutoConversionContext } from "./AutoConversionContext";
import { trackers } from "../../utils/ga";
import {
  ActionTypes,
  AutoConversionProviderProps,
  IConfigPayload,
  IPayloadAction,
  Steps,
  TAoiState,
} from "./AutoConversionContext.model";

const initialConfigPayloadFn = (): IConfigPayload => {
  return {
    conversionFrequency: undefined,
    amountOfPoints: undefined,
    sourcePartner: undefined,
    destinationPartner: undefined,
  };
};

const payloadReducer = (state: IConfigPayload, action: IPayloadAction) => {
  switch (action.type) {
    case "set direction":
      return {
        ...state,
        sourcePartner: action.payload.sourcePartner,
        destinationPartner: action.payload.destinationPartner,
      };
    case "set frequency":
      return {
        ...state,
        conversionFrequency: action.payload.conversionFrequency,
      };
    case "set amount of points":
      return {
        ...state,
        amountOfPoints: action.payload.amountOfPoints,
      };
    case "reset operation":
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

const AutoConversionProvider: React.FC<AutoConversionProviderProps> = ({
  children,
}) => {
  const { addBanner } = useNotifications();
  const { user: authenticatedUser, expireUserSession } = useAuthentication();
  const {
    data: { pairId, urlPartnerPath, basePartnerId },
  } = usePartnershipContext();

  const [isLoadingAoiInfo, setIsLoadingAoiInfo] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const [steps, setSteps] = useState<Steps[]>([
    { label: "Conversion direction", stepNumber: 0, isCompleted: false },
    { label: "Conversion frequency", stepNumber: 1, isCompleted: false },
    { label: "Amount of points", stepNumber: 2, isCompleted: false },
  ]);

  const [configPayloadState, dispatchConfigPayload] = useReducer(
    payloadReducer,
    initialConfigPayloadFn()
  );

  const [aoiState, setAoiState] = useState({} as TAoiState);

  const handleActiveStep = (
    stepId: number,
    action?: ActionTypes,
    stepInfo?: IConfigPayload
  ) => {
    action &&
      stepInfo &&
      dispatchConfigPayload({ type: action, payload: stepInfo });
    setActiveStep(stepId);
  };

  const getSubscriptionInfo = useCallback(() => {
    const getInfo = async () => {
      setIsLoadingAoiInfo(true);
      const { data: subscriptionStatusData, error: subscriptionStatusError } =
        await getSubscriptionStatus(pairId, expireUserSession);

      if (subscriptionStatusError) {
        addBanner({ bannerId: bannersIds.INTERNAL_ERROR });
        setIsLoadingAoiInfo(false);
        return;
      }

      if (subscriptionStatusData) {
        setAoiState(subscriptionStatusData);
        handleActiveStep(0, "reset operation", {});
        setIsLoadingAoiInfo(false);
      }
    };
    getInfo();
  }, [pairId, addBanner, expireUserSession]);

  const handleAoiSubscription = useCallback(async () => {
    setIsLoadingAoiInfo(true);

    if (
      configPayloadState.sourcePartner &&
      configPayloadState.destinationPartner
    ) {
      const subscriptionData: EnableSubscriptionData = {
        sourcePartnerId: configPayloadState.sourcePartner,
        destinationPartnerId: configPayloadState.destinationPartner,
      };

      const { data, error } = await enableSubscription(
        subscriptionData,
        pairId,
        urlPartnerPath,
        expireUserSession
      );

      if (error) {
        addBanner({
          bannerId: error.code,
          handleAdditionalOnClose: () => setIsLoadingAoiInfo(false),
        });
        return;
      }

      if (data && "url" in data) {
        //Track subscription with MFA
        trackers.trackExchangeOperation("subscribe to auto-conversion", {
          memberID: authenticatedUser?.membershipNumber,
          convert_from: configPayloadState.sourcePartner,
          convert_to: configPayloadState.destinationPartner,
          point_of_origin: basePartnerId,
          location: "automatic conversion component",
        });

        window.location.assign(data.url);
        return;
      }

      getSubscriptionInfo();

      trackers.trackExchangeOperation("subscribe to auto-conversion", {
        memberID: authenticatedUser?.membershipNumber,
        convert_from: configPayloadState.sourcePartner,
        convert_to: configPayloadState.destinationPartner,
        point_of_origin: basePartnerId,
        location: "automatic conversion component",
      });

      datadogRum.addAction("aoi activated", subscriptionData);
    }
  }, [
    addBanner,
    pairId,
    urlPartnerPath,
    getSubscriptionInfo,
    configPayloadState.sourcePartner,
    configPayloadState.destinationPartner,
    expireUserSession,
    basePartnerId,
    authenticatedUser?.membershipNumber,
  ]);

  const handleAoiUnsubscription = useCallback(async () => {
    setIsLoadingAoiInfo(true);
    const source = aoiState?.sourceAccountInternalId;
    if (source) {
      const { error } = await cancelSubscription(pairId, expireUserSession);

      if (error) {
        addBanner({
          bannerId: error.code,
          handleAdditionalOnClose: () => setIsLoadingAoiInfo(false),
        });
        return;
      }

      trackers.trackExchangeOperation("cancel auto-conversion", {
        memberID: authenticatedUser?.membershipNumber,
        point_of_origin: basePartnerId,
        location: "automatic conversion component",
      });

      datadogRum.addAction("aoi disabled");

      getSubscriptionInfo();
    }
  }, [
    pairId,
    addBanner,
    setIsLoadingAoiInfo,
    aoiState.sourceAccountInternalId,
    getSubscriptionInfo,
    expireUserSession,
    basePartnerId,
    authenticatedUser?.membershipNumber,
  ]);

  useEffect(() => {
    setSteps((oldSteps) => {
      return oldSteps.map((step) => {
        return { ...step, isCompleted: step.stepNumber < activeStep };
      });
    });
  }, [activeStep]);

  useEffect(() => {
    authenticatedUser?.accountIsLinked && getSubscriptionInfo();
  }, [authenticatedUser?.accountIsLinked, getSubscriptionInfo]);

  return (
    <AutoConversionContext.Provider
      value={{
        steps,
        isLoadingAoiInfo,
        aoiState,
        handleAoiSubscription,
        handleAoiUnsubscription,
        activeStep,
        handleActiveStep,
        configPayloadState,
        dispatchConfigPayload,
      }}
    >
      {children}
    </AutoConversionContext.Provider>
  );
};

export default AutoConversionProvider;
