import fetchData from "../Api";
import redirects from "../../config/redirectUrls";
import { IAuthContext } from "../../context/Authentication/AuthenticationContext.model";
import {
  AccountBalanceDTO,
  AccountInfoDTO,
  CleanRulesDTO,
  LinkedAccountInfoDTO,
  LinkExistsDTO,
  LinkNewAccountDTO,
  UnlinkAccountDTO,
} from "./Accounts.model";

const { REACT_APP_ATLAS_URL } = process.env;

const getAccountInfo = async (): Promise<AccountInfoDTO> => {
  const { data, error } = await fetchData<AccountInfoDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/account`,
    options: {
      credentials: "include",
      cache: "no-cache",
    },
  });

  return { data, error };
};

const getAccountBalance = async (
  expireUserSession: IAuthContext["expireUserSession"]
): Promise<AccountBalanceDTO> => {
  const { data, error } = await fetchData<AccountBalanceDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/account/balance`,
    options: {
      credentials: "include",
      cache: "no-cache",
    },
    expireUserSession,
  });

  return { data, error };
};

const getLinkPartnerExists = async (
  partnerId: string,
  expireUserSession: IAuthContext["expireUserSession"]
): Promise<LinkExistsDTO | null> => {
  if (!partnerId) {
    return null;
  }

  const { data, error } = await fetchData<LinkExistsDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/account/links/${partnerId}/exists`,
    options: {
      credentials: "include",
      cache: "no-cache",
    },
    expireUserSession,
  });

  return { data, error };
};

const linkNewAccount = async (
  partnerId: string,
  expireUserSession: IAuthContext["expireUserSession"]
): Promise<LinkNewAccountDTO> => {
  const { data, error } = await fetchData<LinkNewAccountDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/account/links/${partnerId}`,
    options: {
      method: "POST",
      credentials: "include",
      cache: "no-cache",
    },
    expireUserSession,
    params: {
      successRedirectUrl: redirects.link.successRedirectUrl(partnerId),
      failureRedirectUrl: redirects.link.failureRedirectUrl(partnerId),
    },
  });

  return { data, error };
};

const getLinkedAccountInfo = async (
  partnerId: string,
  expireUserSession: IAuthContext["expireUserSession"]
): Promise<LinkedAccountInfoDTO> => {
  const { data, error } = await fetchData<LinkedAccountInfoDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/account/links/${partnerId}`,
    options: {
      credentials: "include",
      cache: "no-cache",
    },
    expireUserSession,
  });

  return { data, error };
};

const getLinkedAccountBalance = async (
  partnerId: string,
  expireUserSession: IAuthContext["expireUserSession"]
): Promise<AccountBalanceDTO> => {
  const { data, error } = await fetchData<AccountBalanceDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/account/links/${partnerId}/balance`,
    options: {
      credentials: "include",
      cache: "no-cache",
    },
    expireUserSession,
  });

  return { data, error };
};

const unlinkAccount = async (
  partnerId: string,
  expireUserSession: IAuthContext["expireUserSession"]
): Promise<UnlinkAccountDTO> => {
  const { data, error } = await fetchData<UnlinkAccountDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/account/links/${partnerId}`,
    options: {
      method: "DELETE",
      credentials: "include",
      cache: "no-cache",
    },
    expireUserSession,
  });

  return { data, error };
};

const applyGamingCollection = async (
  partnerId: string,
  expireUserSession: IAuthContext["expireUserSession"]
): Promise<LinkNewAccountDTO> => {
  const { data, error } = await fetchData<LinkNewAccountDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/account/${partnerId}/gaming-campaign`,
    options: {
      method: "POST",
      credentials: "include",
      cache: "no-cache",
    },
    expireUserSession,
  });

  return { data, error };
};

const deleteAccountHistory = async ({
  partnerId,
  accountNumber,
}: {
  partnerId: string;
  accountNumber: string;
}): Promise<CleanRulesDTO> => {
  if (process.env.REACT_APP_ENV === "prd") {
    throw new Error("Cannot delete account history in production");
  }

  let url = `${REACT_APP_ATLAS_URL}/maintenance/partners/${partnerId.toLowerCase()}/accounts/${accountNumber}/history`;

  if (partnerId === "NECTAR") {
    url += "?usePartial=true";
  }
  const { data, error } = await fetchData<CleanRulesDTO["data"]>({
    url,
    options: {
      method: "DELETE",
    },
  });

  return { data, error };
};

export {
  getAccountInfo,
  getAccountBalance,
  getLinkPartnerExists,
  getLinkedAccountInfo,
  getLinkedAccountBalance,
  linkNewAccount,
  unlinkAccount,
  applyGamingCollection,
  deleteAccountHistory,
};
