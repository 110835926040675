import React from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from "styled-system";

import Box from "../Box";
import ErrorMessage from "../ErrorMessage";
import { InputProps } from "./Input.model";

const PrimaryLabel = styled.label<SpaceProps>`
  ${css({
    fontSize: "s",
    color: "text",
  })}
  ${space}
`;

const SecondaryLabel = styled.label<PositionProps>`
  ${css({
    color: "neutralTones.4",
    fontSize: "xxs",
  })}
  ${position}
`;

const PrimaryInput = styled.input<SpaceProps | LayoutProps | ColorProps>`
  border: 1px solid;
  ${css({
    borderColor: "neutralTones.4",
    backgroundColor: "transparent",
    fontSize: "s",
  })}
  ${space}
  ${layout}
  ${color}
`;

const SecondaryInput = styled.input<SpaceProps | LayoutProps | ColorProps>`
  border: 1px solid;
  ${css({
    borderColor: "neutralTones.3",
    backgroundColor: "transparent",
    color: "neutralTones.5",
    fontSize: "h3",
  })}
  ${space}
  ${layout}
  ${color}
`;

const Logo = styled.img<LayoutProps | PositionProps>`
  ${layout}
  ${position}
`;

const Input = React.forwardRef(
  (
    {
      type,
      id,
      label,
      value,
      onChange,
      onBlur = () => {},
      onKeyDown = () => {},
      placeholder = "",
      variant = "primary",
      imageUrl = undefined,
      alt = undefined,
      disabled = false,
      error,
      required = false,
      maxLength: maxLengthProp = undefined,
      pattern = undefined,
      "data-cy": dataCy = undefined,
      ...props
    }: InputProps,
    ref?: React.Ref<HTMLInputElement>
  ) => {
    /* To be able to apply maxLength to the input type number,
     * it's necessary to convert it to type text and since initially it was a number,
     * it's necessary to set pattern value and than apply the max-length.
     */
    let extraParams = undefined;

    if (type === "number") {
      extraParams = {
        type: "text",
        pattern: pattern || "d*",
        maxLength: maxLengthProp,
      };
    }
    return (
      <>
        {variant === "primary" && (
          <Box display="flex" flexDirection="column" {...props}>
            <PrimaryLabel htmlFor={id} mx={0} mb="xs">
              {`${label}${required ? " *" : ""}`}
            </PrimaryLabel>
            <PrimaryInput
              data-cy={dataCy}
              ref={ref}
              type={type}
              id={id}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
              disabled={disabled}
              height={48}
              pl="l"
              color={error ? "error" : "text"}
              required={required}
              {...extraParams}
            />
            {error && (
              <ErrorMessage
                message={error.message}
                additionalDetails={error.additionalDetails}
              />
            )}
          </Box>
        )}
        {variant === "secondary" && (
          <>
            <Box
              position="relative"
              height={60}
              backgroundColor="neutralTones.0"
              {...props}
            >
              <SecondaryLabel
                position="absolute"
                top={10}
                left={14}
                htmlFor={id}
              >
                {label}
              </SecondaryLabel>
              <SecondaryInput
                data-cy={dataCy}
                ref={ref}
                type={type}
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                disabled={disabled}
                width="100%"
                height="100%"
                pl={14}
                pt={20}
                pr={imageUrl ? 60 : 0}
                color={error ? "error" : "text"}
                required={required}
                {...extraParams}
              />
              {imageUrl && (
                <Logo
                  position="absolute"
                  top={8}
                  right={14}
                  height={44}
                  src={imageUrl}
                  alt={alt}
                  maxWidth={50}
                />
              )}
            </Box>
            {error && (
              <ErrorMessage
                message={error.message}
                additionalDetails={error.additionalDetails}
              />
            )}
          </>
        )}
      </>
    );
  }
);

export default Input;
