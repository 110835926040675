import React from "react";

import Box from "../Box";
import Typography from "../Typography";
import { TwoCardsSectionProps } from "./TwoCardsSection.model";

const TwoCardsSection: React.FC<TwoCardsSectionProps> = ({
  headingOne,
  greyBox = undefined,
  descriptionOne,
  headingTwo = undefined,
  descriptionTwo = undefined,
  ...props
}) => {
  const shouldRenderSecondColumn = headingTwo && descriptionTwo;

  return (
    <Box
      display="grid"
      gridTemplateRows={{ _: "1fr auto", desktop: "1fr" }}
      gridTemplateColumns={{
        _: "1fr",
        desktop: shouldRenderSecondColumn ? "1fr 0.5fr" : "1fr",
      }}
      gridGap="m"
      {...props}
    >
      <Box backgroundColor="neutralTones.0" paddingX="m" pt="xxl-2" pb="m">
        <Typography variant="title" marginBottom="xxl-2" color="text">
          {headingOne}
        </Typography>
        {greyBox && (
          <Box
            backgroundColor="neutralTones.3"
            border="2px solid"
            borderColor="neutralTones.4"
            marginBottom="xxl-2"
            px={{ _: "s", tablet: "m" }}
            pt="m"
          >
            {greyBox}
          </Box>
        )}
        <Box>{descriptionOne}</Box>
      </Box>
      {shouldRenderSecondColumn && (
        <Box
          backgroundColor="neutralTones.0"
          paddingX={{ _: "l" }}
          paddingY={{ _: "xxl-2" }}
          paddingTop={{ _: "xxl-2" }}
          paddingBottom={{ _: "xxl-2" }}
        >
          <Typography variant="h2" marginBottom="xxl-2" color="text">
            {headingTwo}
          </Typography>
          <Box>{descriptionTwo}</Box>
        </Box>
      )}
    </Box>
  );
};

export default TwoCardsSection;
