import React, { Dispatch, useState } from "react";
import Box from "../Box";
import Button from "../Button";
import Typography from "../Typography";
import styled from "styled-components";
import { SpaceProps, space } from "styled-system";
import Link from "../Link";
import { applyGamingCollection } from "../../services/Accounts";
import { usePartnershipContext } from "../../context/Partnership/PartnershipContext";
import { useAuthentication } from "../../context/Authentication/AuthenticationContext";
import { useNotifications } from "../../context/Notifications/NotificationsContext";
import { BLOCKS } from "@contentful/rich-text-types";
import { GamingCampaign } from "../../dictionaries/gamingCampaignId";

const HeroImage = styled.img`
  object-fit: cover;
`;

export const Logo = styled.img<SpaceProps>`
  height: auto;
  ${space}
`;

const Steps = styled(Box)`
  counter-reset: section;
`;

const StepInstruction = styled(Typography)`
  &::before {
    counter-increment: section;
    content: "Step " counter(section) " - ";
    font-weight: bold;
  }
`;

export interface IGamingAction {
  storageHandler: Dispatch<unknown>;
  content?: {
    logo: {
      src: string;
      alt: string;
    };
    title: string;
    message: string;
    description: {
      stepNumber: number;
      stepInstructions: string;
      requiresAuth?: boolean;
    }[];
  };
}

const contentfulContent = {
  logo: {
    src: "https://images.ctfassets.net/9vfrwuz7yomp/3rwvhKRiKkxMQVxGEXy6uD/1d8d20b8f7873ff81e675c981caef3d9/nectar_avios_logo.png",
    alt: "nectar logo",
  },
  title: "1 of 20 chances to win 50,000 Avios*",
  message: `Thank you for playing our flight simulator! Make sure you enter for your chance to win. Here's how:`,
  description: [
    {
      stepNumber: 1,
      stepInstructions:
        "Click the button below and log into your Executive Club account.",
      requiresAuth: false,
    },
    {
      stepNumber: 2,
      stepInstructions:
        "Link your Nectar and Executive Club accounts (if you haven't already) by 13th July.",
    },
    {
      stepNumber: 3,
      stepInstructions: "Click the button below to enter the draw.",
      requiresAuth: true,
    },
  ],
};

function GamingAction({
  storageHandler,
  content = contentfulContent,
}: IGamingAction) {
  const { user } = useAuthentication();
  const [isLoading, setIsLoading] = useState(false);
  const { addModal } = useNotifications();
  const { expireUserSession, handleLogin } = useAuthentication();
  const {
    data: { urlPartnerPath },
  } = usePartnershipContext();

  const handleLoginAction = () => {
    setIsLoading(true);
    handleLogin();
  };

  const handleDrawApply = async () => {
    setIsLoading(true);
    await applyGamingCollection(urlPartnerPath, expireUserSession);
    addModal({
      id: "GAMING MODAL",
      header: "You're almost there!",
      variant: "secondary",
      message: {
        nodeType: BLOCKS.DOCUMENT as const,
        data: {},
        content: [
          {
            nodeType: BLOCKS.PARAGRAPH as const,
            data: {},
            content: [
              {
                nodeType: "text" as const,
                data: {},
                value:
                  "Don't forget to link your Nectar and Executive Club accounts (if you haven't already) by 13th July for your chance to win 1 of 20 prizes of 50,000 Avios*!",
                marks: [],
              },
            ],
          },
        ],
      },
      handleClickCta: () => {
        window.sessionStorage.removeItem(GamingCampaign.BAEC_LOUNGE);
        storageHandler(null);
        setIsLoading(false);
      },
      ctaText: "Close",
      hasCloseButton: false,
    });
  };

  const filterStepsHandler = ({ requiresAuth }: { requiresAuth?: boolean }) => {
    if (user) {
      return requiresAuth === true || requiresAuth === undefined;
    }
    return requiresAuth === false || requiresAuth === undefined;
  };

  if (urlPartnerPath !== "nectar") return null;

  return (
    <Box
      display="flex"
      mb="m"
      flexDirection={{ _: "column-reverse", desktop: "row" }}
    >
      <Box backgroundColor="neutralTones.0" flex={1}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
          pt={0}
          borderBottom="1px solid"
          borderColor="neutralTones.3"
        >
          <Box
            display="flex"
            p="xl"
            flex={1}
            flexDirection="column"
            alignItems="center"
          >
            <Logo src={content.logo.src} alt={content.logo.alt} />
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              alignItems="center"
              ml={{ _: 0, tablet: "xxl-2", desktop: 0 }}
            >
              <Typography color="neutralTones.5" variant="h2" marginBottom="m">
                {content.title}
              </Typography>
              <Typography
                color="neutralTones.5"
                variant="medium"
                marginBottom="xl"
              >
                {content.message}
              </Typography>
              <Steps display="flex" flexDirection="column" marginBottom="xl">
                {content.description.filter(filterStepsHandler).map((step) => (
                  <StepInstruction
                    key={step.stepInstructions}
                    color="neutralTones.5"
                    marginBottom="s"
                  >
                    {step.stepInstructions}
                  </StepInstruction>
                ))}
              </Steps>
              <Button
                px={75}
                variant="secondary"
                onClick={user ? handleDrawApply : handleLoginAction}
                loading={isLoading}
              >
                {user ? "Enter now" : "Login"}
              </Button>
            </Box>
          </Box>
        </Box>

        <Link variant="text" to="/partner/nectar/terms-and-conditions" py="s">
          Terms and Conditions apply
        </Link>
      </Box>
      <Box width={{ _: "100%", desktop: 294 }}>
        <HeroImage
          src="https://images.ctfassets.net/9vfrwuz7yomp/59aexZVUspF6pGMR7q86eP/33438f080990a23944ba4687fb642f55/Mask_group.png"
          alt="Nectar gaming promotion image"
          height="100%"
          width="100%"
        />
      </Box>
    </Box>
  );
}

export default GamingAction;
