import { createContext, useContext } from "react";
import { BannerProps } from "../../components/Banner/Banner.model";
import { ModalProps } from "../../components/Modal/Modal.model";
import {
  ParsedBanner,
  ParsedModal,
} from "../../services/Contentful/parsers/notifications/notificationsParser.model";
export interface NotificationsContextProps {
  banners: BannerProps[];
  modal?: ModalProps;
  notificationTemplates?: ParsedBanner[];
  modalTemplates?: ParsedModal[];
  addBanner: ({
    bannerId,
  }: {
    bannerId: string;
    handleAdditionalOnClose?: () => void;
    bannerMessage?: string;
    bannerHeader?: string;
  }) => void;
  addModal: (modal: ModalProps, handleAdditionalOnClose?: () => void) => void;
  removeBanner: (id: string) => void;
  removeModal: () => void;
  getModalTemplate: (id: string) => ModalProps | undefined;
  isLoadingNotifications: boolean;
}

const NotificationsContext = createContext<NotificationsContextProps>({
  banners: [],
  modal: undefined,
  notificationTemplates: [],
  modalTemplates: [],
  addBanner: () => {},
  removeBanner: () => {},
  addModal: () => {},
  removeModal: () => {},
  getModalTemplate: () => undefined,
  isLoadingNotifications: true,
});

export const useNotifications = (): NotificationsContextProps => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      "useNotifications must be used within a NotificationsContextProvider"
    );
  }

  return context;
};

export default NotificationsContext;

NotificationsContext.displayName = "NotificationsContext";
