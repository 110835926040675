import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  BorderProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  border,
} from "styled-system";

import useActivation from "../../hooks/useActivation";
import useExpiration from "../../hooks/useExpiration";
import Box from "../Box";
import Typography from "../Typography";
import { PromotionProps } from "./Promotion.model";
import Link from "../Link";
import { trackers } from "../../utils/ga";

const StyledImage = styled.img<SpaceProps & LayoutProps & BorderProps>`
  object-fit: cover;
  ${layout}
  ${space}
  ${border}
`;
const Promotion: React.FC<PromotionProps> = ({
  header,
  title,
  description,
  image,
  startDate,
  endDate,
  descriptionImage,
  linkToPromotion,
}) => {
  const [shouldShow, setShouldShow] = useState(false);
  const isActive = useActivation(startDate);
  const hasExpired = useExpiration(endDate);

  useEffect(() => {
    setShouldShow(isActive);
  }, [isActive]);

  return (!endDate || !hasExpired) && shouldShow ? (
    <Box
      display="flex"
      flexDirection={{ _: "column", tablet: "row" }}
      backgroundColor="neutralTones.0"
      mb="l"
      px={{ _: "xxl-3" }}
      py={{ _: "xxl-2" }}
    >
      {image && (
        <StyledImage
          src={image.imageUrl}
          alt={image.imageAlt}
          width="100%"
          maxWidth={{ _: "100%", tablet: "50%", desktop: 490 }}
          height="auto"
          borderRadius={8}
        />
      )}
      <Box display="flex" flexDirection="column" m={{ _: "xl" }}>
        <Box
          display="flex"
          flexDirection="column"
          backgroundColor="neutralTones.0"
        >
          <Box
            display="flex"
            flexDirection={{ _: "column", desktop: "row" }}
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Box
              flexGrow={1}
              marginRight={{ _: "unset", desktop: "s" }}
              marginTop={{ _: "m", tablet: "unset" }}
            >
              {header && (
                <Typography variant="h2" color="text" lineHeight="m" mb="l">
                  {header}
                </Typography>
              )}
              <Typography variant="h3" color="text" lineHeight="m" mb="l">
                {title}
              </Typography>

              {descriptionImage?.imageUrl && (
                <StyledImage
                  src={descriptionImage?.imageUrl}
                  alt={descriptionImage?.imageAlt}
                  maxWidth={{ _: 254 }}
                  mb={{ _: "s" }}
                />
              )}
              <Box>{description}</Box>
              {linkToPromotion && (
                <Link
                  onClick={() =>
                    trackers.trackPromotionInteraction({
                      location: "promotion banner on partner page",
                      promotion_name: title,
                    })
                  }
                  variant="navAnchorIcon"
                  width={{ _: "100%", desktop: 220 }}
                  to={linkToPromotion.url}
                  mt={24}
                >
                  {linkToPromotion.label}
                </Link>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default Promotion;
