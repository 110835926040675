import React, { useEffect } from "react";
import css from "@styled-system/css";
import styled, { keyframes } from "styled-components";

import Box from "../../components/Box";
import Typography from "../../components/Typography";
import { fireGaEvent } from "../../utils/ga";

import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

const fly = keyframes`
  0% {
    animation-timing-function: ease-in;
    transform: rotate(-30deg);
  }
  50% {
    animation-timing-function: ease-in;
    transform: rotate(30deg);
  }
  100% {
    animation-timing-function: ease-in;
    transform: rotate(-30deg);
  }
`;

const Parachute = styled.svg`
  ${css({
    fill: getConfig<string>(config.colors, "tertiary", "tertiary"),
  })}
  animation: ${fly} 3s infinite;
`;

const Oops: React.FC = () => {
  useEffect(() => {
    fireGaEvent("404_pageview");
  }, []);

  return (
    <Box
      backgroundColor="neutralTones.0"
      width="100vw"
      flex={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Typography variant="h1" color="text" mb="m">
        Oops
      </Typography>
      <Typography variant="h2" color="text" mb="xxl-2">
        Something went wrong
      </Typography>
      <Typography variant="medium" color="neutralTones.7" mb="xxl-6">
        You are not allowed to land
      </Typography>
      <Parachute version="1.1" width="100px" viewBox="0 0 297.666 297.666">
        <g>
          <path d="M276.783,119.911c1.685-1.725,2.427-4.017,2.246-6.245h0.09C270.389,49.563,215.295,0,148.834,0C82.575,0,27.615,49.26,18.629,113.079c-0.014,0.099-0.032,0.197-0.042,0.297c-0.013,0.097-0.027,0.193-0.04,0.29h0.023c-0.141,2.188,0.606,4.423,2.26,6.112l71.586,73.066c2.338-0.762,4.829-1.179,7.417-1.179h8.357c-0.017-1-0.024-1.207-0.024-1.688c0-1.261,0.066-2.506,0.18-3.739l-73.77-75.294c6.241-12.513,19.158-21.112,34.094-21.112c13.01,0,24.49,6.527,31.359,16.481l17.594,57.565c3.68-4.43,8.27-8.077,13.48-10.636l-14.121-46.197c6.806-10.364,18.525-17.213,31.852-17.213c13.073,0,24.605,6.59,31.462,16.626l-13.95,46.695c5.225,2.538,9.829,6.176,13.532,10.6l1.221-4.088l15.986-53.512c6.882-9.863,18.306-16.321,31.245-16.321c15.137,0,28.207,8.828,34.349,21.616l-73.353,75.026c0.109,1.218,0.174,2.446,0.174,3.69c0,0.418-0.006,0.5-0.019,1.5h7.352c2.93,0,5.737,0.529,8.335,1.495L276.783,119.911z" />
          <path d="M204.833,215.666c0-4.418-3.582-8-8-8h-30.137c4.213-5,6.804-10.908,6.804-17.5c0-13.622-11.045-25.041-24.666-25.041c-13.623,0-24.668,11.032-24.668,24.654c0,6.327,2.385,12.366,6.303,16.732c-0.029,0.051-0.058,0.154-0.086,0.154h-0.005c-0.068,0-0.136,0-0.201,1H99.833c-4.418,0-8,3.582-8,8s3.582,8,8,8H126.5v66c0,4.418,3.582,8,8,8s8-3.582,8-8v-25.512c2,0.326,2.952,0.512,4.5,0.512s3.5-0.185,4.5-0.512v25.512c0,4.418,3.582,8,8,8s8-3.582,8-8v-66h29.333C201.251,223.666,204.833,220.084,204.833,215.666z" />
        </g>
      </Parachute>
    </Box>
  );
};

export default Oops;
