import React from "react";
import { useFeatureToggleContext } from "../../context/FeatureToggle/FeatureToggleProvider";
import { FeatureToggleProps } from "./FeatureToggle.model";

const FeatureToggle: React.FC<FeatureToggleProps> = ({ feature, children }) => {
  const features = useFeatureToggleContext();
  return features && features[feature] ? <>{children}</> : null;
};

export default FeatureToggle;
