const bannersIds: { [key: string]: string } = {
  LOGOUT: "LOGOUT_BANNER",
  LOGIN: "LOGIN_BANNER",
  LINK: "ACCOUNT_LINKING_BANNER",
  LINKING_ERROR: "ACCOUNT_LINKING_ERROR_BANNER",
  EXCHANGE_PENDING: "EXCHANGE_PENDING",
  EXCHANGE_SUCCESS: "EXCHANGE_SUCCESS",
  EXCHANGE_FAILED: "EXCHANGE_FAILED",
  INTERNAL_ERROR: "INTERNAL_ERROR_BANNER",
  BASE_ACCOUNT_REACHED_MAX_LINKS: "BASE_ACCOUNT_REACHED_MAX_LINKS_BANNER",
  BASE_ACCOUNT_EXCEEDED_LINKING_CAP: "BASE_ACCOUNT_EXCEEDED_LINKING_CAP_BANNER",
  ACCOUNT_TO_LINK_EXCEEDED_LINKING_CAP:
    "ACCOUNT_TO_LINK_EXCEEDED_LINKING_CAP_BANNER",
  ACCOUNT_TO_LINK_REACHED_MAX_LINKS: "ACCOUNT_TO_LINK_REACHED_MAX_LINKS_BANNER",
  ACCOUNT_TYPE_NOT_ALLOWED: "ACCOUNT_TYPE_NOT_ALLOWED",
  USER_ALREADY_SUBSCRIBED: "USER_ALREADY_SUBSCRIBED",
  AOI_SUBSCRIPTION_SUCCESS: "AOI_SUBSCRIPTION_SUCCESS",
  ACCOUNT_TO_LINK_INVALID_STATE: "ACCOUNT_TO_LINK_INVALID_STATE",
  NO_PARTNER_GENERIC_ERROR: "NO_PARTNER_GENERIC_ERROR",
  NO_PARTNER_CLEAN_RULES_SUCCESS: "NO_PARTNER_CLEAN_RULES_SUCCESS",
};

export default bannersIds;
