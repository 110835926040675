import React from "react";

import Box from "../Box";
import { LayoutProps } from "./Layout.model";

const Layout: React.FC<LayoutProps> = ({ ...props }) => {
  return (
    <Box
      maxWidth={{
        _: "100%",
        desktopLarge: "max-width",
      }}
      marginX="auto"
      paddingX={{ _: 0, tablet: "l" }}
      {...props}
    />
  );
};

export default Layout;
