import {
  ConversionSectionDTO,
  ParsedConversionSection,
} from "./conversionSectionParser.model";
import richTextToComponents from "../../../../utils/richTextToComponents";

const conversionSectionParser = (
  dto: ConversionSectionDTO["data"]
): ParsedConversionSection["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const {
    displayTitle,
    title,
    displayManualConversion,
    manualConversion,
    displayAutoConversion,
    conversionStatusTemplates,
    autoConversion,
    displayAccordion,
    accordion,
    unlinkedConversionPlaceholder,
    basePartner,
    externalPartner,
    isSwitchEnabledGlobal,
    isQuickOptionsEnabled,
    unlinkedAutoConversionPlaceholder,
    linkedAutoConversionPlaceholder,
  } = dto.items[0].fields;

  return {
    displayTitle,
    title,
    displayManualConversion,
    manualConversionBoxProps: {
      heading: manualConversion?.fields.heading,
      description: manualConversion?.fields.description,
      richTextDescription: manualConversion?.fields.richTextDescription,
      details: manualConversion?.fields.details,
      imageUrl: manualConversion?.fields.image.fields.image.fields.file.url,
      imageAlt: manualConversion?.fields.image.fields.alt,
      exchangeBox: manualConversion?.fields.exchangeBox
        ? {
            convertButtonText:
              manualConversion.fields.exchangeBox.fields.convertButtonText,
            creditLabel: manualConversion.fields.exchangeBox.fields.creditLabel,
            creditPlaceholder:
              manualConversion.fields.exchangeBox.fields.creditPlaceholder,
            debitLabel: manualConversion.fields.exchangeBox.fields.debitLabel,
            debitPlaceholder:
              manualConversion.fields.exchangeBox.fields.debitPlaceholder,
            exchangeRateText:
              manualConversion.fields.exchangeBox.fields.exchangeRateText,
            quickSelectLabel:
              manualConversion.fields.exchangeBox.fields.quickSelectLabel,
            quickSelectTotalValidLabel:
              manualConversion.fields.exchangeBox.fields
                .quickSelectTotalValidLabel,
            switchButtonAriaLabel:
              manualConversion.fields.exchangeBox.fields.switchButtonAriaLabel,
            inputErrors:
              manualConversion.fields.exchangeBox.fields.inputErrors.map(
                (error) => ({
                  type: error.fields.type,
                  message: error.fields.message,
                  additionalDetails: error.fields.additionalDetails,
                })
              ) || [],
          }
        : undefined,
    },
    displayAutoConversion,
    autoConversionBoxProps: {
      heading: autoConversion?.fields.heading,
      description: autoConversion?.fields.description,
      details: autoConversion?.fields.details,
      imageUrl: autoConversion?.fields.image.fields.image.fields.file.url,
      imageAlt: autoConversion?.fields.image.fields.alt,
      exchangeBox: autoConversion?.fields.exchangeBox
        ? {
            heading: autoConversion.fields.exchangeBox.fields.heading,
            conversionDirectionLabel:
              autoConversion.fields.exchangeBox.fields.conversionDirectionLabel,
            conversionDirectionText:
              autoConversion.fields.exchangeBox.fields.conversionDirectionText,
            conversionFrequencyLabel:
              autoConversion.fields.exchangeBox.fields.conversionFrequencyLabel,
            periodicity: autoConversion.fields.exchangeBox.fields.periodicity,
            confirmButtonText:
              autoConversion.fields.exchangeBox.fields.confirmButtonText,
            confirmButtonAriaLabel:
              autoConversion.fields.exchangeBox.fields.confirmButtonAriaLabel,
            cancelButtonText:
              autoConversion.fields.exchangeBox.fields.cancelButtonText,
            cancelButtonAriaLabel:
              autoConversion.fields.exchangeBox.fields.cancelButtonAriaLabel,
            setupButtonText:
              autoConversion.fields.exchangeBox.fields.setupButtonText,
            setupButtonAriaLabel:
              autoConversion.fields.exchangeBox.fields.setupButtonAriaLabel,
            switchButtonAriaLabel:
              autoConversion.fields.exchangeBox.fields.switchButtonAriaLabel,
            unlinkedAutoConversionPlaceholder,
            linkedAutoConversionPlaceholder:
              linkedAutoConversionPlaceholder || "",
          }
        : undefined,
    },
    conversionStatusTemplates: conversionStatusTemplates.map(
      (statusTemplate) => ({
        id: statusTemplate.fields.id,
        conversionDirectionLabel:
          statusTemplate.fields.conversionDirectionLabel,
        conversionDirectionText: statusTemplate.fields.conversionDirectionText,
        conversionFrequencyLabel:
          statusTemplate.fields.conversionFrequencyLabel,
        ctaButtonText: statusTemplate.fields.ctaButtonText,
        cancelButtonText: statusTemplate.fields.cancelButtonText || "",
        description: statusTemplate.fields.description,
        heading: statusTemplate.fields.heading,
        iconName: statusTemplate.fields.iconName,
        source: statusTemplate.fields.source,
        status: statusTemplate.fields.status,
        type: statusTemplate.fields.type,
        pairId: statusTemplate.fields.pairId,
        amountConvertedLabel: statusTemplate.fields.amountConvertedLabel,
        amountConvertedText: statusTemplate.fields.amountConvertedText,
      })
    ),
    displayAccordion,
    accordionSections: accordion?.fields.sections.map((section) => ({
      header: section.fields.header,
      body: richTextToComponents(section.fields.body),
    })),
    unlinkedConversionPlaceholder: unlinkedConversionPlaceholder,
    unlinkedAutoConversionPlaceholder: unlinkedAutoConversionPlaceholder,
    linkedAutoConversionPlaceholder: linkedAutoConversionPlaceholder || "",
    conversionManualProps: {
      basePartner: {
        logoUrl: basePartner.fields.currencyLogo.fields.image.fields.file.url,
        logoAlt: basePartner.fields.currencyLogo.fields.alt,
        id: basePartner.fields.partnerId,
      },
      externalPartner: {
        logoUrl:
          externalPartner.fields.currencyLogo.fields.image.fields.file.url,
        logoAlt: externalPartner.fields.currencyLogo.fields.alt,
        id: externalPartner.fields.partnerId,
      },
      isSwitchEnabledGlobal,
      isQuickOptionsEnabled,
    },
  };
};

export default conversionSectionParser;
