import React, { useState } from "react";

import AoiButtonsHandler from "../../AoiButtonsHandler";
import Box from "../../Box";
import Dropdown from "../../Dropdown";
import Typography from "../../Typography";
import { DropdownProps } from "../../Dropdown/Dropdown.model";
import { useAutoConversion } from "../../../context/AutoConversion/AutoConversionContext";

const FrequencyStep = (): JSX.Element => {
  const { activeStep, configPayloadState, handleActiveStep } =
    useAutoConversion();
  const conversionDropdown: DropdownProps = {
    id: "conversionDropdown",
    variant: "tertiary",
    placeholder: {
      value: "Select frequency",
    },
    options: [
      { value: "Weekly" },
      { value: "Monthly" },
      { value: "Quarterly" },
      { value: "Yearly" },
    ],
  };
  const [currentFrequency, setCurrentFrequency] = useState(
    configPayloadState.conversionFrequency
  );

  const handleFrequency = (index: number) => {
    setCurrentFrequency(conversionDropdown.options[index].value);
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" flex={1}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        flexGrow={1}
      >
        <Typography variant="small" color="neutralTones.7" fontSize="xxs">
          Conversion Frequency:
        </Typography>
        <Dropdown
          id={"dropdown"}
          placeholder={conversionDropdown.placeholder}
          options={conversionDropdown.options}
          variant={conversionDropdown.variant}
          onChange={handleFrequency}
          defaultSelectedIndex={conversionDropdown.options.findIndex(
            (option) => option.value === configPayloadState.conversionFrequency
          )}
        ></Dropdown>
      </Box>
      <AoiButtonsHandler
        nextButtonProps={{
          variant: "tertiary",
          disabled: !currentFrequency,
          padding: "s",
          onClick: () =>
            handleActiveStep(activeStep + 1, "set frequency", {
              conversionFrequency: currentFrequency,
            }),
        }}
      />
    </Box>
  );
};
export default FrequencyStep;
