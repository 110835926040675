import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { RoutesEnum } from "../../config/routes.enum";
import { getFeatureToggleContent } from "../../services/Contentful";
import { ParsedFeatureToggle } from "../../services/Contentful/parsers/featureToggle/featureToggleParser.model";
import { usePartnershipContext } from "../Partnership/PartnershipContext";
import { FeatureToggleProviderProps } from "./FeatureToggleProvider.model";

export const FeatureToggleContext = createContext(
  {} as ParsedFeatureToggle["data"]
);

export const useFeatureToggleContext = (): ParsedFeatureToggle["data"] => {
  const context = useContext(FeatureToggleContext);

  if (!context) {
    throw new Error(
      "useFeatureToggleContext must be used within a FeatureToggleProvider"
    );
  }

  return context;
};

const FeatureToggleProvider: React.FC<FeatureToggleProviderProps> = ({
  children,
}) => {
  const [features, setFeatures] = useState<ParsedFeatureToggle["data"]>({});
  const {
    data: { pairId },
  } = usePartnershipContext();

  const history = useHistory();

  useEffect(() => {
    const getFeatureToggle = async () => {
      const { data, error } = await getFeatureToggleContent(pairId);

      if (error) {
        history.push(RoutesEnum.OOPS);
      }
      setFeatures(data);
    };
    getFeatureToggle();
  }, [history, pairId, setFeatures]);

  return (
    <FeatureToggleContext.Provider value={features}>
      {children}
    </FeatureToggleContext.Provider>
  );
};

export default FeatureToggleProvider;

FeatureToggleContext.displayName = "FeatureToggleContext";
