import React from "react";
import styled from "styled-components";
import { LayoutProps, space, SpaceProps } from "styled-system";
import { css } from "@styled-system/css";

import Box from "../Box";
import Typography from "../Typography";
import {
  AoiConversionBoxProps,
  ManualConversionBoxProps,
} from "./ConversionBox.model";
import richTextToComponents from "../../utils/richTextToComponents";

// workaround as width and height from styled system don't work on img tags
const StyledImage = styled.img<
  SpaceProps &
    LayoutProps & {
      cssWidth: LayoutProps["width"];
      cssHeight: LayoutProps["height"];
    }
>`
  ${space}
  ${(props) =>
    css({
      width: props.cssWidth,
      height: props.cssHeight,
    })}
  object-fit: contain;
`;

const ConversionBox: React.FC<
  ManualConversionBoxProps | AoiConversionBoxProps
> = ({
  heading = undefined,
  imageUrl = undefined,
  imageAlt = undefined,
  description = undefined,
  details = undefined,
  children = undefined,
  id = undefined,
  richTextDescription = undefined,
}) => {
  return (
    <Box
      display="flex"
      flexDirection={{ _: "column", desktop: "row" }}
      mb="xl"
      px="xxl-2"
      pt="xxl-2"
      borderTop="1px solid #eee"
    >
      <Box
        display="flex"
        flexDirection={{ _: "column" }}
        mr={{ _: 0, desktop: "s" }}
      >
        <Box
          id={id}
          display="flex"
          flexDirection={{ _: "column", tablet: "row" }}
          mb={{ _: 0, tablet: "xl" }}
          alignItems="center"
        >
          {imageUrl && (
            <StyledImage
              cssWidth={[280, 230]}
              cssHeight={[150, 120]}
              src={imageUrl}
              alt={imageAlt}
              mb={{ _: "m", tablet: 0 }}
              mr={{ _: 0, tablet: "xxl" }}
            />
          )}
          {(heading || description) && (
            <Box data-cy="product-description">
              {heading && (
                <Typography
                  variant="xl"
                  color="text"
                  mb={{ _: "l", tablet: "m" }}
                >
                  {heading}
                </Typography>
              )}
              {richTextDescription
                ? richTextToComponents(richTextDescription)
                : description && (
                    <Typography
                      variant="medium"
                      color="neutralTones.7"
                      mb={{ _: "xxl-2", tablet: 0 }}
                    >
                      {description}
                    </Typography>
                  )}
            </Box>
          )}
        </Box>
        {details && (
          <Typography variant="medium" color="neutralTones.7" mb="m">
            {details}
          </Typography>
        )}
      </Box>
      {children && (
        <Box
          id={id}
          backgroundColor="#eee"
          minWidth={{ _: "100%", desktop: 330 }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

export default ConversionBox;
