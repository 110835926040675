import React from "react";

import Box from "../Box";
import { MainContainerProps } from "./MainContainer.model";

const MainContainer: React.FC<MainContainerProps> = ({ children, ...rest }) => {
  return (
    <Box as="main" backgroundColor="neutralTones.1" pb="xxl-2" {...rest}>
      {children}
    </Box>
  );
};

export default MainContainer;
