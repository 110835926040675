import React from "react";
import styled from "styled-components";

import Box from "../Box";
import Icon from "../Icon";
import Layout from "../Layout";
import Link from "../Link";

const StyledLink = styled(Link)`
  display: inline-flex;
  align-items: center;
`;

const ButtonContainer = styled(Box)`
  &:hover {
    background: ${(props) => props.theme.colors.secondary[0]}1A;
  }
`;

interface IGoBackButtonProps {
  label: string;
  to: string;
}
const GoBackLink = ({ label, to }: IGoBackButtonProps): JSX.Element => (
  <Box
    backgroundColor="neutralTones.1"
    pt="s"
    pb="m"
    px={{ _: "l", tablet: 0 }}
  >
    <Layout display="flex">
      <ButtonContainer px="m" py="xs" display="flex" data-cy="back-button">
        <StyledLink to={to}>
          <Icon
            type="regular"
            name="chevron-left"
            mr="xxs"
            color="secondary.0"
          />{" "}
          {label}
        </StyledLink>
      </ButtonContainer>
    </Layout>
  </Box>
);
export default GoBackLink;
