import richTextToComponents from "../../../../utils/richTextToComponents";
import {
  ParsedPrivacyAndSecurityPage,
  PrivacyAndSecurityPageDTO,
} from "./privacyAndSecurityPageParser.model";

const privacyAndSecurityPageParser = (
  dto: PrivacyAndSecurityPageDTO["data"]
): ParsedPrivacyAndSecurityPage["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const { title, policies } = dto.items[0].fields;
  return {
    title,
    policies: richTextToComponents(policies),
  };
};

export default privacyAndSecurityPageParser;
