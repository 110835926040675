import { TPartnerTrackerName } from "./tracker.model.ts";

const partnerTrackerName = (currentLocation: string): TPartnerTrackerName => {
  const domainEnvironment =
    process.env.REACT_APP_ENV === "prd" ? "prod" : "non_prod";

  const baseUrl =
    domainEnvironment === "non_prod"
      ? window.location.origin
      : (process.env.REACT_APP_BASE_URL as string);
  const path = currentLocation.split(baseUrl)[1];
  const partnerName = path
    .split("/")[2]
    ?.replace("-", "_")
    ?.toLocaleLowerCase();

  return {
    partnerName: partnerName || "partners_page",
    domainEnvironment,
  };
};

export default { partnerTrackerName };
