import fetchData from "../Api";
import { IAuthContext } from "../../context/Authentication/AuthenticationContext.model";
import {
  SubmitContactFormDTO,
  SubmitContactFormPayload,
} from "./Feedback.model";

const { REACT_APP_ATLAS_URL } = process.env;

const submitContactForm = async (
  formData: SubmitContactFormPayload,
  expireUserSession?: IAuthContext["expireUserSession"]
): Promise<SubmitContactFormDTO> => {
  const { data, error } = await fetchData<SubmitContactFormDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/feedback/contact-us`,
    options: {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
    },
    expireUserSession,
  });

  return { data, error };
};

export { submitContactForm };
