import redirects from "../../config/redirectUrls";
import fetchData from "../Api";
import { IAuthContext } from "../../context/Authentication/AuthenticationContext.model";
import {
  EnableSubscriptionData,
  SubscriptionInfoDTO,
  SubscriptionResponseDTO,
  SubscriptionStatusDTO,
} from "./Aoi.model";

const { REACT_APP_ATLAS_URL } = process.env;

const getSubscriptionStatus = async (
  partnerId: string,
  expireUserSession: IAuthContext["expireUserSession"]
): Promise<SubscriptionInfoDTO> => {
  //Grant that if the user is not subscribed, an empty object is sent as response instead of undefined
  const data = {} as SubscriptionInfoDTO["data"];

  const { data: subscriptionInfoData, error } = await fetchData<
    SubscriptionInfoDTO["data"]
  >({
    url: `${REACT_APP_ATLAS_URL}/api/aoi/subscriptions/pairId/${partnerId}`,
    options: {
      credentials: "include",
      cache: "no-cache",
    },
    expireUserSession,
  });

  Object.assign(data, subscriptionInfoData);

  return { data, error };
};

const enableSubscription = async (
  enableSubscriptionData: EnableSubscriptionData,
  pairId: string,
  partnerId: string,
  expireUserSession: IAuthContext["expireUserSession"]
): Promise<SubscriptionResponseDTO> => {
  const payload = {
    ...enableSubscriptionData,
    pairId: pairId,
  };
  const { data, error } = await fetchData<SubscriptionResponseDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/aoi/subscriptions`,
    options: {
      method: "POST",
      credentials: "include",
      cache: "no-cache",
      headers: {
        "content-type": "application/json;charset=UTF-8",
      },
      body: JSON.stringify(payload),
    },
    expireUserSession,
    params: {
      successRedirectUrl:
        redirects.enableAoiSubscription.successRedirectUrl(partnerId),
      failureRedirectUrl:
        redirects.enableAoiSubscription.failureRedirectUrl(partnerId),
    },
  });

  return { data, error };
};

const cancelSubscription = async (
  pairId: string,
  expireUserSession: IAuthContext["expireUserSession"]
): Promise<SubscriptionStatusDTO> => {
  const { data, error } = await fetchData<SubscriptionStatusDTO["data"]>({
    url: `${REACT_APP_ATLAS_URL}/api/aoi/subscriptions/pairId/${pairId}`,
    options: {
      method: "DELETE",
      credentials: "include",
      cache: "no-cache",
    },
    expireUserSession,
  });

  return { data, error };
};

export { getSubscriptionStatus, enableSubscription, cancelSubscription };
