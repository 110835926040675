import richTextToComponents from "../../../../utils/richTextToComponents";
import {
  ParsedVersatilePromotion,
  VersatilePromotionDTO,
} from "./versatilePromotionParser.model";

const versatilePromotionParser = (
  dto: VersatilePromotionDTO["data"]
): ParsedVersatilePromotion["data"] => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  return dto.items.map((promotion) => {
    const {
      header,
      description,
      image,
      startDate,
      endDate,
      linkToPromotion,
      id,
      partner,
      promotionType,
      borderRadiusForImage,
      desktopMaxWidth,
      rightAlignedImage,
      tabletImageMaxHeight,
    } = promotion.fields;

    return {
      id,
      header,
      description: richTextToComponents(description),
      image: image
        ? {
            imageUrl: image.fields.image.fields.file.url,
            imageAlt: image.fields.alt,
          }
        : undefined,
      startDate,
      endDate,
      linkToPromotion: {
        ...linkToPromotion.fields,
      },
      partner: {
        currencyLogo: {
          url: partner.fields.currencyLogo.fields.image.fields.file.url,
          alt: partner.fields.currencyLogo.fields.alt,
        },
      },
      promotionType,
      borderRadiusForImage,
      desktopMaxWidth,
      rightAlignedImage,
      tabletImageMaxHeight,
    };
  });
};

export default versatilePromotionParser;
