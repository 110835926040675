import React from "react";

import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";
import { useAutoConversion } from "../../context/AutoConversion/AutoConversionContext";
import { AoiButtonsHandlerProps } from "./AoiButtonsHandler.model";

const AoiButtonsHandler: React.FC<AoiButtonsHandlerProps> = ({
  nextButtonProps,
}) => {
  const { activeStep, handleActiveStep, isLoadingAoiInfo } =
    useAutoConversion();

  return (
    <Box
      display="flex"
      flexDirection={{ _: "column", desktop: "row" }}
      justifyContent="space-between"
      alignItems={{ _: "stretch", desktop: "center" }}
    >
      {activeStep > 0 && (
        <Button
          variant="text"
          mb={{ _: "xs", desktop: 0 }}
          onClick={() => handleActiveStep(activeStep - 1)}
          disabled={isLoadingAoiInfo}
          marginRight={{ _: 0, desktop: "xxl-2" }}
          flexGrow={1}
        >
          <Icon
            type="solid"
            height={14}
            name="chevron-left"
            marginRight="xxs"
          />
          Previous Step
        </Button>
      )}
      <Button
        display="flex"
        alignItems="center"
        justifyContent="center"
        loading={isLoadingAoiInfo}
        flexGrow={1}
        {...nextButtonProps}
      >
        Next Step
        <Icon type="solid" height={14} name="chevron-right" marginLeft="xxs" />
      </Button>
    </Box>
  );
};

export default AoiButtonsHandler;
