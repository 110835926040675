import richTextToComponents from "../../../../utils/richTextToComponents";
import { HomepageDTO, ParsedHomepage } from "./homepageParser.model";

const homepageParser = (dto: HomepageDTO["data"]): ParsedHomepage["data"] => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const { title, subtitle, partners, threeSectionTitle } = dto.items[0].fields;

  return {
    title,
    subtitle,
    partners: partners.map((partner) => ({
      id: partner.fields.id,
      imageUrl: partner.fields.imageUrl.fields.image.fields.file.url,
      imageAlt: partner.fields.imageAlt,
      logoUrl: partner.fields.logoUrl.fields.image.fields.file.url,
      logoAlt: partner.fields.logoAlt,
      partnerId: partner.fields.partnerId,
      partnerName: partner.fields.partnerName,
      exchangeRateDescription: richTextToComponents(
        partner.fields.exchangeRateDescription
      ),
      partnershipDescription: richTextToComponents(
        partner.fields.partnershipDescription
      ),
      ctaText: partner.fields.ctaText,
      ctaUrl: partner.fields.ctaUrl,
    })),
    threeSectionTitle,
  };
};

export default homepageParser;
