import {
  ParsedThreeColumnsSection,
  ThreeColumnsSectionsDTO,
} from "./threeColumnsSectionParser.model";

const threeColumnsSectionParser = (
  dto: ThreeColumnsSectionsDTO["data"]
): ParsedThreeColumnsSection["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const { header, sections } = dto.items[0].fields;

  return {
    header,
    sections: sections.map((section) => ({
      imageUrl: section.fields.image.fields.image.fields.file.url,
      imageAlt: section.fields.image.fields.alt,
      title: section.fields.title,
      description: section.fields.description,
    })),
  };
};

export default threeColumnsSectionParser;
