import React from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import { space, SpaceProps, variant } from "styled-system";

import variants from "../Typography/variants";
import Box from "../Box";
import Typography from "../Typography";
import Link from "../Link";
import { IPartnerCardProps } from "./PartnerCard.model";
import { TTypographyVariants } from "../Typography/Typography.model";
import { trackers } from "../../utils/ga";

const HeroImage = styled.img<SpaceProps>`
  object-fit: cover;
`;

const Logo = styled.img<SpaceProps>`
  ${space}
  max-width: 140px;
  height: auto;
`;

const PartnerDescription = styled(Box)<{ variant?: TTypographyVariants }>`
  margin-top: auto;

  > p {
    margin-bottom: 0;
    ${variant({ variants })}
    ${css({
      color: "neutralTones.7",
    })}
  }
`;

const PartnerRateInfo = styled(PartnerDescription)`
  > p {
    ${css({
      lineHeight: "s",
      textAlign: ["center", "center", "left"],
    })}
  }
`;

function PartnerCard({ partnerInfo }: IPartnerCardProps): JSX.Element {
  return (
    <Box
      backgroundColor="neutralTones.0"
      display="flex"
      flexDirection="column"
      data-partner-card={partnerInfo.partnerId}
    >
      <>
        <Box height={336}>
          <HeroImage
            src={partnerInfo.imageUrl}
            alt={partnerInfo.imageAlt}
            width="100%"
            height="100%"
          />
        </Box>

        <Box p={"xl"} pb="xxl-2" display="flex" flexDirection="column" flex={1}>
          <Box
            display="flex"
            flexDirection={{
              _: "column-reverse",
              desktop: "row",
            }}
            alignItems={{
              _: "center",
              desktop: "flex-start",
            }}
            justifyContent="space-between"
            mb="m"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems={{
                _: "center",
                desktop: "flex-start",
              }}
            >
              <Typography
                variant="title"
                mb="xs"
                color="text"
                textAlign={{
                  _: "center",
                  desktop: "left",
                }}
                lineHeight="xl"
              >
                {partnerInfo.partnerName}
              </Typography>
              <PartnerRateInfo variant="subtitle">
                {partnerInfo.exchangeRateDescription}
              </PartnerRateInfo>
            </Box>
            <Logo
              src={partnerInfo.logoUrl}
              alt={partnerInfo.logoAlt}
              height={48}
              ml={{ _: 0, desktop: "xs" }}
              mb={{ _: "m", desktop: 0 }}
            />
          </Box>
          <PartnerDescription variant="medium" mb="xl">
            {partnerInfo.partnershipDescription}
          </PartnerDescription>
          <Link
            onClick={() =>
              trackers.trackNavigation(`${partnerInfo.partnerName} card`)
            }
            to={partnerInfo.ctaUrl}
            variant="primary"
            useAnchor={true}
          >
            {partnerInfo.ctaText}
          </Link>
        </Box>
      </>
    </Box>
  );
}

export default PartnerCard;
