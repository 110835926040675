import trackingParams from "./trackingParams";
import { TAdditionalVariables, TGaEvents } from "./tracker.model.ts";

declare global {
  interface Window {
    dataLayer?: unknown[];
  }
}

const fireGaEvent = (
  eventName: TGaEvents,
  additionalVariables?: TAdditionalVariables
): void => {
  const { partnerTrackerName } = trackingParams;
  const { domainEnvironment: domain_environment, partnerName: partner_name } =
    partnerTrackerName(location.href);

  let variables: TAdditionalVariables & {
    event: TGaEvents;
    custom_timestamp: number;
    domain_environment: string;
    partner_name: string;
  } = {
    event: eventName,
    custom_timestamp: Date.now(),
    domain_environment,
    partner_name,
    point_of_origin: additionalVariables?.point_of_origin || "AVIOS",
  };

  if (additionalVariables) {
    variables = {
      ...variables,
      ...additionalVariables,
    };
  }

  window.dataLayer?.push(variables);
};

export default fireGaEvent;
