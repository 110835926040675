import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";

import { RoutesEnum } from "../../config/routes.enum";
import { getPairPartnersContent } from "../../services/Contentful";
import { getPairConfiguration } from "../../services/Pairs";
import { pairIds } from "./PairIds";
import {
  Action,
  IPartnershipContext,
  Partner,
  Partners,
  PartnershipContextProviderProps,
} from "./PartnershipContext.model";
import { getPartnerFromUrl } from "../../utils/routing";
import { useLocation } from "react-router-dom";

export const PartnershipContext = createContext({} as IPartnershipContext);

export const usePartnershipContext = (): IPartnershipContext => {
  const context = useContext(PartnershipContext);

  if (!Object.keys(context).length) {
    throw new Error(
      "usePartnershipContext must be used within a PartnershipContextProvider"
    );
  }

  return context;
};

function partnershipReducer(
  state: IPartnershipContext["data"],
  action: Action
) {
  switch (action.type) {
    case "set partners config":
      return {
        ...state,
        partnersConfiguration: action.payload.partnersConfiguration,
        rate: action.payload.rate,
        partners: state.partners.map((partner) => {
          const partnerConfigWithPartnerId =
            action.payload?.partnersConfiguration[partner.id];

          return {
            ...partner,
            isDebitEnabled: partnerConfigWithPartnerId
              ? partnerConfigWithPartnerId?.operations?.DEBIT
              : false,
            points: {
              min: partnerConfigWithPartnerId
                ? partnerConfigWithPartnerId?.minimum.points.redemption
                : 0,
              max: partnerConfigWithPartnerId
                ? partnerConfigWithPartnerId?.maximum?.points?.redemption
                : 0,
              increment: partnerConfigWithPartnerId
                ? partnerConfigWithPartnerId?.minimum?.points?.increment
                : 0,
            },
            rate: partnerConfigWithPartnerId
              ? partnerConfigWithPartnerId?.rate
              : 0,
          };
        }),
      };
    case "set pair id":
      return {
        ...state,
        pairId: action.payload.pairId,
      };
    case "set url partner path":
      return {
        ...state,
        urlPartnerPath: action.payload.urlPartnerPath,
      };
    case "set partners ids":
      const { basePartnerId, externalPartnerId } = action.payload;
      return {
        ...state,
        basePartnerId,
        externalPartnerId,
      };
    case "set partners content":
      return {
        ...state,
        partners: state.partners.map((partner) => {
          const payloadPartner = action.payload.partners.filter(
            ({ id }: { id: string }) => partner.id === id
          )[0];
          return {
            ...partner,
            ...payloadPartner,
          };
        }),
      };
    case "set partners currency":
      return {
        ...state,
        partners: state.partners.map((partner) => {
          if (!(partner.id === action.payload.partnerId)) return { ...partner };

          return {
            ...partner,
            currencyName: action.payload.currencyName,
          };
        }),
      };
    default:
      return state;
  }
}

const PartnershipContextProvider: React.FC<PartnershipContextProviderProps> = ({
  children,
}) => {
  const location = useLocation();

  const initialStateFn = useCallback((): IPartnershipContext["data"] => {
    const urlPartner = getPartnerFromUrl() as Partners;
    const searchPartnerId = new URLSearchParams(location.search).get(
      "partnerId"
    ) as Partners;

    const partnerId = urlPartner || searchPartnerId;

    const [firstPartnerId, secondPartnerId] =
      pairIds[partnerId]?.split("_") ?? [];

    return {
      pairId: pairIds[partnerId] ?? "",
      partners: [
        {
          id: firstPartnerId,
        },
        {
          id: secondPartnerId,
        },
      ],
      basePartnerId: undefined,
      externalPartnerId: undefined,
      urlPartnerPath: partnerId,
      partnersConfiguration: undefined,
      rate: undefined,
    };
  }, [location.search]);
  const [data, dispatch] = useReducer(partnershipReducer, initialStateFn());

  const getPairConfigData = useCallback(async (pairId: string) => {
    if (!pairId) {
      return;
    }
    const { data: pairConfigData, error: pairConfigError } =
      (await getPairConfiguration(pairId)) ?? {};
    if (pairConfigError) {
      window.location.assign(RoutesEnum.OOPS);
      return;
    }
    dispatch({
      type: "set partners config",
      payload: pairConfigData,
    });
  }, []);

  const getPartners = useCallback(
    (partnerId: string) => {
      const partnersInfo = {} as Record<
        "creditPartner" | "debitPartner",
        Partner
      >;

      data.partners.forEach((current) => {
        if (current.id === partnerId) {
          Object.assign(partnersInfo, { creditPartner: current });
        } else {
          Object.assign(partnersInfo, {
            debitPartner: current,
          });
        }
      });

      return partnersInfo;
    },
    [data.partners]
  );

  const setPartnerData = useCallback(
    (basePartnerId: string, externalPartnerId: string) => {
      dispatch({
        type: "set partners ids",
        payload: {
          basePartnerId,
          externalPartnerId,
        },
      });
    },
    []
  );

  const setPartnerCurrency = useCallback(
    (partnerId: string, currencyName?: string) => {
      dispatch({
        type: "set partners currency",
        payload: {
          partnerId,
          currencyName,
        },
      });
    },
    []
  );

  const updatePairId = useCallback((pairId: string) => {
    dispatch({
      type: "set pair id",
      payload: {
        pairId,
      },
    });
  }, []);

  const updatePartnerPath = useCallback((urlPartnerPath: string) => {
    dispatch({
      type: "set url partner path",
      payload: {
        urlPartnerPath,
      },
    });
  }, []);

  useEffect(() => {
    const { pairId } = initialStateFn();
    const getPairPartners = async () => {
      const { data, error } = await getPairPartnersContent(pairId);
      if (error) {
        window.location.assign(RoutesEnum.OOPS);
        return;
      }
      dispatch({
        type: "set partners content",
        payload: {
          partners: data?.partners,
        },
      });
    };
    pairId && getPairPartners();
  }, [initialStateFn]);

  useEffect(() => {
    const { pairId } = initialStateFn();
    const urlPartnerPath = getPartnerFromUrl() as Partners;

    updatePairId(pairId);
    updatePartnerPath(urlPartnerPath);
  }, [location.pathname, initialStateFn, updatePairId, updatePartnerPath]);

  return (
    <PartnershipContext.Provider
      value={{
        data,
        setPartnerData,
        setPartnerCurrency,
        getPartners,
        getPairConfigData,
      }}
    >
      {children}
    </PartnershipContext.Provider>
  );
};

export default PartnershipContextProvider;

PartnershipContext.displayName = "PartnershipContext";
