import React from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import { layout, LayoutProps } from "styled-system";

import Box from "../Box";
import Typography from "../Typography";
import { AccountInfoProps } from "./AccountInfo.model";
import {
  formatNumberAsLocalisedString,
  formatStringAsCapitalizedString,
} from "../../utils/strings";

import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";

const Container = styled(Box)`
  border: 1px solid;
  ${css({
    borderColor: "neutralTones.3",
  })}
`;

const StyledImage = styled.img<LayoutProps>`
  ${layout}
`;

const BoxWithBorder = styled(Box)`
  border-right: 1px solid;
  ${css({
    borderColor: "neutralTones.3",
  })}
`;

const AccountInfo: React.FC<AccountInfoProps> = ({
  logoUrl,
  logoAlt,
  balance,
  currencyName,
  membershipLabel,
  membershipNumber,
  unavailableBalanceLabel,
  ...props
}) => {
  return (
    <Container display="flex" alignItems="center" height={56} pl="m" {...props}>
      <StyledImage
        src={logoUrl}
        alt={logoAlt}
        width={37}
        data-cy="partner-logo"
      />
      <Box display="flex" flex={1}>
        <BoxWithBorder
          display="flex"
          flexDirection="column"
          justifyContent="center"
          flex={1}
          paddingLeft="s"
          paddingRight="xs"
        >
          <Typography
            id="currencyName"
            variant="medium"
            data-cy="partner-currency"
            color={getConfig<string>(
              config.accountInfo,
              "color",
              "neutralTones.7"
            )}
            whiteSpace="nowrap"
          >
            {formatStringAsCapitalizedString(currencyName)}
          </Typography>
          <Typography
            id="currencyValue"
            data-cy="balance"
            variant="medium"
            color={getConfig<string>(
              config.accountInfo,
              "color",
              "neutralTones.7"
            )}
            whiteSpace="nowrap"
          >
            {balance !== undefined
              ? formatNumberAsLocalisedString(balance)
              : unavailableBalanceLabel}
          </Typography>
        </BoxWithBorder>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          flex={1}
          paddingLeft="s"
          paddingRight="xs"
        >
          <Typography
            variant="medium"
            color={getConfig<string>(
              config.accountInfo,
              "color",
              "neutralTones.7"
            )}
            whiteSpace="nowrap"
            data-cy="membership"
          >
            {membershipLabel}
          </Typography>
          <Typography
            id="membershipNumber"
            variant="medium"
            color={getConfig<string>(
              config.accountInfo,
              "color",
              "neutralTones.7"
            )}
            whiteSpace="nowrap"
            data-cy="membership-number"
          >
            {membershipNumber}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default AccountInfo;
