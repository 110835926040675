import React, { useCallback, useEffect, useState } from "react";

import NotificationsContext from "./NotificationsContext";
import { getNotificationsContent } from "../../services/Contentful";
import { BannerProps } from "../../components/Banner/Banner.model";

import {
  ParsedBanner,
  ParsedModal,
} from "../../services/Contentful/parsers/notifications/notificationsParser.model";

import { ModalProps } from "../../components/Modal/Modal.model";
import { usePartnershipContext } from "../Partnership/PartnershipContext";
import { NotificationsProviderProps } from "./NotificationsProvider.model";
import { createSecureRandomKey } from "../../utils/security";

const NotificationsProvider: React.FC<NotificationsProviderProps> = ({
  children,
}) => {
  const [notificationTemplates, setNotificationTemplates] = useState<
    ParsedBanner[] | undefined
  >(undefined);
  const [modalTemplates, setModalTemplates] = useState<
    ParsedModal[] | undefined
  >(undefined);
  const [banners, setBanners] = useState<BannerProps[]>([]);
  const [modal, setModal] = useState<ModalProps | undefined>(undefined);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(true);
  const {
    data: { pairId },
  } = usePartnershipContext();

  const addBanner = useCallback(
    ({
      bannerId,
      handleAdditionalOnClose = () => {},
      bannerMessage,
      bannerHeader,
    }: {
      bannerId: string;
      handleAdditionalOnClose?: () => void;
      bannerMessage?: string;
      bannerHeader?: string;
    }) => {
      const newNotification = notificationTemplates?.find(
        (notification) => notification.id === bannerId
      );

      if (newNotification) {
        setBanners((prevNotifications) => [
          ...prevNotifications,
          {
            ...newNotification,
            id: `${newNotification.id}_${createSecureRandomKey()}`,
            handleAdditionalOnClose,
            header: bannerHeader ?? newNotification.header,
            message: bannerMessage ?? newNotification.message,
          },
        ]);
      }
    },
    [notificationTemplates]
  );

  const removeBanner = useCallback((id: string) => {
    setBanners((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  }, []);

  const addModal = useCallback((modal: ModalProps, callback?: () => void) => {
    setModal({
      ...modal,
      handleAdditionalOnClose: () => {
        modal.handleAdditionalOnClose?.();
        callback?.();
      },
    });
  }, []);

  const removeModal = () => {
    setModal(undefined);
  };

  const getModalTemplate = useCallback(
    (modalId: string): ParsedModal | undefined => {
      return modalTemplates?.find((modal) => modal.id === modalId);
    },
    [modalTemplates]
  );

  useEffect(() => {
    const getNotifications = async (pairId: string) => {
      const { data, error } = await getNotificationsContent(pairId);

      if (error) {
        setBanners([
          {
            variant: "error",
            header: "Oops!",
            message: "Something went wrong",
            id: "GENERIC_ERROR_MESSAGE",
          },
        ]);
      }

      if (data) {
        setNotificationTemplates(data.banners);
        setModalTemplates(data.modals);
      }
      setIsLoadingNotifications(false);
    };

    getNotifications(pairId);
  }, [pairId]);

  return (
    <NotificationsContext.Provider
      value={{
        banners,
        modal,
        notificationTemplates,
        modalTemplates,
        addBanner,
        removeBanner,
        addModal,
        removeModal,
        getModalTemplate,
        isLoadingNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
