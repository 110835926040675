type TTransactionStatusIds = {
  [key: string]: string;
};
const transactionStatusIds: TTransactionStatusIds = {
  COMPLETED: "COMPLETED",
  CANCELED: "CANCELED",
  INVALID: "INVALID",
  REJECTED: "REJECTED",
  MANUAL_RESOLUTION: "MANUAL_RESOLUTION",
  PARTNER_AUTHENTICATION_FAILED: "PARTNER_AUTHENTICATION_FAILED",
  INTERNAL_SERVER_ERROR: "INTERNAL_SERVER_ERROR",
  SESSION_NOT_FOUND: "SESSION_NOT_FOUND",
  UNAUTHORIZED: "UNAUTHORIZED",
};

const transactionErrorStatusIds = [
  transactionStatusIds.REJECTED,
  transactionStatusIds.CANCELED,
  transactionStatusIds.INVALID,
  transactionStatusIds.MANUAL_RESOLUTION,
];

const systemErrorStatusIds = [
  transactionStatusIds.PARTNER_AUTHENTICATION_FAILED,
  transactionStatusIds.INTERNAL_SERVER_ERROR,
  transactionStatusIds.SESSION_NOT_FOUND,
  transactionStatusIds.UNAUTHORIZED,
];

const transactionErrorsRulesIds: TTransactionStatusIds = {
  RULES_INVALID_ACCOUNT_NAME: "MATCHING_ERRORS",
};

export {
  systemErrorStatusIds,
  transactionStatusIds,
  transactionErrorStatusIds,
  transactionErrorsRulesIds,
};
