export enum RoutesEnum {
  HOME = "/partners",
  LANDING = "/partner/:id",
  FAQ = "/partner/:id/faq",
  COOKIES = "/partner/:id/cookies",
  TERMS_AND_CONDITIONS = "/partner/:id/terms-and-conditions",
  PRIVACY_AND_SECURITY = "/partner/:id/privacy-and-security",
  EXCHANGE = "/partner/:id/exchange",
  CONTACT = "/partner/:id/contact-us",
  OOPS = "/oops",
  LOGOUT = "/logout",
  CLEAN_RULES = "/clean-rules",
}
