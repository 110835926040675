import React from "react";
import styled, { keyframes } from "styled-components";
import { variant } from "styled-system";
import { LoadingProps, Size } from "./Loading.model";

import variants from "./variants";

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div<{ size: Size }>`
  border-radius: 50%;
  animation: ${spinAnimation} 0.8s linear infinite;
  ${variant(variants)};
`;

const Loading: React.FC<LoadingProps> = ({ size = "small" }) => (
  <Loader size={size} />
);

export default Loading;
