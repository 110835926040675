import React from "react";
import styled from "styled-components";
import { layout, LayoutProps, position, PositionProps } from "styled-system";

import Box from "../Box";
import Icon from "../Icon";
import { ControlersProps } from "./Controllers.model";

const Controler = styled.button<PositionProps & LayoutProps>`
  cursor: pointer;
  border: none;
  transform: translateY(-50%);
  z-index: 2;
  background-color: transparent;
  transition: opacity 0.5s;

  &:hover {
    opacity: 0.7;
  }

  ${position}
  ${layout}
`;

const Controllers: React.FC<ControlersProps> = ({
  handleNext,
  handlePrevious,
}) => (
  <>
    <Controler
      position="absolute"
      left="-20px"
      top="calc(50% - 18px)"
      onClick={handlePrevious}
      width={40}
      height={50}
    >
      <Box
        width="100%"
        height="100%"
        position="absolute"
        top={0}
        left={0}
        opacity={0.1}
        backgroundColor="neutralTones.6"
      />
      <Icon type="solid" name="chevron-left" color="secondary.0" />
    </Controler>
    <Controler
      position="absolute"
      right="-20px"
      top="calc(50% - 18px)"
      onClick={handleNext}
      width={40}
      height={50}
    >
      <Box
        width="100%"
        height="100%"
        position="absolute"
        top={0}
        left={0}
        opacity={0.1}
        backgroundColor="neutralTones.6"
      />
      <Icon type="solid" name="chevron-right" color="secondary.0" />
    </Controler>
  </>
);

export default Controllers;
