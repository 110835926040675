import React, { useState } from "react";
import styled from "styled-components";
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
} from "styled-system";
import { useHistory } from "react-router";
import { useFormik } from "formik";

import MainContainer from "../../components/MainContainer";
import LoadingPage from "../../components/LoadingPage";
import Box from "../../components/Box";
import Layout from "../../components/Layout";
import Dropdown from "../../components/Dropdown";
import Typography from "../../components/Typography";
import Input from "../../components/Input";
import Textarea from "../../components/Textarea";
import Button from "../../components/Button";
import bannersIds from "../../dictionaries/bannersIds";
import { useNotifications } from "../../context/Notifications/NotificationsContext";
import { useGetContactUsPageContent } from "../../services/Contentful";
import { RoutesEnum } from "../../config/routes.enum";
import { submitContactForm } from "../../services/Feedback";
import { usePartnershipContext } from "../../context/Partnership/PartnershipContext";
import { fireGaEvent } from "../../utils/ga";
import config from "../../build-config.json";

interface ValidationErrorObject {
  [key: string]: string | undefined;
}

const StyledForm = styled.form<LayoutProps & FlexboxProps & SpaceProps>`
  ${layout}
  ${flexbox}
  ${space}
`;

const Contact: React.FC = () => {
  const [queryType, setQueryType] = useState<string>("");
  const [tierStatus, setTierStatus] = useState<string>("");
  const [message, setMessage] = useState("");
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const { addBanner } = useNotifications();
  const {
    data: { pairId, urlPartnerPath },
  } = usePartnershipContext();
  const { opCoId } = config;

  const { data, error } = useGetContactUsPageContent(pairId);
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      membershipNr: undefined,
    },
    validate: (values) => {
      const errors: ValidationErrorObject = {};
      if (!values.name) {
        errors.name = data?.userName.errors.required?.message;
      }

      if (!values.membershipNr) {
        errors.membershipNr = data?.membershipNumber.errors.required?.message;
      }

      if (!values.email) {
        errors.email = data?.emailAddress.errors.required?.message;
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = data?.emailAddress.errors.format?.message;
      }
      return errors;
    },
    onSubmit: async () => {
      const { name, membershipNr: membershipNumber, email } = formik.values;
      const payload = {
        partnerId: urlPartnerPath,
        name,
        membershipNumber: `${membershipNumber}`,
        email,
        tierStatus: tierStatus,
        freeText: message,
        queryType,
        IAGPartnerId: opCoId,
      };
      const { data: success, error } = await submitContactForm(payload);

      if (error || !success) {
        addBanner({ bannerId: bannersIds.INTERNAL_ERROR });
        setSubmitSuccessful(false);
        return;
      }

      if (success) {
        setSubmitSuccessful(true);

        fireGaEvent("contact_us", {
          user_action: `${urlPartnerPath} contact us`,
          form_subject: queryType,
          memberID: membershipNumber,
        });

        return;
      }
    },
  });

  const handleNumbersOnly = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(e.target.id, e.target.value.replace(/[^0-9]/g, ""));
  };

  const handleQueryType = (index: number) => {
    if (!data?.issueDropdown.options) {
      return;
    }

    setQueryType(data.issueDropdown.options[index].value);
  };

  const handleTierStatus = (index: number) => {
    if (!data?.tierStatus.options) {
      return;
    }
    setTierStatus(data.tierStatus.options[index].value);
  };

  const handleMessage = (evt: React.FormEvent<HTMLTextAreaElement>) => {
    setMessage(evt.currentTarget.value);
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    const excludeKeys = ["+", "-", "e", "E"];
    if (excludeKeys.includes(evt.key)) {
      evt.preventDefault();
    }
  };

  const resetForm = (evt: React.FormEvent) => {
    setSubmitSuccessful(false);
    setMessage("");
    setTierStatus("");
    formik.handleReset(evt);
  };

  if (!data && !error) {
    return <LoadingPage />;
  }

  if (error) {
    history.push(RoutesEnum.OOPS);
  }

  const nameError =
    formik.touched.name && formik.errors.name
      ? { message: formik.errors.name }
      : undefined;
  const membershipNumberError =
    formik.touched.membershipNr && formik.errors.membershipNr
      ? { message: formik.errors.membershipNr }
      : undefined;
  const emailError =
    formik.touched.email && formik.errors.email
      ? { message: formik.errors.email }
      : undefined;
  const isSubmitButtonDisabled =
    [
      formik.values.name,
      formik.values.membershipNr,
      formik.values.email,
      tierStatus,
      message,
    ].some((value) => !value) ||
    [nameError, membershipNumberError, emailError].some((value) => value);
  return data ? (
    <MainContainer>
      <Layout>
        <Box
          backgroundColor="neutralTones.0"
          py="xxl-2"
          px={{ _: "m", desktop: "xxl-4" }}
        >
          <Typography
            variant="h1"
            lineHeight="m"
            marginBottom="xl"
            color="text"
          >
            {data?.title}
          </Typography>
          {data?.description}
          <Dropdown
            id="contact-us"
            variant={data.issueDropdown.variant}
            placeholder={data.issueDropdown.placeholder}
            options={data.issueDropdown.options}
            onChange={handleQueryType}
            width={{ _: "100%", desktop: 450 }}
            mb="s"
          />
          {queryType && !submitSuccessful && (
            <StyledForm
              maxWidth={980}
              display="flex"
              flexDirection="column"
              margin="0 auto"
              onSubmit={formik.handleSubmit}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                mb="s"
                flexDirection={{ _: "column", desktop: "row" }}
              >
                <Input
                  id="name"
                  type={data.userName.type}
                  variant={data.userName.variant}
                  label={data.userName.label}
                  required={data.userName.required}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  width={{ _: "100%", desktop: 450 }}
                  error={nameError}
                />
                <Input
                  id="membershipNr"
                  type={data.membershipNumber.type}
                  variant={data.membershipNumber.variant}
                  label={data.membershipNumber.label}
                  required={data.membershipNumber.required}
                  value={formik.values.membershipNr}
                  onChange={handleNumbersOnly}
                  onBlur={formik.handleBlur}
                  onKeyDown={handleKeyDown}
                  width={{ _: "100%", desktop: 450 }}
                  error={membershipNumberError}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                mb="s"
                flexDirection={{ _: "column", desktop: "row" }}
              >
                <Input
                  id="email"
                  type={data.emailAddress.type}
                  variant={data.emailAddress.variant}
                  label={data.emailAddress.label}
                  required={data.emailAddress.required}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  width={{ _: "100%", desktop: 450 }}
                  error={emailError}
                />
                <Dropdown
                  id="tier-status"
                  variant={data.tierStatus.variant}
                  label={data.tierStatus.label}
                  required={data.tierStatus.required}
                  placeholder={data.tierStatus.placeholder}
                  options={data.tierStatus.options}
                  onChange={handleTierStatus}
                  width={{ _: "100%", desktop: 450 }}
                />
              </Box>
              <Textarea
                id="message"
                label={data.message.label}
                required={data.message.required}
                value={message}
                onChange={handleMessage}
                mb="s"
              />
              <Button
                variant="secondary"
                type="submit"
                margin="0 auto"
                disabled={isSubmitButtonDisabled}
              >
                Submit
              </Button>
            </StyledForm>
          )}
          {submitSuccessful && (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              mt="xxl-2"
            >
              <Typography variant="h3" mb="s">
                {data.successMessage}
              </Typography>
              <Button variant="secondary" onClick={resetForm}>
                {data.resetFormButtonText}
              </Button>
            </Box>
          )}
        </Box>
      </Layout>
    </MainContainer>
  ) : (
    <></>
  );
};

export default Contact;
