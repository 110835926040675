import richTextToComponents from "../../../../utils/richTextToComponents";
import { ParsedTooltips, TooltipsDTO } from "./tooltipsParser.model";

const tooltipsParser = (
  dto: TooltipsDTO["data"]
): ParsedTooltips["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const { tooltips } = dto.items[0].fields;

  return tooltips.map((tooltip) => ({
    id: tooltip.fields.id,
    tooltipTitle: tooltip.fields.tooltipTitle || "",
    tooltipMessage: richTextToComponents(tooltip.fields.tooltipMessage),
  }));
};

export default tooltipsParser;
