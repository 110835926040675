import React, { useState } from "react";
import styled from "styled-components";
import css from "@styled-system/css";
import {
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  variant,
} from "styled-system";

import Typography from "../Typography";
import Icon from "../Icon";
import { AccordionProps, TAccordionVariant } from "./Accordion.model";
import { bodyVariants, variants } from "./variants";

const StyledAccordionSectionHeader = styled.button<
  LayoutProps & FlexboxProps & { variant: TAccordionVariant }
>`
  background: none;
  cursor: pointer;
  ${css({
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "transparent",
    fontSize: "s",
    lineHeight: "xxs",
  })}
  ${variant({ variants })}

  &+& {
    ${css({
      borderTopColor: "transparent",
    })}
  }
  ${layout}
  ${flexbox}
`;

const StyledAccordionSectionBody = styled.div<
  SpaceProps & {
    variant: TAccordionVariant;
  }
>`
  ${css({
    color: "neutralTones.7",
    borderWidth: "1px",
    borderStyle: "solid",
    borderTop: "none",
  })}
  ${variant({ variants: bodyVariants })}
  
  p {
    ${css({
      fontSize: "xs",
      lineHeight: "xxs",
    })}
  }

  ol {
    ${css({
      marginY: "m",
      listStylePosition: "inside",
      paddingLeft: 0,
    })}

    li {
      ${css({
        marginBottom: "m",
      })}

      ol {
        ${css({
          marginLeft: "m",
        })}
      }

      p {
        ${css({
          display: "contents",
        })}
      }
    }
  }
  ${space}
`;

const Accordion: React.FC<AccordionProps> = ({
  sections,
  defaultOpenSectionIndex = -1,
  canOpenMultiple = false,
  variant = "default",
}) => {
  const [openSections, setOpenSections] = useState<Set<number | undefined>>(
    new Set([defaultOpenSectionIndex])
  );

  const handleClick = (sectionIndex: number) => {
    const isOpen = openSections.has(sectionIndex);

    if (isOpen) {
      const newSet = new Set(openSections);
      newSet.delete(sectionIndex);

      setOpenSections(newSet);
      return;
    }

    if (canOpenMultiple) {
      const newSet = new Set(openSections);
      newSet.add(sectionIndex);

      setOpenSections(newSet);
      return;
    }

    setOpenSections(new Set([sectionIndex]));
  };

  const isOpen = (sectionIndex: number) => openSections.has(sectionIndex);

  return (
    <>
      {sections.map((section, index) => (
        <React.Fragment key={index}>
          <StyledAccordionSectionHeader
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => handleClick(index)}
            aria-expanded={isOpen(index)}
            height="xxl-5"
            variant={variant}
          >
            <Typography variant="large" color="text" margin="0">
              {section.header}
            </Typography>
            <Icon
              type="regular"
              name="chevron-down"
              color="text"
              rotate={isOpen(index) ? "180deg" : "0"}
            />
          </StyledAccordionSectionHeader>
          {isOpen(index) && (
            <StyledAccordionSectionBody
              color="neutralTones.7"
              pt="xxl-2"
              pb="m"
              variant={variant}
            >
              {section.body}
            </StyledAccordionSectionBody>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default Accordion;
