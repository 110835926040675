import React from "react";

import Box from "../Box";
import ConversionManual from "../ConversionManual";
import ConversionStatus from "../ConversionStatus";
import Typography from "../Typography";
import { useAuthentication } from "../../context/Authentication/AuthenticationContext";
import { useTransaction } from "../../context/Transaction/TransactionContext";
import { ConversionManualSectionProps } from "./ConversionManualSection.model";
import {
  transactionErrorStatusIds,
  transactionStatusIds,
} from "../../dictionaries/transactionStatusIds";

const ConversionManualSection: React.FC<ConversionManualSectionProps> = ({
  unlinkedConversionPlaceholder,
  conversionManualProps,
  exchangeContent,
  conversionStatusTemplates,
}) => {
  const { transaction, clearTransactionInfo } = useTransaction();
  const { user } = useAuthentication();

  const parseTransactionStatus = (status?: string) => {
    if (!status) return;
    if (status === transactionStatusIds.COMPLETED) return "success";
    if (status && transactionErrorStatusIds.includes(status)) return "failure";
    return "pending";
  };

  const template = conversionStatusTemplates?.find(
    (template) =>
      template.status === parseTransactionStatus(transaction?.status)
  );

  if (transaction && template) {
    return (
      <ConversionStatus
        status={template.status}
        content={template}
        handleClickCta={clearTransactionInfo}
        amountConverted={transaction?.targetAmount}
        sourcePartnerId={transaction.source.partnerId}
      />
    );
  }

  return (
    <>
      {user?.accountIsLinked && (
        <ConversionManual
          {...conversionManualProps}
          exchangeContent={exchangeContent}
        />
      )}
      {!user?.accountIsLinked && (
        <Box
          width={330}
          height={220}
          display="flex"
          alignItems="center"
          justifyContent="center"
          p="l"
          margin="auto"
        >
          <Typography
            variant="medium"
            textAlign="center"
            color="neutralTones.5"
          >
            {unlinkedConversionPlaceholder}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ConversionManualSection;
