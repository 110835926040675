import { FooterDTO, ParsedFooter } from "./footerParser.model";

const footerParser = (
  dto: FooterDTO["data"]
): ParsedFooter["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const {
    links,
    partnerLogo,
    partnerUrl,
    copyright: copyrightText,
  } = dto.items[0].fields;

  return {
    links: links.map((link) => ({
      label: link.fields.label,
      url: link.fields.url,
    })),
    partnerLogoUrl: partnerLogo.fields.image.fields.file.url,
    partnerLogoAlt: partnerLogo.fields.alt,
    partnerText: partnerUrl.fields.label,
    partnerLink: partnerUrl.fields.url,
    copyrightText,
  };
};

export default footerParser;
