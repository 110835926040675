import React, { ReactNode } from "react";
import styled from "styled-components";
import { space, SpaceProps } from "styled-system";
import { css } from "@styled-system/css";

import Link from "../components/Link";
import Typography from "../components/Typography";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {
  BLOCKS,
  Document,
  INLINES,
  MARKS,
  Node,
} from "@contentful/rich-text-types";

const StyledUl = styled.ul<SpaceProps>`
  ${space}

  li {
    ${css({
      marginLeft: "m",
    })}
  }
`;

const StyledOl = styled.ol<SpaceProps>`
  ${space}
`;

const StyledLi = styled.li`
  ${space}
`;

const richTextToComponents = (richText: Document): React.ReactNode => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text: ReactNode) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text: ReactNode) => <em>{text}</em>,
      [MARKS.UNDERLINE]: (text: ReactNode) => <u>{text}</u>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node: Node, children: React.ReactNode) => (
        <Typography variant="medium" mb="m" color="neutralTones.7">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_1]: (node: Node, children: React.ReactNode) => (
        <Typography variant="h1" mb="m" color="text">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_2]: (node: Node, children: React.ReactNode) => (
        <Typography variant="h2" mb="m" color="text">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_3]: (node: Node, children: React.ReactNode) => (
        <Typography variant="h3" mb="m" color="text">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_4]: (node: Node, children: React.ReactNode) => (
        <Typography variant="h4" mb="m" color="text">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_5]: (node: Node, children: React.ReactNode) => (
        <Typography variant="h5" mb="m" color="text">
          {children}
        </Typography>
      ),
      [BLOCKS.HEADING_6]: (node: Node, children: React.ReactNode) => (
        <Typography variant="h6" mb="m" color="text">
          {children}
        </Typography>
      ),
      [BLOCKS.UL_LIST]: (node: Node, children: React.ReactNode) => (
        <StyledUl mb="m">{children}</StyledUl>
      ),
      [BLOCKS.OL_LIST]: (node: Node, children: React.ReactNode) => (
        <StyledOl mb="m" pl="xl">
          {children}
        </StyledOl>
      ),
      [BLOCKS.LIST_ITEM]: (node: Node, children: React.ReactNode) => {
        return <StyledLi>{children}</StyledLi>;
      },
      [INLINES.HYPERLINK]: (node: Node, children: React.ReactNode) => (
        <Link to={node.data.uri} openInNewTab>
          {children}
        </Link>
      ),
    },
  };
  return documentToReactComponents(richText, options);
};

export default richTextToComponents;
