import React, { useContext, useMemo } from "react";
import styled, { ThemeContext } from "styled-components";

import useWindowSize from "../../hooks/useWindowSize";
import Box from "../Box";
import Button from "../Button";
import Icon from "../Icon";
import Link from "../Link";
import Layout from "../Layout";
import Typography from "../Typography";
import UserInfo from "../UserInfo";
import { HeaderProps } from "./Header.model";
import { useHistory } from "react-router-dom";
import { RoutesEnum } from "../../config/routes.enum";
import { useAuthentication } from "../../context/Authentication/AuthenticationContext";
import { trackers } from "../../utils/ga";
import { getPartnerFromUrl } from "../../utils/routing";

import theme from "../../theme";
import config from "../../build-config.json";
import getConfig from "../../utils/configGetter";
import {
  useHeaderManipulation,
  OpCos,
} from "../../hooks/useHeaderManipulation";

const NavMenu = styled(Box)`
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Header: React.FC<HeaderProps> = ({
  content,
  isLoadingUser = false,
  user,
  handleLogout = undefined,
  handleLogin = undefined,
}) => {
  const { isPartnerPageVariant } = useAuthentication();
  const { width: windowWidth } = useWindowSize();
  const { breakpoints } = useContext(ThemeContext);
  const history = useHistory();
  const mobileStartBreakpoint = parseInt(breakpoints[0].split("px")[0], 10);
  const tabletStartBreakpoint = parseInt(breakpoints[1].split("px")[0], 10);
  const isMobileWidth = windowWidth < tabletStartBreakpoint;
  const requirePositioning = content.buttons.length > 1 && isMobileWidth;
  const partnerId = getPartnerFromUrl();

  useHeaderManipulation(config.opCoId as OpCos, partnerId);

  const checkAvailableButtons = useMemo(
    () =>
      content.buttons.reduce((acc, button) => {
        return acc || button.shouldBeDisplayed;
      }, false),
    [content.buttons]
  );

  return (
    <Box marginTop={{ _: 110, tablet: 140 }}>
      <Box
        as="header"
        position="fixed"
        top={0}
        left={0}
        width="100vw"
        zIndex={998}
      >
        <Box
          backgroundColor={getConfig<string>(
            config.header,
            "backgroundColor",
            String(theme.colors.neutralTones[0])
          )}
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          borderBottomColor="neutralTones.4"
          paddingX={{ _: "l", tablet: 0 }}
          paddingY="l"
        >
          <Layout
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Link
              to={content.logoLink}
              onClick={() =>
                trackers.trackNavigation("sitewide BA header logo")
              }
              data-cy="header-logo"
            >
              <img
                src={
                  windowWidth < mobileStartBreakpoint
                    ? content.mobileLogoSrc || content.logoSrc
                    : content.logoSrc
                }
                alt={
                  windowWidth < mobileStartBreakpoint
                    ? content.mobileLogoAlt || content.logoAlt
                    : content.logoAlt
                }
              />
            </Link>
            {!isPartnerPageVariant &&
              user?.membershipNumber &&
              content.labels &&
              handleLogout && (
                <UserInfo
                  content={content.labels}
                  membershipNumber={user.membershipNumber}
                  balance={user.balance}
                  username={user.username}
                  handleLogout={handleLogout}
                />
              )}
            {!isPartnerPageVariant &&
              !user?.membershipNumber &&
              handleLogin && (
                <Button
                  id="header button"
                  variant="text"
                  onClick={handleLogin}
                  display="flex"
                  alignItems="center"
                  disabled={isLoadingUser}
                >
                  <Icon
                    type="regular"
                    name="user"
                    color={getConfig<string>(
                      config.header,
                      "textColor",
                      "neutralTones.7"
                    )}
                  />
                  <Typography
                    htmlTag="span"
                    variant="large"
                    ml="xxs"
                    color={getConfig<string>(
                      config.header,
                      "textColor",
                      "neutralTones.7"
                    )}
                  >
                    {content.loginButtonLabel}
                  </Typography>
                </Button>
              )}
          </Layout>
        </Box>
        <Box
          backgroundColor="neutralTones.0"
          borderBottomWidth={getConfig<string>(
            config.header,
            "borderWidth",
            "2px"
          )}
          borderBottomStyle="solid"
          borderBottomColor={getConfig<string>(
            config.header,
            "borderColor",
            "tertiary"
          )}
        >
          <Layout display="flex">
            <NavMenu
              alignItems="center"
              display="flex"
              flexWrap="nowrap"
              overflowX="auto"
              overflowY="hidden"
              paddingLeft={requirePositioning ? "xxl-6" : ""}
            >
              {checkAvailableButtons &&
                content.buttons.map((button, btnIndex) => {
                  return (
                    <Link
                      key={button.id}
                      to={button.url}
                      variant="navAnchor"
                      paddingY={btnIndex === 0 ? 14 : "m"}
                      isFixed={Boolean(requirePositioning && btnIndex === 0)}
                      shouldBeHighlighted={button.shouldBeHighlighted}
                      data-cy="home"
                      isActive={
                        button.id === "header_button_convert_avios" &&
                        history.location.pathname === RoutesEnum.HOME
                      }
                      onClick={() =>
                        trackers.trackNavigation("sitewide main navigation")
                      }
                    >
                      {button.icon && (
                        <Icon
                          type="regular"
                          name={button.icon}
                          color={getConfig<string>(
                            config.colors,
                            "tertiary",
                            "tertiary"
                          )}
                        />
                      )}
                      {button.buttonText}
                    </Link>
                  );
                })}
              {!checkAvailableButtons && <Box py="m" mt="xl" />}
            </NavMenu>
          </Layout>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
