import React from "react";
import { StepProps } from "./Step.model";
import {
  AmountStep,
  ConfirmationStep,
  DirectionStep,
  FrequencyStep,
} from "./Steps";
import { useAutoConversion } from "../../context/AutoConversion/AutoConversionContext";

const Step = ({ exchangeContent = undefined }: StepProps): JSX.Element => {
  const { activeStep } = useAutoConversion();

  const availableSteps = [
    <DirectionStep key="directionStep" exchangeContent={exchangeContent} />,
    <FrequencyStep key="frequencyStep" />,
    <AmountStep key="amountStep" />,
    <ConfirmationStep key="confirmationStep" />,
  ];

  return <>{availableSteps[activeStep]}</>;
};
export default Step;
