import {
  FeatureDTO,
  FeatureToggleDTO,
  ParsedFeatureToggle,
} from "./featureToggleParser.model";

const featureToggleParser = (
  dto: FeatureToggleDTO["data"]
): ParsedFeatureToggle["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  return dto?.items[0].fields.features.reduce(
    (acc: ParsedFeatureToggle["data"], feature: FeatureDTO) => {
      const { featureName, display } = feature.fields;
      return {
        ...acc,
        [featureName]: display,
      };
    },
    {}
  );
};

export default featureToggleParser;
