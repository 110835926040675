import richTextToComponents from "../../../../utils/richTextToComponents";
import {
  ContactUsPageDTO,
  ParsedContactUsPage,
} from "./contactUsPageParser.model";
import { InputError, ParsedInput } from "../../Contentful.model";

const contactUsPageParser = (
  dto: ContactUsPageDTO["data"]
): ParsedContactUsPage["data"] | undefined => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  const defaultError: ParsedInput["errors"] = {};

  const computeErrors = (
    acc: ParsedInput["errors"],
    error: InputError
  ): ParsedInput["errors"] => {
    acc[error.fields.type] = {
      message: error.fields.message,
      additionalDetails: error.fields.additionalDetails,
    };
    return acc;
  };

  const {
    description,
    emailAddress,
    issueDropdown,
    membershipNumber,
    message,
    tierStatus,
    title,
    userName,
    resetFormButtonText,
    successMessage,
  } = dto.items[0].fields;

  return {
    title,
    resetFormButtonText,
    successMessage,
    description: richTextToComponents(description),

    issueDropdown: {
      variant: issueDropdown.fields.variant,
      options: issueDropdown.fields.options.map((option) => ({
        value: option,
      })),
      placeholder: { value: issueDropdown.fields.placeholder },
    },
    userName: {
      label: userName.fields.label,
      type: userName.fields.type,
      variant: userName.fields.variant,
      required: userName.fields.required,
      errors: userName.fields.errors.reduce(computeErrors, defaultError),
    },
    membershipNumber: {
      label: membershipNumber.fields.label,
      type: membershipNumber.fields.type,
      variant: membershipNumber.fields.variant,
      required: membershipNumber.fields.required,
      errors: membershipNumber.fields.errors.reduce(
        computeErrors,
        defaultError
      ),
    },
    emailAddress: {
      label: emailAddress.fields.label,
      type: emailAddress.fields.type,
      variant: emailAddress.fields.variant,
      required: emailAddress.fields.required,
      errors: emailAddress.fields.errors.reduce(computeErrors, defaultError),
    },
    tierStatus: {
      variant: tierStatus.fields.variant,
      label: tierStatus.fields.label,
      required: tierStatus.fields.required,
      options: tierStatus.fields.options.map((option) => ({
        value: option,
      })),
      placeholder: { value: tierStatus.fields.placeholder },
    },
    message: {
      label: message.fields.label,
      required: message.fields.required,
    },
  };
};

export default contactUsPageParser;
