import React from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from "react-router-dom";

import theme from "./theme";
import GlobalStyle from "./GlobalStyle";
import ExchangeThemeProvider from "./ThemeProvider";
import Routes from "./routes";
import NoPartnerLayout from "./pages/_layouts/NoPartner";
import ContextProviders from "./context/ContextProviders";
import GAListener from "./components/GAListener";
import { Home, Logout, Oops } from "./pages";
import { RoutesEnum } from "./config/routes.enum";

import config from "./build-config.json";
import getConfig from "./utils/configGetter";
import { TransactionContextProvider } from "./context/Transaction/TransactionContext";
import AutoConversionProvider from "./context/AutoConversion/AutoConversionProvider";

let CleanRules: React.FC = () => <Oops />;

if (process.env.REACT_APP_ENV !== "prd") {
  CleanRules = require("./pages/CleanRules").default;
}

const App: React.FC = () => {
  return (
    <ExchangeThemeProvider theme={theme}>
      <Router basename={getConfig<string>(config, "baseUrlPath", "/")}>
        <GlobalStyle />
        <GAListener>
          <ContextProviders>
            <Switch>
              <Route exact path="/">
                <Redirect
                  to={getConfig<string>(config, "defaultPath", "/partners")}
                />
              </Route>
              <Route exact path={RoutesEnum.OOPS}>
                <NoPartnerLayout>
                  <Oops />
                </NoPartnerLayout>
              </Route>
              <Route exact path={RoutesEnum.CLEAN_RULES}>
                <NoPartnerLayout>
                  <CleanRules />
                </NoPartnerLayout>
              </Route>
              <Route path={RoutesEnum.LOGOUT}>
                <Logout />
              </Route>
              <Route exact path={RoutesEnum.HOME}>
                <NoPartnerLayout>
                  <Home />
                </NoPartnerLayout>
              </Route>
              <Route path="/partner/:id">
                <TransactionContextProvider>
                  <AutoConversionProvider>
                    <Routes />
                  </AutoConversionProvider>
                </TransactionContextProvider>
              </Route>
              <Route path="*">
                <NoPartnerLayout>
                  <Oops />
                </NoPartnerLayout>
              </Route>
            </Switch>
          </ContextProviders>
        </GAListener>
      </Router>
    </ExchangeThemeProvider>
  );
};

export default App;
