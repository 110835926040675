export const getBulletsActiveIndex = (
  index: number,
  nrOfChildren: number
): number => {
  if (nrOfChildren < 2) {
    return 0;
  }

  if (index === 0) {
    return nrOfChildren - 3;
  }

  if (index === nrOfChildren - 1) {
    return 0;
  }

  return index - 1;
};
