const variants = {
  success: {
    backgroundColor: "success.0",
  },
  warning: {
    backgroundColor: "warning.0",
  },
  error: {
    backgroundColor: "error.0",
  },
};

export default variants;
