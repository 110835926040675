import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import bannersIds from "../../dictionaries/bannersIds";
import Box from "../../components/Box";
import HeroSection from "../../components/HeroSection";
import Layout from "../../components/Layout";
import LoadingPage from "../../components/LoadingPage";
import LoginSection from "../../components/LoginSection";
import MainContainer from "../../components/MainContainer";
import Promotions from "../../components/Promotions";
import VersatilePromotion from "../../components/VersatilePromotion";
import ThreeColumnsSection from "../../components/ThreeColumnsSection";
import TwoCardsSection from "../../components/TwoCardsSection";
import Typography from "../../components/Typography";
import {
  getHeroSectionContent,
  getLoginSectionContent,
  getPageContent,
  getPromotionContent,
  getThreeColumnsSectionContent,
  getTwoCardsSectionContent,
  getVersatilePromotionContent,
} from "../../services/Contentful";
import { useAuthentication } from "../../context/Authentication/AuthenticationContext";
import { ParsedHeroSection } from "../../services/Contentful/parsers/heroSection/heroSectionParser.model";
import { ParsedLoginSection } from "../../services/Contentful/parsers/loginSection/loginSectionParser.model";
import { ParsedPage } from "../../services/Contentful/parsers/page/pageParser.model";
import { ParsedPromotion } from "../../services/Contentful/parsers/promotion/promotionParser.model";
import { ParsedThreeColumnsSection } from "../../services/Contentful/parsers/threeColumnsSection/threeColumnsSectionParser.model";
import { ParsedTwoCardsSection } from "../../services/Contentful/parsers/twoCardsSection/twoCardsSectionParser.model";
import { RoutesEnum } from "../../config/routes.enum";
import { useNotifications } from "../../context/Notifications/NotificationsContext";
import { removeSearchParams } from "../../utils/routing";
import { usePartnershipContext } from "../../context/Partnership/PartnershipContext";
import { promotionTypesIds } from "../../dictionaries/promotionTypesIds";
import { GamingCampaign } from "../../dictionaries/gamingCampaignId";
import GamingAction from "../../components/GamingAction";
import { trackers } from "../../utils/ga";
import { ParsedVersatilePromotion } from "../../services/Contentful/parsers/versatilePromotion/versatilePromotionParser.model";

type TPageContent = {
  heroSection: ParsedHeroSection["data"];
  landing: ParsedPage["data"];
  promotion: ParsedPromotion["data"];
  threeColumnsSection: ParsedThreeColumnsSection["data"];
  twoCardsSection: ParsedTwoCardsSection["data"];
  loginSection: ParsedLoginSection["data"];
  versatilePromotion: ParsedVersatilePromotion["data"];
};

const Landing: React.FC = () => {
  const history = useHistory();
  const [pageContent, setPageContent] = useState<TPageContent | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState(false);
  const {
    data: { pairId, urlPartnerPath },
  } = usePartnershipContext();
  const { handleLogin } = useAuthentication();
  const { addBanner } = useNotifications();
  const [gamingStorageContent, setGamingStorageContent] = useState(() => {
    try {
      const value = window.sessionStorage.getItem(GamingCampaign.BAEC_LOUNGE);

      if (value && urlPartnerPath === "nectar") {
        return JSON.parse(value);
      }
      return null;
    } catch (err) {
      return null;
    }
  });

  const handleLoginAction = (event: React.MouseEvent<HTMLButtonElement>) => {
    trackers.trackAuthentication({
      location: event.currentTarget.id,
      user_action: "log in",
    });

    setIsLoading(true);
    handleLogin();
  };

  useEffect(() => {
    const getContent = async (pairId: string) => {
      const { data: landingPageContent, error: landingPageError } =
        await getPageContent(pairId, "Landing page");
      const { data: heroSectionContent, error: heroSectionContentError } =
        await getHeroSectionContent(pairId);
      const { data: promotionContent, error: promotionContentError } =
        await getPromotionContent(pairId);
      const {
        data: versatilePromotionContent,
        error: versatilePromotionError,
      } = await getVersatilePromotionContent(pairId);
      const {
        data: threeColumnSectionContent,
        error: threeColumnSectionContentError,
      } = await getThreeColumnsSectionContent(pairId);
      const {
        data: twoCardsSectionContent,
        error: twoCardsSectionContentError,
      } = await getTwoCardsSectionContent(pairId);
      const { data: loginSectionContent, error: loginSectionContentError } =
        await getLoginSectionContent(pairId);
      if (
        [
          landingPageError,
          heroSectionContentError,
          threeColumnSectionContentError,
          twoCardsSectionContentError,
          loginSectionContentError,
          versatilePromotionError,
        ].some((error) => error)
      ) {
        history.push(RoutesEnum.OOPS);
      }

      setPageContent({
        heroSection: heroSectionContent,
        landing: landingPageContent,
        promotion: promotionContentError
          ? undefined
          : promotionContent?.filter((promotion) =>
              promotion.promotionType.includes(promotionTypesIds.PARTNER_PAGE)
            ),
        versatilePromotion: versatilePromotionContent,
        threeColumnsSection: threeColumnSectionContent,
        twoCardsSection: twoCardsSectionContent,
        loginSection: loginSectionContent,
      });
    };
    getContent(pairId);
  }, [history, pairId]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const error = params.get("error");
    const operation = params.get("operation");
    const gamingParam = params.get(GamingCampaign.BAEC_LOUNGE);

    if (error === "ACCOUNT_TYPE_NOT_ALLOWED") {
      addBanner({
        bannerId: bannersIds.ACCOUNT_TYPE_NOT_ALLOWED,
        handleAdditionalOnClose: () =>
          removeSearchParams(["error", "operation"]),
      });
    }

    if (operation === "logout") {
      addBanner({
        bannerId: bannersIds.LOGOUT,
        handleAdditionalOnClose: () => removeSearchParams(["operation"]),
      });
    }

    if (urlPartnerPath === "nectar" && gamingParam) {
      window.sessionStorage.setItem(
        GamingCampaign.BAEC_LOUNGE,
        JSON.stringify({ allowed: true })
      );
      setGamingStorageContent(JSON.stringify({ allowed: true }));
    }
  }, [addBanner, urlPartnerPath]);

  if (!pageContent) {
    return <LoadingPage />;
  }

  return (
    <MainContainer>
      <Layout>
        {pageContent?.landing?.pageTitle && (
          <Typography
            variant="h1"
            color="text"
            mb="xl"
            px={{ _: "l", tablet: 0 }}
          >
            {pageContent?.landing?.pageTitle}
          </Typography>
        )}
        <Promotions content={pageContent.promotion} />
        {pageContent.versatilePromotion && (
          <VersatilePromotion {...pageContent.versatilePromotion[0]} />
        )}

        {pageContent?.heroSection && (
          <HeroSection
            {...pageContent?.heroSection}
            mb="l"
            buttonAction={handleLoginAction}
            isLoading={isLoading}
            useLoginLayout
            displayButton
          />
        )}

        {gamingStorageContent && (
          <GamingAction storageHandler={setGamingStorageContent} />
        )}

        {pageContent?.twoCardsSection && (
          <TwoCardsSection {...pageContent.twoCardsSection} mb="l" />
        )}
        {pageContent?.threeColumnsSection && (
          <Box borderBottom="1px solid" borderBottomColor="neutralTones.3">
            <Box backgroundColor="neutralTones.0" px="m" paddingTop="xxl-2">
              <ThreeColumnsSection {...pageContent.threeColumnsSection} />
            </Box>
          </Box>
        )}

        {pageContent?.loginSection && (
          <Box backgroundColor="neutralTones.0">
            <LoginSection
              {...pageContent.loginSection}
              handleLogin={handleLoginAction}
              isLoading={isLoading}
            />
          </Box>
        )}
      </Layout>
    </MainContainer>
  );
};

export default Landing;
