import React from "react";

import AccountInfo from "../AccountInfo";
import Box from "../Box";
import Button from "../Button";
import FeatureToggle from "../FeatureToggle";
import Tooltip from "../Tooltip";
import Typography from "../Typography";
import { useAuthentication } from "../../context/Authentication/AuthenticationContext";
import { usePartnershipContext } from "../../context/Partnership/PartnershipContext";
import { useTransaction } from "../../context/Transaction/TransactionContext";
import { LinkSummaryProps } from "./LinkSummary.model";

const LinkSummary: React.FC<LinkSummaryProps> = ({
  handleLinkAccount = undefined,
  handleUnlinkAccount = undefined,
  isLoading = false,
  content,
  disabledButtonTooltip = undefined,
  ...props
}) => {
  const { user, isPartnerPageVariant } = useAuthentication();
  const {
    data: { partners, basePartnerId, externalPartnerId },
  } = usePartnershipContext();
  const { transactionIsPending } = useTransaction();

  const maximumLinkingTimesReached = user?.linkingCaps?.remaining === 0;

  const accountInfoStyleProps = {
    width: "100%",
    flex: { desktop: 1 },
    maxWidth: { _: "100%", desktopLarge: 350 },
    mb: { _: "s", desktop: 0 },
    mr: { _: 0, desktop: "s" },
  };
  const basePartner = partners.find((partner) => partner.id === basePartnerId);
  const externalPartner = partners.find(
    (partner) => partner.id === externalPartnerId
  );

  if (!content) {
    return <></>;
  }

  const normalizedPartnersContent = () => {
    const partnersContent = {
      basePartnerContent: {
        membershipNumber: {
          prefix: content.basePartnerInfo.membershipNumberPrefix,
          number: isPartnerPageVariant
            ? user?.partnerAccount?.membershipNumber
            : user?.membershipNumber,
        },
        membershipLabel: content.basePartnerInfo.membershipLabel,
      },
      externalPartnerContent: {
        membershipNumber: {
          prefix: content.externalPartnerInfo.membershipNumberPrefix,
          number: isPartnerPageVariant
            ? user?.baseAccount?.partialId
            : user?.partnerAccount?.partialId ??
              user?.partnerAccount?.membershipNumber,
        },
        membershipLabel: content.externalPartnerInfo.membershipLabel,
      },
    };

    if (!isPartnerPageVariant) return partnersContent;

    return {
      basePartnerContent: { ...partnersContent.externalPartnerContent },
      externalPartnerContent: { ...partnersContent.basePartnerContent },
    };
  };

  const { basePartnerContent, externalPartnerContent } =
    normalizedPartnersContent();

  return (
    <Box
      display="flex"
      flexDirection={{ _: "column", desktopLarge: "row" }}
      justifyContent={{ _: "center", desktopLarge: "space-between" }}
      alignItems={{ _: "flex-start", desktopLarge: "center" }}
      backgroundColor="neutralTones.0"
      p="m"
      {...props}
    >
      <Typography
        variant="h3"
        mb={{ _: "l", desktopLarge: 0 }}
        mr="m"
        color="text"
        data-cy="account-description-info"
      >
        {user?.accountIsLinked
          ? content?.linkedHeader
          : content?.unlinkedHeader}
      </Typography>
      <Box
        flex={{ desktopLarge: 1 }}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexDirection={{ _: "column", desktop: "row" }}
        width={{ _: "100%", desktopLarge: "auto" }}
        maxWidth={{ desktopLarge: 914 }}
      >
        {basePartner && (
          <AccountInfo
            {...accountInfoStyleProps}
            id="basePartner"
            logoUrl={basePartner.smallLogo?.url || ""}
            logoAlt={basePartner.smallLogo?.alt || "unavailable"}
            balance={user?.baseAccount?.balance?.amount}
            currencyName={
              user?.baseAccount?.balance?.currency ||
              content.unavailableCurrencyLabel
            }
            membershipNumber={
              basePartnerContent.membershipNumber.number
                ? `${basePartnerContent.membershipNumber.prefix}${basePartnerContent.membershipNumber.number}`
                : content.unavailableMembershipNumberLabel
            }
            membershipLabel={basePartnerContent.membershipLabel}
            unavailableBalanceLabel={content.unavailableBalanceLabel}
          />
        )}
        {user?.accountIsLinked && (
          <AccountInfo
            {...accountInfoStyleProps}
            id="externalPartner"
            balance={user.partnerAccount?.balance?.amount}
            logoUrl={externalPartner?.smallLogo?.url || ""}
            logoAlt={externalPartner?.smallLogo?.alt || "unavailable"}
            currencyName={
              user.partnerAccount?.balance?.currency ||
              content.unavailableCurrencyLabel
            }
            membershipNumber={
              externalPartnerContent.membershipNumber.number
                ? `${externalPartnerContent.membershipNumber.prefix}${externalPartnerContent.membershipNumber.number}`
                : content.unavailableMembershipNumberLabel
            }
            membershipLabel={externalPartnerContent.membershipLabel}
            unavailableBalanceLabel={content.unavailableBalanceLabel}
          />
        )}

        {!isPartnerPageVariant && (
          <FeatureToggle feature="unlink">
            <Tooltip
              infoMessage={
                maximumLinkingTimesReached &&
                !user?.accountIsLinked &&
                disabledButtonTooltip
              }
            >
              <Button
                id={
                  user?.accountIsLinked
                    ? "link summary unlink button"
                    : "link summary link button"
                }
                variant="outlined"
                onClick={
                  user?.accountIsLinked
                    ? handleUnlinkAccount
                    : handleLinkAccount
                }
                width={{ _: "100%", desktop: 202 }}
                disabled={
                  (maximumLinkingTimesReached && !user?.accountIsLinked) ||
                  transactionIsPending
                }
                loading={isLoading}
                data-cy="link-unlink"
              >
                {user?.accountIsLinked
                  ? content?.linkedButtonText
                  : content?.unlinkedButtonText}
              </Button>
            </Tooltip>
          </FeatureToggle>
        )}
      </Box>
    </Box>
  );
};

export default LinkSummary;
