import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router";

import datadogRum from "../../utils/ddRum";
import bannersIds from "../../dictionaries/bannersIds";
import modalsIds from "../../dictionaries/modalsIds";
import useQuery from "../../hooks/useQuery";
import { consumeCode, userLogin, userLogout } from "../../services/Auth";
import { getPartnerFromUrl, removeSearchParams } from "../../utils/routing";
import { useNotifications } from "../Notifications/NotificationsContext";
import { usePartnershipContext } from "../Partnership/PartnershipContext";
import {
  AuthenticationContextProviderProps,
  IAuthContext,
  TUser,
} from "./AuthenticationContext.model";
import { RoutesEnum } from "../../config/routes.enum";
import {
  getAccountBalance,
  getAccountInfo,
  getLinkedAccountBalance,
  getLinkedAccountInfo,
  getLinkPartnerExists,
} from "../../services/Accounts";
import {
  getLinkingPairCaps,
  getPairRemainingPoints,
} from "../../services/Pairs";
import { isSessionExpired } from "../../utils/strings";
import config from "../../build-config.json";

const AuthenticationContext = createContext({} as IAuthContext);
const opCos = ["AVIOS", "IBERIA"];

const AuthenticationContextProvider: React.FC<
  AuthenticationContextProviderProps
> = ({ children }) => {
  const [user, setUser] = useState<TUser | undefined>(undefined);
  const [isLoadingUser, setIsLoadingUser] = useState(true);
  const [sessionExpired, setSessionExpired] = useState(false);
  const userSessionRef = useRef<string | undefined>(undefined);
  const isPartnerPageVariant = useRef<boolean>(false);
  const { search } = useLocation();
  const query = useQuery(search);

  const {
    data: { urlPartnerPath, pairId, externalPartnerId },
    setPartnerData,
    setPartnerCurrency,
    getPairConfigData,
  } = usePartnershipContext();

  const {
    isLoadingNotifications,
    modalTemplates,
    addBanner,
    addModal,
    getModalTemplate,
  } = useNotifications();

  const expireUserSession = useCallback((shouldExpireSession: boolean) => {
    setSessionExpired(shouldExpireSession);
  }, []);

  const handleLogin = useCallback(async () => {
    const { data, error } = await userLogin(urlPartnerPath);
    if (error) {
      addBanner({ bannerId: bannersIds.INTERNAL_ERROR });
      setIsLoadingUser(false);
      return;
    }

    if (data?.url) {
      window.location.assign(data.url);
    }
  }, [addBanner, urlPartnerPath]);

  const handleLogout = useCallback(() => {
    setIsLoadingUser(true);
    userLogout(urlPartnerPath);
    datadogRum.removeUser();
    removeSearchParams(["origin"]);
  }, [urlPartnerPath]);

  const getBaseAccountInfo = useCallback(() => {
    const getData = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get("code");
      if (code) {
        const { error } = await consumeCode(code);
        removeSearchParams(["code"]);
        if (error) addBanner({ bannerId: bannersIds.INTERNAL_ERROR });
      }
      const { data: accountInfo, error: accountInfoError } =
        await getAccountInfo();

      if (accountInfoError) {
        const { code, status } = accountInfoError;
        if (
          isSessionExpired({ code, status }) &&
          userSessionRef.current !== undefined
        ) {
          expireUserSession(true);
          setIsLoadingUser(false);
          return;
        }

        setUser(undefined);
        expireUserSession(false);
        setIsLoadingUser(false);
        return;
      }

      const basePartnerId = accountInfo?.ownerId;
      const externalPartnerId = pairId
        .split("_")
        .find((id: string) => id !== basePartnerId) as string;

      await getPairConfigData(pairId);
      const { data: accountBalance, error: accountBalanceError } =
        await getAccountBalance(expireUserSession);

      if (accountBalanceError) {
        addBanner({ bannerId: bannersIds.INTERNAL_ERROR });
      }

      const baseAccountBalance = accountBalanceError
        ? undefined
        : accountBalance?.balance;
      const basePermissions = accountBalanceError
        ? undefined
        : accountBalance?.permissions;

      const { data: partnerLinkExists, error: partnerLinkExistsError } =
        (await getLinkPartnerExists(externalPartnerId, expireUserSession)) ??
        {};

      if (partnerLinkExistsError) {
        location.assign(RoutesEnum.OOPS);
        return;
      }

      const { data: linkingCapsData, error: linkingPairCapsError } =
        (await getLinkingPairCaps(pairId, expireUserSession)) ?? {};
      const { data: remainingPointsData, error: remainingPointsError } =
        (await getPairRemainingPoints(pairId)) ?? {};

      if (remainingPointsError) {
        addBanner({ bannerId: bannersIds.INTERNAL_ERROR });
      }

      if (linkingPairCapsError) {
        //TODO: handle error;
        setIsLoadingUser(false);
        return;
      }

      if (accountInfo && basePartnerId) {
        setUser((user) => {
          const updatedUser = {
            ...user,
            username: `${accountInfo.user.firstName || ""} ${
              accountInfo.user.lastName || ""
            }`,
            membershipNumber: accountInfo.accountId,
            accountIsLinked: Boolean(partnerLinkExists),
            linkingCaps: linkingCapsData,
            baseAccount: {
              ...user?.baseAccount,
              partialId: accountInfo.partialId || null,
              balance: baseAccountBalance,
              permissions: basePermissions,
            },
            partnerAccount: {
              ...user?.partnerAccount,
              partialId: user?.partnerAccount?.partialId || null,
            },
          };

          if (remainingPointsData) {
            updatedUser.baseAccount.remainingPoints = {
              automatic: remainingPointsData.automatic[basePartnerId],
              manual: remainingPointsData.manual[basePartnerId],
            };
            updatedUser.partnerAccount.remainingPoints = {
              automatic: remainingPointsData.automatic[externalPartnerId],
              manual: remainingPointsData.manual[externalPartnerId],
            };
          }
          return updatedUser;
        });

        /* This fix should be replaced by a generic session handler */
        isPartnerPageVariant.current =
          !opCos.includes(basePartnerId) || query.get("origin") === "external";

        setPartnerData(basePartnerId || "", externalPartnerId);
        setPartnerCurrency(basePartnerId, baseAccountBalance?.currency);

        datadogRum.setUser({
          accountId: accountInfo.accountId,
          ownerId: accountInfo.ownerId,
        });

        expireUserSession(false);

        if (!partnerLinkExists) {
          setIsLoadingUser(false);
        }
      }
    };
    getData();
  }, [
    pairId,
    getPairConfigData,
    expireUserSession,
    addBanner,
    setPartnerData,
    setPartnerCurrency,
    query,
  ]);

  const handleUnlinkingAccount = useCallback(() => {
    getBaseAccountInfo();
  }, [getBaseAccountInfo]);

  const getUpdatedBalance = useCallback(async () => {
    if (!externalPartnerId) {
      return;
    }

    const { data: accountBalance, error: accountBalanceError } =
      await getAccountBalance(expireUserSession);
    const { data: linkedAccountBalance, error: linkedAccountBalanceError } =
      await getLinkedAccountBalance(externalPartnerId, expireUserSession);

    if (linkedAccountBalanceError) {
      const modal = getModalTemplate(modalsIds.ACCOUNT_TEMPORARY_BLOCKED);
      linkedAccountBalanceError?.code === "ACCOUNT_TEMPORARY_BLOCKED" && modal
        ? addModal({
            ...modal,
            hasCloseButton: true,
          })
        : addBanner({ bannerId: bannersIds.INTERNAL_ERROR });
    }

    const baseAccountBalance = accountBalanceError
      ? undefined
      : accountBalance?.balance;
    const basePermissions = accountBalanceError
      ? undefined
      : accountBalance?.permissions;

    if (baseAccountBalance && basePermissions && linkedAccountBalance) {
      setUser((user) => ({
        ...user,
        baseAccount: {
          ...user?.baseAccount,
          partialId: user?.baseAccount?.partialId || null,
          balance: baseAccountBalance,
          permissions: basePermissions,
        },
        partnerAccount: {
          ...user?.partnerAccount,
          partialId: user?.partnerAccount?.partialId || null,
          balance: linkedAccountBalance.balance,
          permissions: linkedAccountBalance.permissions,
        },
      }));
    }
  }, [
    externalPartnerId,
    expireUserSession,
    getModalTemplate,
    addModal,
    addBanner,
  ]);

  const updateRemainingPoints = useCallback(
    async (pairId: string) => {
      const externalPartnerId = getPartnerFromUrl();
      const { opCoId } = config;

      try {
        const { data: remainingPointsData, error: remainingPointsError } =
          (await getPairRemainingPoints(pairId)) ?? {};

        if (remainingPointsError) {
          addBanner({ bannerId: bannersIds.INTERNAL_ERROR });
        }

        if (remainingPointsData) {
          setUser((currentUser) => {
            if (!currentUser) return currentUser;

            const updatedUser: TUser = {
              ...currentUser,
              baseAccount: {
                ...currentUser.baseAccount,
                partialId: currentUser?.baseAccount?.partialId || null,
                remainingPoints: {
                  automatic: remainingPointsData.automatic[opCoId],
                  manual: remainingPointsData.manual[opCoId],
                },
              },
              partnerAccount: {
                ...currentUser.partnerAccount,
                partialId: currentUser?.partnerAccount?.partialId || null,
                remainingPoints: {
                  automatic: remainingPointsData.automatic[externalPartnerId],
                  manual: remainingPointsData.manual[externalPartnerId],
                },
              },
            };

            return updatedUser;
          });
        }
      } catch {
        addBanner({ bannerId: bannersIds.INTERNAL_ERROR });
      }
    },
    [addBanner]
  );

  const getExternalAccountInfo = useCallback(() => {
    const getData = async () => {
      if (!externalPartnerId) {
        setIsLoadingUser(false);
        return;
      }

      const { data: linkedAccountInfo, error: linkedAccountInfoError } =
        await getLinkedAccountInfo(externalPartnerId, expireUserSession);
      const { data: linkedAccountBalance, error: linkedAccountBalanceError } =
        await getLinkedAccountBalance(externalPartnerId, expireUserSession);

      if (linkedAccountBalanceError) {
        const modal = getModalTemplate(modalsIds.ACCOUNT_TEMPORARY_BLOCKED);
        linkedAccountBalanceError?.code === "ACCOUNT_TEMPORARY_BLOCKED" && modal
          ? addModal({
              ...modal,
              hasCloseButton: true,
            })
          : addBanner({ bannerId: bannersIds.INTERNAL_ERROR });
      }

      if (linkedAccountInfoError) {
        addBanner({ bannerId: bannersIds.INTERNAL_ERROR });
      }

      setUser((currentUser) => {
        const updatedUser = {
          ...currentUser,
          membershipNumber: currentUser?.membershipNumber || "",
          accountIsLinked: currentUser?.accountIsLinked || true,
          partnerAccount: {
            ...currentUser?.partnerAccount,
            partialId: linkedAccountInfo?.partialId || null,
            membershipNumber: linkedAccountInfo?.accountId,
            balance: linkedAccountBalance?.balance,
            permissions: linkedAccountBalance?.permissions,
          },
        };
        return updatedUser;
      });
      setPartnerCurrency(
        linkedAccountInfo?.ownerId || "",
        linkedAccountBalance?.balance.currency
      );
      setIsLoadingUser(false);
    };
    getData();
  }, [
    externalPartnerId,
    expireUserSession,
    getModalTemplate,
    setPartnerCurrency,
    addModal,
    addBanner,
  ]);

  useEffect(() => {
    if (!isLoadingNotifications) {
      getBaseAccountInfo();
    }
  }, [getBaseAccountInfo, isLoadingNotifications]);

  useEffect(() => {
    if (user?.accountIsLinked && externalPartnerId) {
      getExternalAccountInfo();
    }
  }, [getExternalAccountInfo, user?.accountIsLinked, externalPartnerId]);

  useEffect(() => {
    if (!sessionExpired) {
      return;
    }

    const sessionExpiredModal = modalTemplates?.find(
      (modal) => modal.id === modalsIds.SESSIONEXPIRED
    );

    if (!sessionExpiredModal) {
      return;
    }

    addModal({
      ...sessionExpiredModal,
      hasCloseButton: true,
      handleClickCta: handleLogout,
      handleAdditionalOnClose: handleLogout,
    });
  }, [sessionExpired, addModal, handleLogout, modalTemplates]);

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        isLoadingUser,
        isPartnerPageVariant: isPartnerPageVariant.current,
        handleLogin,
        handleLogout,
        handleUnlinkingAccount,
        expireUserSession,
        getUpdatedBalance,
        updateRemainingPoints,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

const useAuthentication = (): IAuthContext => {
  const context = useContext(AuthenticationContext);

  if (!Object.keys(context).length) {
    throw new Error(
      "useAuthentication must be used within a AuthenticationContextProvider"
    );
  }

  return context;
};

export {
  AuthenticationContext,
  AuthenticationContextProvider,
  useAuthentication,
};

AuthenticationContext.displayName = "AuthenticationContext";
