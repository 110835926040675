export default function getConfig<T>(
  configProperty: Record<string, T | unknown>,
  property: string,
  defaultValue: T
): T {
  if (!property) {
    return configProperty as T;
  }

  if (property in configProperty) {
    return configProperty[property] as T;
  }

  return defaultValue;
}
