import React, { useCallback, useState } from "react";
import styled from "styled-components";

import AoiButtonsHandler from "../../AoiButtonsHandler";
import Box from "../../Box";
import Button from "../../Button";
import Icon from "../../Icon";
import { useAutoConversion } from "../../../context/AutoConversion/AutoConversionContext";
import { usePartnershipContext } from "../../../context/Partnership/PartnershipContext";

export interface DirectionStepProps {
  exchangeContent?: {
    switchButtonAriaLabel: string;
  };
}
const Logo = styled.img`
  height: 34px;
  width: 40px;
`;

const DirectionStep = ({
  exchangeContent = undefined,
}: DirectionStepProps): JSX.Element => {
  const {
    data: { basePartnerId, externalPartnerId },
    getPartners,
  } = usePartnershipContext();
  const { activeStep, configPayloadState, handleActiveStep, isLoadingAoiInfo } =
    useAutoConversion();

  const [ownerId, setOwnerId] = useState(
    configPayloadState?.destinationPartner || basePartnerId
  );
  const { creditPartner, debitPartner } = getPartners(ownerId || "");

  const handleSwitchDirection = useCallback(() => {
    setOwnerId((prevOwnerId) => {
      const sourcePartnerId =
        prevOwnerId === basePartnerId ? externalPartnerId : basePartnerId;

      return sourcePartnerId;
    });
  }, [basePartnerId, externalPartnerId]);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" flex={1}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-around"
        flexGrow={1}
      >
        <Box display="flex" justify-content="center" align-items="center">
          <Logo
            src={debitPartner.smallLogo?.url}
            alt={debitPartner.smallLogo?.alt}
          />
        </Box>
        <Button
          id="switch-direction"
          variant="icon"
          height="xxl-2"
          width="xxl-2"
          aria-label={exchangeContent?.switchButtonAriaLabel}
          onClick={handleSwitchDirection}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Icon type="regular" name="arrow-down-arrow-up" rotate="90deg" />
        </Button>
        <Box display="flex" justify-content="center" align-items="center">
          <Logo
            src={creditPartner.smallLogo?.url}
            alt={creditPartner.smallLogo?.alt}
          />
        </Box>
      </Box>
      <AoiButtonsHandler
        nextButtonProps={{
          variant: "tertiary",
          disabled: isLoadingAoiInfo,
          padding: "s",
          onClick: () =>
            handleActiveStep(activeStep + 1, "set direction", {
              sourcePartner: debitPartner.id,
              destinationPartner: creditPartner.id,
            }),
        }}
      />
    </Box>
  );
};
export default DirectionStep;
