import React, {
  Children,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled, { ThemeContext } from "styled-components";

import Box from "../Box";
import Bullets from "./Bullets";
import Controllers from "./Controllers";
import useWindowSize from "../../hooks/useWindowSize";
import { CarouselProps } from "./Carousel.model";
import { getChildrenWithDuplicates } from "./helpers/getChildrenWithDuplicates";
import { getBulletsActiveIndex } from "./helpers/getBulletsActiveIndex";

const ROTATION_TIME = 1000;
const AUTOROTATE_INTERVAL = 16000;
let withTransition = true;
let blockClick = false;

const List = styled(Box)<{ withTransition: boolean }>`
  transition: left
    ${(props) => (props.withTransition ? `${ROTATION_TIME}ms` : "0s")};
`;

const Viewport = styled(Box)`
  transition: height 0.5s;
`;

const Carousel: React.FC<CarouselProps> = ({
  children,
  autorotate = false,
  disableSwipe = false,
  ...rest
}) => {
  const numberOfChildren = Children.count(children);
  const [activeIndex, setActiveIndex] = useState<number>(
    numberOfChildren > 1 ? 1 : 0
  );
  const [slideHeight, setSlideHeight] = useState<number>(0);
  const [childWidth, setChildWidth] = useState<number>(0);
  const { width: windowWidth } = useWindowSize();
  const theme = useContext(ThemeContext);
  const startX = useRef<number>(0);
  const direction = useRef<number>(0);
  const viewportRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);
  const childrenWithDuplicates = getChildrenWithDuplicates(
    children,
    childWidth
  );
  const numberOfChildrenWithDuplicates = Children.count(childrenWithDuplicates);
  const bulletsActiveIndex = getBulletsActiveIndex(
    activeIndex,
    numberOfChildrenWithDuplicates
  );

  const handlePrevious = useCallback(() => {
    if (blockClick) {
      return;
    }

    blockClick = true;
    setTimeout(() => {
      blockClick = false;
    }, ROTATION_TIME);

    if (activeIndex === 1) {
      setActiveIndex(0);
      setTimeout(() => {
        withTransition = false;
        setActiveIndex(numberOfChildrenWithDuplicates - 2);
        withTransition = true;
      }, ROTATION_TIME);
      return;
    }
    setActiveIndex((currentIndex) => {
      return currentIndex - 1;
    });
  }, [activeIndex, numberOfChildrenWithDuplicates]);

  const handleNext = useCallback(() => {
    if (blockClick) {
      return;
    }

    blockClick = true;
    setTimeout(() => {
      blockClick = false;
    }, ROTATION_TIME);

    if (activeIndex === numberOfChildrenWithDuplicates - 2) {
      setActiveIndex(numberOfChildrenWithDuplicates - 1);
      setTimeout(() => {
        withTransition = false;
        setActiveIndex(1);
        withTransition = true;
      }, ROTATION_TIME);
      return;
    }

    setActiveIndex((currentIndex) => {
      return currentIndex + 1;
    });
  }, [activeIndex, numberOfChildrenWithDuplicates]);

  const handleTouchStart = useCallback((evt: React.TouchEvent) => {
    startX.current = evt.touches[0].pageX;
    direction.current = 0;
  }, []);

  const handleTouchMove = useCallback((evt: React.TouchEvent) => {
    if (startX.current < evt.touches[0].pageX) {
      direction.current = -1;
      return;
    }
    if (startX.current > evt.touches[0].pageX) {
      direction.current = 1;
      return;
    }
    direction.current = 0;
  }, []);

  const handleTouchEnd = useCallback(() => {
    if (direction.current === 0 || disableSwipe || numberOfChildren < 2) {
      return;
    }

    if (direction.current < 0) {
      handlePrevious();
      return;
    }

    handleNext();
  }, [disableSwipe, numberOfChildren, handleNext, handlePrevious]);

  useEffect(() => {
    if (!autorotate || numberOfChildren < 2) {
      return;
    }
    const interval = setInterval(handleNext, AUTOROTATE_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [autorotate, numberOfChildren, handleNext]);

  useLayoutEffect(() => {
    const viewportNode = viewportRef.current;
    const listNode = listRef.current;
    const setHeight = () =>
      setSlideHeight(listNode ? listNode.clientHeight : 0);
    const setItemWidth = () =>
      setChildWidth(viewportNode ? viewportNode.clientWidth : 0);

    const timeout = setTimeout(setHeight, 1000);
    setItemWidth();
    ["resize", "orientationchange"].forEach((evt) =>
      addEventListener(
        evt,
        () => {
          setHeight();
          setItemWidth();
        },
        false
      )
    );

    return () => {
      ["resize", "orientationchange"].forEach((evt) =>
        removeEventListener(
          evt,
          () => {
            setHeight();
            setItemWidth();
          },
          false
        )
      );
      clearTimeout(timeout);
    };
  }, []);

  if (!children) {
    return null;
  }

  return (
    <Box position="relative" {...rest}>
      <Viewport
        width="100%"
        position="relative"
        zIndex={1}
        overflow="hidden"
        display="flex"
        height={slideHeight}
        ref={viewportRef}
      >
        <List
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          as="ul"
          withTransition={withTransition}
          display="flex"
          position="absolute"
          top={0}
          left={-activeIndex * childWidth}
          width={Children.count(childrenWithDuplicates) * childWidth}
          ref={listRef}
        >
          {childrenWithDuplicates}
        </List>
      </Viewport>
      {numberOfChildren > 1 &&
        windowWidth >=
          parseInt(theme.breakpoints.desktop.split("px")[0], 10) && (
          <Controllers
            handlePrevious={handlePrevious}
            handleNext={handleNext}
          />
        )}
      {numberOfChildren > 1 && (
        <Bullets
          activeIndex={bulletsActiveIndex}
          numberOfBullets={numberOfChildren}
          setActiveIndex={setActiveIndex}
        />
      )}
    </Box>
  );
};

export default Carousel;
