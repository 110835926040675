import React from "react";
import { useHistory } from "react-router-dom";

import Accordion from "../../components/Accordion";
import Layout from "../../components/Layout";
import LoadingPage from "../../components/LoadingPage";
import MainContainer from "../../components/MainContainer";
import TopicsContainer from "../../components/TopicsContainer";
import Typography from "../../components/Typography";
import { RoutesEnum } from "../../config/routes.enum";
import { useGetFaqPageContent } from "../../services/Contentful";
import { usePartnershipContext } from "../../context/Partnership/PartnershipContext";

const Faq: React.FC = () => {
  const history = useHistory();
  const {
    data: { pairId },
  } = usePartnershipContext();
  const { data: pageContent, error } = useGetFaqPageContent(pairId);

  if (error) {
    history.push(RoutesEnum.OOPS);
  }

  if (!pageContent && !error) {
    return <LoadingPage />;
  }

  return (
    <MainContainer>
      <Layout>
        <TopicsContainer
          backgroundColor="neutralTones.0"
          py="xxl-2"
          px={{ _: "m", desktop: "xxl-4" }}
        >
          {pageContent && (
            <>
              <Typography
                variant="h1"
                lineHeight="m"
                marginBottom="xl"
                color="text"
              >
                {pageContent.title}
              </Typography>
              <Typography variant="xm" color="neutralTones.5" mb="xl">
                {pageContent.subtitle}
              </Typography>
              {pageContent?.qa_accordion && (
                <Accordion
                  sections={pageContent.qa_accordion}
                  variant="outline"
                />
              )}
            </>
          )}
        </TopicsContainer>
      </Layout>
    </MainContainer>
  );
};

export default Faq;
