import richTextToComponents from "../../../../utils/richTextToComponents";
import { ParsedPromotion, PromotionDTO } from "./promotionParser.model";

const promotionParser = (
  dto: PromotionDTO["data"]
): ParsedPromotion["data"] => {
  if (!dto || dto?.items.length === 0) {
    return undefined;
  }

  return dto.items.map((promotion) => {
    const {
      header,
      description,
      image,
      title,
      startDate,
      endDate,
      linkToPromotion,
      id,
      partner,
      promotionType,
      displayConditions,
      descriptionImage,
    } = promotion.fields;

    return {
      id,
      header,
      title,
      description: richTextToComponents(description),
      image: image
        ? {
            imageUrl: image.fields.image.fields.file.url,
            imageAlt: image.fields.alt,
          }
        : undefined,
      descriptionImage:
        descriptionImage && descriptionImage.fields
          ? {
              imageUrl: descriptionImage.fields?.file?.url || "",
              imageAlt: descriptionImage.fields?.file?.alt || "",
            }
          : undefined,
      startDate,
      endDate,
      linkToPromotion: {
        ...linkToPromotion.fields,
      },
      partner: {
        currencyLogo: {
          url: partner.fields.currencyLogo.fields.image.fields.file.url,
          alt: partner.fields.currencyLogo.fields.alt,
        },
      },
      promotionType,
      displayConditions,
    };
  });
};

export default promotionParser;
