import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { gridColumn, gridGap, gridTemplateColumns } from "styled-system";
import { useHistory } from "react-router-dom";

import Box from "../../components/Box";
import Carousel from "../../components/Carousel";
import CarouselPromotion from "../../components/CarouselPromotion";
import Layout from "../../components/Layout";
import LoadingPage from "../../components/LoadingPage";
import MainContainer from "../../components/MainContainer";
import PartnerCard from "../../components/PartnerCard";
import ThreeColumnsSection from "../../components/ThreeColumnsSection";
import Typography from "../../components/Typography";
import {
  getAllPromotionsContent,
  getHomepageContent,
  getThreeColumnsSectionContent,
} from "../../services/Contentful";
import { ParsedHomepage } from "../../services/Contentful/parsers/homepage/homepageParser.model";
import {
  ParsedPromotion,
  TParsedPromotion,
} from "../../services/Contentful/parsers/promotion/promotionParser.model";
import { ParsedThreeColumnsSection } from "../../services/Contentful/parsers/threeColumnsSection/threeColumnsSectionParser.model";
import { RoutesEnum } from "../../config/routes.enum";
import { pairIds } from "../../context/Partnership/PairIds";
import { promotionTypesIds } from "../../dictionaries/promotionTypesIds";

type TPageContent = {
  partnersPage: ParsedHomepage["data"];
  threeColumnsSection: ParsedThreeColumnsSection["data"];
  promotions: ParsedPromotion["data"];
};

const GridBox = styled(Box)`
  display: grid;

  ${gridGap}
  ${gridColumn}
  ${gridTemplateColumns}
`;

function Home(): JSX.Element {
  const [pageContent, setPageContent] = useState<TPageContent | undefined>(
    undefined
  );
  const history = useHistory();

  useEffect(() => {
    const allPairIds = Object.values(pairIds);

    const getPageContent = async () => {
      const { data: partnersPageContent, error: partnersPageError } =
        await getHomepageContent();
      const {
        data: threeColumnSectionContent,
        error: threeColumnSectionContentError,
      } = await getThreeColumnsSectionContent("NO_PARTNER");
      const { data: promotions } = await getAllPromotionsContent(allPairIds);
      if (
        [partnersPageError, threeColumnSectionContentError].some(
          (error) => error
        )
      ) {
        history.push(RoutesEnum.OOPS);
      }

      const homepageTypeQualifiedPromotions = (
        promotionList: TParsedPromotion[]
      ) =>
        promotionList?.filter((promotion) => {
          if (!promotion.promotionType.includes(promotionTypesIds.HOMEPAGE)) {
            return false;
          }

          if (promotion.displayConditions) {
            return false;
          }

          if (!promotion.endDate && !promotion.startDate) {
            return true;
          }

          const now = new Date().getTime();

          if (promotion.startDate && promotion.endDate) {
            const promotionStartDate = new Date(promotion.startDate).getTime();
            const promotionEndDate = new Date(promotion.endDate).getTime();
            return now > promotionStartDate && now < promotionEndDate;
          }

          if (promotion.startDate) {
            const promotionStartDate = new Date(promotion.startDate).getTime();
            return now > promotionStartDate;
          }

          if (promotion.endDate) {
            const promotionEndDate = new Date(promotion.endDate).getTime();
            return promotionEndDate > now;
          }

          return false;
        });

      const promotionsByPartner =
        promotions &&
        promotions.filter((allPromotionsByPartner) =>
          Boolean(allPromotionsByPartner)
        );

      const qualifiedPromotions = promotionsByPartner
        ? promotionsByPartner?.reduce((acc, partnerPromotions) => {
            if (!partnerPromotions) {
              return acc;
            }

            return acc?.concat(
              homepageTypeQualifiedPromotions(partnerPromotions)
            );
          }, [] as TParsedPromotion[])
        : [];

      setPageContent({
        partnersPage: partnersPageContent,
        threeColumnsSection: threeColumnSectionContent,
        promotions: qualifiedPromotions,
      });
    };
    getPageContent();
  }, [history]);

  if (!pageContent) {
    return <LoadingPage />;
  }

  return (
    <>
      <MainContainer>
        {pageContent.promotions && pageContent.promotions.length > 0 && (
          <Box width="100%" backgroundColor="neutralTones.2" py="xxl-2">
            <Layout>
              <Carousel autorotate>
                {pageContent.promotions.map((promotion) => (
                  <CarouselPromotion
                    key={promotion?.id}
                    image={{
                      url: promotion?.image?.imageUrl,
                      alt: promotion?.image?.imageAlt,
                    }}
                    descriptionImage={{
                      url: promotion?.descriptionImage?.imageUrl,
                      alt: promotion?.descriptionImage?.imageAlt,
                    }}
                    logo={promotion?.partner.currencyLogo}
                    title={promotion?.title}
                    description={promotion?.description}
                    linkToPromotion={promotion?.linkToPromotion}
                  />
                ))}
              </Carousel>
            </Layout>
          </Box>
        )}
        <Layout>
          <Box display="flex" flexDirection="column">
            {pageContent?.partnersPage?.title && (
              <Typography
                variant="h1"
                color="text"
                mb="m"
                mt="xxl-2"
                px={{ _: "l", tablet: 0 }}
              >
                {pageContent.partnersPage.title}
              </Typography>
            )}
            {pageContent?.partnersPage?.subtitle && (
              <Typography
                variant="medium"
                color="neutralTones.7"
                px={{ _: "l", tablet: 0 }}
                mb="xl"
              >
                {pageContent.partnersPage.subtitle}
              </Typography>
            )}
            {pageContent?.partnersPage?.partners.length && (
              <GridBox
                gridGap={16}
                gridTemplateColumns={{
                  _: "auto",
                  tablet:
                    pageContent.partnersPage?.partners.length > 1
                      ? "1fr 1fr"
                      : "1fr",
                }}
              >
                {pageContent.partnersPage.partners.map((partner) => (
                  <PartnerCard key={partner.id} partnerInfo={partner} />
                ))}
              </GridBox>
            )}
          </Box>
        </Layout>
      </MainContainer>
      {pageContent?.threeColumnsSection && (
        <Box backgroundColor="neutralTones.2" py="xxl-2">
          <Layout>
            <Typography
              variant="h1"
              color="text"
              mb="xxl"
              px={{ _: "l", tablet: 0 }}
            >
              {pageContent?.partnersPage?.threeSectionTitle}
            </Typography>

            <Box backgroundColor="neutralTones.0" px="m" paddingTop="xxl-2">
              <ThreeColumnsSection {...pageContent.threeColumnsSection} />
            </Box>
          </Layout>
        </Box>
      )}
    </>
  );
}

export default Home;
